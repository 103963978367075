import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import InputPhoneNumber from "components/inputPhoneNumber";
import { optionsStudentContact } from "models/common";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { regexGSM7 } from "utils";
import ErrorMessageForm from "../ErrorMessageForm";
import { useStyles } from "./styles";
import numeral from "numeral";

interface IProp {
  title: string;
  isRequired?: boolean;
  widthLabel?: number;
  marginRightLabel?: number;
  type?: string;
  onChange?: any;
  name: string;
  register: any;
  errors?: string;
  control?: any;
  options?: optionsStudentContact;
  showCountCharacters?: boolean;
  maxCharacters?: number;
  currentContent?: string;
  fullWidth?: boolean;
  initIsoVnDefault?: string;
  amountContact?: any;
}

export default function InputTextForm({
  title,
  isRequired = false,
  widthLabel,
  marginRightLabel,
  type,
  name,
  register,
  errors,
  control,
  options,
  showCountCharacters,
  maxCharacters,
  currentContent,
  fullWidth,
  initIsoVnDefault,
  amountContact,
}: IProp) {
  const { classes } = useStyles();
  const getType = type ?? "text";
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();
  const width = widthLabel ?? 80;
  const marginRight = marginRightLabel ?? 60;

  const isNormalText = (text: string) => {
    return regexGSM7.test(text);
  };

  const numberOfMessages = (text: string) => {
    return Math.ceil(text.length / (isNormalText(text) ? 160 : 70));
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrap} style={type === "textarea" ? { alignItems: "flex-start" } : undefined}>
          <Box sx={{ width: `${width}px`, marginRight: `${marginRight}px` }}>
            <p style={{ width: width }} className={classes.label}>
              {title} {isRequired && <span>*</span>}
            </p>
          </Box>
          {name === "phone_number" && options ? (
            <InputPhoneNumber
              options={options["country"] ?? []}
              nameRegisterPhone={name}
              control={control}
              nameRegisterIso="country_iso"
              initIso={initIsoVnDefault}
              classNamePoper={classes.classNamePoper}
              className={classes.inputPhone}
            />
          ) : (
            <>
              {type !== "password" && type !== "textarea" && (
                <input
                  type={getType}
                  className={[classes.input, fullWidth ? classes.flexGrow : ""].join(" ")}
                  {...register(name)}
                />
              )}
              {type === "textarea" && (
                <Box className={[classes.wrapTextArea, fullWidth ? classes.flexGrow : ""].join(" ")}>
                  <textarea
                    className={classes.inputArea}
                    {...register(name)}
                    maxLength={maxCharacters ? maxCharacters : ""}
                  />
                  {showCountCharacters && currentContent && (
                    <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                      <Typography variant="caption">
                        {t("character")}: {currentContent.length}
                      </Typography>
                      <Typography variant="caption">
                        {t("number_of_messages")}:{" "}
                        <span style={{ fontWeight: "bold" }}>{numberOfMessages(currentContent)}</span>
                      </Typography>
                      <Typography variant="caption">
                        {t("number_of_messages_sent")}:{" "}
                        <span className={classes.textSpecial}>{numberOfMessages(currentContent) * amountContact}</span>
                      </Typography>
                      <Typography variant="caption">
                        {t("total_freight_approx")}:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {numeral(numberOfMessages(currentContent) * amountContact * 300).format("0,0[.]00")}đ
                        </span>
                        {" - "}
                        <span style={{ fontWeight: "bold" }}>
                          {numeral(numberOfMessages(currentContent) * amountContact * 350).format("0,0[.]00")}đ
                        </span>
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              {type === "password" && (
                <div className={classes.containerPassword}>
                  <input
                    className={classes.inputPassword}
                    {...register("password")}
                    type={showPassword ? "text" : "password"}
                    placeholder={t("password")}
                  />
                  <IconButton onClick={() => setShowPassword(!showPassword)} className={classes.btnHide}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>
              )}
            </>
          )}
        </div>
        <ErrorMessageForm errors={errors} name={name} widthLabel={width} marginRightLabel={marginRight} />
      </div>
    </>
  );
}
