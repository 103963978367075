import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Forbidden() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "calc(100vh - 147px);",
      }}
    >
      <Typography variant="h1">403</Typography>
      <Typography variant="h6">{t("page_forbidden_text")}</Typography>
      <Button className={classes.btnBackHome} variant="contained" onClick={() => navigate("/")}>
        {t("back_home")}
      </Button>
    </Box>
  );
}
