import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  containerPayInformation: {
    marginTop: "32px",
  },
  titlePay: {
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "20px",
    color: "#AB3024",
    marginBottom: "10px",
  },
  contentPay: {
    backgroundColor: "#AB2523dd",
    color: colors.backgroundLight,
    borderRadius: "5px",
    padding: "16px",
  },
  wrapInformationPay: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  wrapInfoPayAndQRCode: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  namePayField: {
    minWidth: "150px",
    fontWeight: 400,
  },
  table: {
    border: "1px solid #ab2523cc",
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "16px",
    backgroundColor: colors.backgroundLight,
    color: colors.defaultColorText,
    "& th, td": {
      border: "1px solid #ab2523cc",
    },
  },
}));
