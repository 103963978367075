import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useStyles } from "./styles";

interface PaginationProp {
  totalRecords: number;
  pageSize: number;
  limit: number;
  currentPage: number;
  onChangePage: Function;
  onChangeLimit: Function;
  onSetPageSize: Function;
  isShowTotal?: boolean;
}

const Paginate = ({
  totalRecords,
  pageSize,
  limit,
  currentPage,
  onChangePage,
  onChangeLimit,
  onSetPageSize,
  isShowTotal = true,
}: PaginationProp) => {
  const count: number = pageSize ? Math.ceil(totalRecords / pageSize) : totalRecords;
  const { classes } = useStyles();

  return (
    <Stack>
      <div className={classes.container}>
        {isShowTotal && (
          <input
            type="number"
            min={1}
            onChange={(e) => onChangeLimit(e.target.value)}
            className={classes.pageLimit}
            value={limit}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                if (!limit || limit > 200) {
                  onChangeLimit(50);
                  onSetPageSize(50);
                } else {
                  onSetPageSize(limit);
                }
              }
            }}
          />
        )}
        <Pagination
          className="nav-paginate"
          count={count}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          size="small"
          color="primary"
          showFirstButton
          showLastButton
          siblingCount={0}
          onChange={(e, page) => onChangePage(page)}
        />
      </div>
    </Stack>
  );
};

export default Paginate;
