import { LooseObject } from "models/common";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { STUDENT_CONTACT_TYPE } from "utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface IQuery {
  type: number;
  queries: LooseObject[];
  errors?: any;
}

const initialState: IQuery = {
  type: STUDENT_CONTACT_TYPE,
  queries: [],
  errors: null,
};

export const getListQueries = (type: number) => async () => {
  try {
    const url: string = API.query;
    const response = await axiosClient.get(url, { params: { type: type } });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getQuery = (id: number) => async () => {
  try {
    const url: string = `${API.query}/${id}`;
    const response = await axiosClient.get(url);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const saveQuery = (data: LooseObject) => async () => {
  try {
    const url: string = API.query;
    const response = await axiosClient.post(url, { ...data });

    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const deleteQuery = (id: number) => async () => {
  try {
    const url: string = `${API.query}/${id}`;
    const response = await axiosClient.delete(url);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const fetchQueriesByParam = async (paramsData: any) => {
  const url: string = API.query;
  const response = await axiosClient.get(url, {
    params: { ...paramsData },
  });
  return response?.data?.data;
};

export const fetchQuery = createAsyncThunk(
  "query/fetchQuery",
  async (_, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const paramsData = {
        type: state.query.type,
      };
      const data = await fetchQueriesByParam(paramsData);
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    setTypeContact: (state, action) => {
      state.type = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuery.fulfilled, (state, action) => {
      state.queries = action.payload;
    });
  },
});

export default querySlice;

export const querySelector = (state: RootState) => state.query.queries;
