import { makeStyles } from "tss-react/mui";
import checkList from "../../../../../../assets/images/checklist.svg";

export const useStyles = makeStyles()(() => ({
  containerContent: {
    margin: "16px",
    padding: "16px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    "@media (max-width: 425px)": {
      margin: "8px",
      padding: "8px",
    },
  },
  checkList: {
    maskImage: `url(${checkList})`,
    display: "block",
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    marginBottom: "5px",
  },
  wrapHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px",
  },
  labelHeader: {
    fontSize: "30px",
    fontWeight: 700,
    "@media (max-width: 425px)": {
      fontSize: "20px",
    },
  },
  btnAddTask: {
    height: "40px",
    backgroundColor: "#4A4358",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "#4A4358",
    },
  },
  wrapNodata: {
    backgroundColor: "#FAFAFA",
  },
  noData: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
