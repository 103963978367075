import { Box, Grid, Typography } from "@mui/material";
import CardInfoReport from "components/CardInfoReport";
import ReportCardCollapse from "components/ReportCardCollapse";
import ReportDateRangePicker from "components/ReportDateRangePicker";
import StatisticChartBase from "components/StatisticChart/StatisticChartBase";
import StatisticChartByAdmissionStatus from "components/StatisticChart/StatisticChartByAdmissionStatus";
import StatisticChartByCertificate from "components/StatisticChart/StatisticChartByCertificate";
import StatisticChartByCity from "components/StatisticChart/StatisticChartByCity";
import StatisticChartByDistrict from "components/StatisticChart/StatisticChartByDistrict";
import StatisticChartByProfileStatus from "components/StatisticChart/StatisticChartByProfileStatus";
import StatisticChartByResults from "components/StatisticChart/StatisticChartByResults";
import StatisticChartBySource from "components/StatisticChart/StatisticChartBySource";
import StatisticChartToTheStaffInCharge from "components/StatisticChart/StatisticChartToTheStaffInCharge";
import StudentStatisticChartAccordingToAspirations from "components/StatisticChart/StudentStatisticChartAccordingToAspirations";
import StudentStatisticChartByStatus from "components/StatisticChart/StudentStatisticChartByStatus";
import StudentStatisticChartByTestScore from "components/StatisticChart/StudentStatisticChartByTestScore";
import TableReport from "components/TableReport";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "redux/store";
import { getQuantityContact, quantityContactSelector } from "store/reportSlice";
import { useStyles } from "./styles";

const Reports = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [startUpdate, setStartUpdate] = React.useState(moment());
  const [endUpdate, setEndUpdate] = React.useState(moment());

  const quantityContactReport = useSelector(quantityContactSelector);

  const getDataReport = useMemo(() => {
    return async (dateRange: any) => {
      await dispatch(getQuantityContact({ dateFilter: dateRange }));
    };
  }, [dispatch]);

  useEffect(() => {
    getDataReport(1);
  }, [getDataReport]);

  const handleDateRange = (startDate: any, endDate: any) => {
    setStartUpdate((prev) => (prev = startDate));
    setEndUpdate((prev) => (prev = endDate));
  };

  return (
    <Box>
      <Helmet>
        <title>{t("reports")}</title>
      </Helmet>
      <Box padding={"20px"}>
        <Typography variant="h5">{t("reports")}</Typography>
      </Box>
      <Box className={classes.contentPage}>
        <Box mb={2} className={classes.wrapSelected}>
          <ReportDateRangePicker start={startUpdate} end={endUpdate} applyCallback={handleDateRange} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <CardInfoReport title={t("number_of_students")} data={quantityContactReport.student || 0} />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardInfoReport title={t("number_of_masters")} data={quantityContactReport.master || 0} />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardInfoReport
              title={t("total")}
              data={quantityContactReport.student + quantityContactReport.master || 0}
            />
          </Grid>

          <Grid item xs={12}>
            <ReportCardCollapse title={t("student")}>
              <TableReport />
            </ReportCardCollapse>
          </Grid>
          <Grid item xs={12}>
            <ReportCardCollapse title={t("master")}>
              <TableReport />
            </ReportCardCollapse>
          </Grid>

          <Grid item xs={12}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("student_statistics_chart_by_status")} leftMode>
                <StudentStatisticChartByStatus />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>

          <Grid item xs={12} md={3}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("statistics_chart_by_profile_status")} minWidthSelect={"150px"}>
                <StatisticChartByProfileStatus />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>
          <Grid item xs={12} md={3}>
            <ReportCardCollapse>
              <StatisticChartBase
                title={t("student_statistics_chart_according_to_aspirations")}
                minWidthSelect={"150px"}
              >
                <StudentStatisticChartAccordingToAspirations />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>
          <Grid item xs={12} md={6}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("statistics_chart_by_certificate")} leftMode>
                <StatisticChartByCertificate />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("statistics_chart_by_results")}>
                <StatisticChartByResults />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>
          <Grid item xs={12} md={6}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("student_statistics_chart_by_test_score")} leftMode>
                <StudentStatisticChartByTestScore />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("statistics_chart_by_admission_status")}>
                <StatisticChartByAdmissionStatus />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>
          <Grid item xs={12} md={6}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("statistics_chart_by_source")} leftMode>
                <StatisticChartBySource />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>

          <Grid item xs={12}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("statistics_chart_by_city")} leftMode>
                <StatisticChartByCity />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>
          <Grid item xs={12}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("statistics_chart_by_district")} leftMode>
                <StatisticChartByDistrict />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>

          <Grid item xs={12}>
            <ReportCardCollapse>
              <StatisticChartBase title={t("statistics_chart_to_the_staff_in_charge")} leftMode>
                <StatisticChartToTheStaffInCharge />
              </StatisticChartBase>
            </ReportCardCollapse>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Reports;
