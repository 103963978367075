import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";
import checkList from "../../../assets/images/checklist.svg";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    backgroundColor: colors.btnDark,
    color: colors.backgroundLight,
    height: "40px",
    fontSize: "18px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "20px",
  },
  btnClose: {
    backgroundColor: colors.btnDark,
    color: colors.backgroundLight,
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    height: "40px",
  },
  btnYes: {
    backgroundColor: colors.btnPrimary,
    marginLeft: "25px",
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
  btnNo: {
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "25px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  formControl: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.between("xs", "md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "10px",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    minWidth: "150px",
    "& span": {
      color: "#F2756F",
    },
    [theme.breakpoints.between("xs", "md")]: {
      margin: "5px 0",
    },
  },
  input: {
    width: "100%",
    height: "30px",
    backgroundColor: colors.whiteF5,
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "150px",
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: 0,
    },
  },
  containerContent: {
    fontSize: "14px",
    padding: "16px 55px",
    [theme.breakpoints.between("xs", "md")]: {
      padding: "15px",
    },
  },
  wrapIconTitle: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginLeft: "14px",
    },
    "& img": {
      width: "24px",
      height: "24px",
    },
  },
  inputSelect: {
    width: "100%",
  },
  inputArea: {
    width: "100%",
    backgroundColor: colors.whiteF5,
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "5px 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
    marginTop: "12px",
    resize: "none",
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: "5px",
    },
  },
  btnSwitch: {
    marginLeft: "5px",
  },
  hidden: {
    display: "none",
  },
  checkList: {
    maskImage: `url(${checkList})`,
    display: "block",
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    marginBottom: "5px",
  },
  wrapBtnAddTask: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  btnAddTask: {
    height: "40px",
    minWidth: "145px",
    borderRadius: "8px",
    backgroundColor: colors.btnDark,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: colors.btnDark,
    },
  },
}));
