import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
    inputDisable: {
        width: "100%",
        height: "30px",
        fontSize: "14px",
        backgroundColor: "#F5F5F5",
        border: "1px solid #DEDEDE",
        borderRadius: "4px",
        padding: "0 8px",
        boxSizing: "border-box",
        "&:focus": {
            border: "1px solid #1070bd",
            outline: "none",
        },
        "&:hover": {
            cursor: "not-allowed",
        },
    },
}));