import { Navigate } from "react-router-dom";
import { getUserInfo, hasPermissions, hasRoles } from "redux/auth/auth.service";

interface IProps {
  children: JSX.Element;
  roles?: Array<string>;
  permissions?: Array<string>;
}

function ProtectedComponent({ children, roles, permissions }: IProps) {
  const userInfo = getUserInfo();
  if (!userInfo) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (!hasRoles(roles ?? []) || !hasPermissions(permissions ?? [])) {
    return <Navigate to={{ pathname: "/*" }} />;
  }
  return children;
}

export default ProtectedComponent;
