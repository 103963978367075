import { Box, Typography } from "@mui/material";
import BaseChart from "components/Charts";
import React from "react";
import { useTranslation } from "react-i18next";
import colors, { colorsPaletteChart } from "utils/colors";
import { optionsPieChart } from "utils/optionsChart";

interface IProps extends React.PropsWithChildren {
  title?: string;
}

const StatisticChartByProfileStatus: React.FC<IProps> = ({
  title,
  children,
}) => {
  const { t } = useTranslation();

  const labels = [t("unconfimred"), t("approved"), t("not_approved")];

  const data = [
    {
      data: [22, 22, 56],
      borderColor: colors.backgroundLight,
      backgroundColor: colorsPaletteChart.default,
    },
  ];

  return (
    <Box>
      <BaseChart
        type={"pie"}
        datasets={data}
        labels={labels}
        options={optionsPieChart}
      />
      <Box>
        <Typography variant="body1" textAlign="center">
          {t("successful_profile_conversion_rate")}
        </Typography>
        <Typography variant="body2" fontWeight={"bold"} textAlign={"center"}>
          10%
        </Typography>
      </Box>
    </Box>
  );
};

export default StatisticChartByProfileStatus;
