import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  contentPage: {
    backgroundColor: colors.backgroundLight,
    boxSizing: "border-box",
    padding: "30px 20px",
  },
  wrapChart: {
    display: "flex",
    justifyContent: "center",
    margin: "30px auto",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
}));
