import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  containerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  btnCancel: {
    height: "31px",
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "51px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  btnSearch: {
    height: "31px",
    backgroundColor: colors.btnDark,
    "&:hover": {
      backgroundColor: colors.btnDark,
    },
  },
  containerForm: {
    paddingLeft: "12.5px",
  },
  resultContainer: {
    marginLeft: "140px",
    fontSize: "14px",
    marginBottom: "10px",
    width: "auto",
    overflow: "hidden",
    "& div": {
      width: "100%",
    },
    "& .MuiFormControlLabel-root": {
      width: "100%",
    },
    "& .MuiFormControlLabel-label": {
      width: "100%",
      fontSize: "14px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  result: {
    color: colors.danger,
    fontWeight: "700",
    lineHeight: "normal",
  },
  radio: {
    height: "30.5px",
    color: "#80868B",
    "&.Mui-checked": {
      color: "#4A4358",
    },
  },
}));
