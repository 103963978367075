import { getUserInfo, hasPermissions, hasRoles } from "redux/auth/auth.service";

interface IProps {
  children: JSX.Element;
  roles?: Array<string>;
  permissions?: Array<string>;
}

function RoleBaseComponent({ roles, permissions, children }: IProps) {
  const userInfo = getUserInfo();
  if (!userInfo || !hasRoles(roles ?? []) || !hasPermissions(permissions ?? [])) {
    return null;
  }

  return children;
}

export default RoleBaseComponent;
