import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "components/Loading/Loading";
import ModalConfirmLeaveForm from "components/ModalConfirmLeaveForm";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/store";
import actionContactSlice, { addContactToExistingList, getErrorActionContactSelector } from "store/actionContactSlice";
import contactCategorySlice, {
  contactCategoryListWithoutCurrentContactIdSelector,
  fetchContactCategoriesByContactId,
  fetchContactCategoriesWithoutCurrentContactId,
} from "store/contactCategorySlice";
import * as Yup from "yup";
import AutoCompleteForm from "../AutoCompleteForm";
import ButtonCancelForm from "../ButtonCancelForm";
import ButtonSaveForm from "../ButtonSaveForm";
import { useStyles } from "./styles";

interface IAddContactToExistingListProp {
  onClose: Function;
  contactId: number;
  isOpen: boolean;
}

export default function AddContactToExistingListForm({
  onClose,
  contactId,
  isOpen = false,
}: IAddContactToExistingListProp) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const errorFromApis = useSelector(getErrorActionContactSelector);
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearchContactList, setIsLoadingSearchContactList] = useState<boolean>(false);
  const [isOpenModalConfirmLeaveForm, setIsOpenModalConfirmLeaveForm] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    contact_category_ids: Yup.array().min(1, t("this_field_is_required")),
  });

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      dispatch<any>(contactCategorySlice.actions.setFilterWithoutContactId(contactId));
      dispatch<any>(actionContactSlice.actions.resetStateError());
      dispatch<any>(fetchContactCategoriesWithoutCurrentContactId());
      setIsLoading(false);
    }
  }, [dispatch, contactId, isOpen]);

  const contactCategories = useSelector(contactCategoryListWithoutCurrentContactIdSelector);

  type AddContactToExistingListSubmitForm = Yup.InferType<typeof validationSchema>;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<AddContactToExistingListSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: contactId,
      contact_category_ids: [],
    },
  });

  const handleBackClick = () => {
    isDirty ? setIsOpenModalConfirmLeaveForm(true) : onClose();
  };

  const handleOnChangeSearch = async (value: any) => {
    setIsLoadingSearchContactList(true);
    dispatch<any>(contactCategorySlice.actions.setName(value));
    await dispatch<any>(fetchContactCategoriesWithoutCurrentContactId());
    setIsLoadingSearchContactList(false);
  };

  const handleSubmitModal = async (data: AddContactToExistingListSubmitForm) => {
    const dataSubmit = {
      id: contactId,
      contact_category_ids: Array.isArray(data?.contact_category_ids)
        ? data.contact_category_ids.map((item: any) => item.id)
        : [],
    };
    setIsLoading(true);
    const result = await dispatch<any>(addContactToExistingList(dataSubmit));
    if (result?.meta?.requestStatus === "fulfilled") {
      toast.success<void>(t("add_contact_to_list_successful"));
      onClose();
      await dispatch<any>(fetchContactCategoriesByContactId());
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setIsLoading(false);
  };

  const getErrorsValidate = (name: any) => {
    return Object.keys(errors).length !== 0 ? errors[name as keyof typeof errors]?.message : errorFromApis?.[name]?.[0];
  };

  return (
    <>
      {isLoading && <Loading />}
      <form onSubmit={handleSubmit(handleSubmitModal)} noValidate>
        <AutoCompleteForm
          title={t("list_name")}
          data={contactCategories}
          isMultiple={true}
          name="contact_category_ids"
          register={register}
          key={"contact_category_ids"}
          control={control}
          errors={getErrorsValidate("contact_category_ids")}
          onChangeSearch={handleOnChangeSearch}
          maxHeight={120}
          isLoading={isLoadingSearchContactList}
          isSearchApi={true}
        />
        <div className={classes.containerBtn}>
          <ButtonCancelForm onClick={handleBackClick} />
          <ButtonSaveForm />
        </div>
      </form>
      <ModalConfirmLeaveForm
        isOpen={isOpenModalConfirmLeaveForm}
        onClose={() => {
          setIsOpenModalConfirmLeaveForm(false);
          onClose();
        }}
        onConfirm={() => {
          setIsOpenModalConfirmLeaveForm(false);
        }}
      />
    </>
  );
}
