import React, { useEffect, useMemo, useRef } from "react";

import { debounce } from "lodash";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";

interface IProps {
  value: string;
  uploadImageHandler: (file: File) => Promise<string>;
  onChange: (newValue: string) => void;
  changeDelay?: number;
}

const MyCKEditor: React.FC<IProps> = ({ value, uploadImageHandler, onChange, changeDelay = 200 }) => {
  const uploadHandler = useRef(uploadImageHandler);

  const changeDebounce = useMemo(
    () => debounce((newValue: string) => onChange(newValue.replace("&amp;", "&")), changeDelay),
    [changeDelay, onChange]
  );

  useEffect(() => {
    uploadHandler.current = uploadImageHandler;
  }, [uploadImageHandler]);

  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file: File) => {
            uploadHandler
              .current(file)
              .then((res) => {
                resolve({
                  default: res,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CKEditor
      data={value}
      config={{
        toolbar: {
          shouldNotGroupWhenFull: true,
        },
        extraPlugins: [uploadPlugin],
      }}
      editor={DecoupledEditor as any}
      onChange={(event: any, editor: any) => {
        const data = editor.getData();
        changeDebounce(data);
      }}
      onReady={(editor) => {
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
      }}
    />
  );
};

export default MyCKEditor;
