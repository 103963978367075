import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    width: "250px",
    maxWidth: "250px",
    borderRadius: "5px",
    border: "0.5px dashed #413C51",
    padding: "10px",
  },
  wrapHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  wrapBtn: {
    display: "flex",
    marginRight: "-5px"
  },
  title: {
    fontWeight: 700,
    margin: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  iconEdit: {
    color: "#413C51",
    width: "35px",
    height: "35px",
    marginRight: "-5px",
  },
  iconDelete: {
    color: "#FF0000",
    width: "35px",
    height: "35px",
  },
}));
