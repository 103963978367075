import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LooseObject } from "models/common";
import { RootState } from "redux/store";
import { axiosClient } from "services/axiosClient";
import { API } from "utils/api";

const initialState: LooseObject = {
  isRemoveContactToList: null,
  error: null
}

export interface IRemoveContactToList {
  id: number,
  contact_category_ids: Array<number>
}

export interface IAddContactToNewList {
  id: number,
  unit_tags?: Array<number>,
  name: string,
  source?: string
}

export interface IAddContactToExistingList {
  id: number,
  contact_category_ids: Array<number>
}

export interface IAddExistingContactPoint {
  contact_id: number,
  contact_point_ids: Array<number>
}

interface IAddNewContactPoint {
  contact_id: number,
  full_name: string,
  phone_number?: string,
  relationship?: string,
  address?: string,
  email?: string
}

export const addNewContactPoint = createAsyncThunk("actionContact/addNewContactPoint", async(data: IAddNewContactPoint, { rejectWithValue, fulfillWithValue, dispatch}) => {
  dispatch(resetStateStatus());
  const url: string = `${API.studentContact}/${data.contact_id}/add-to-new-contact-point`;
  return await axiosClient.post(url, data)
    .then(response => fulfillWithValue(response?.data?.data))
    .catch(response => rejectWithValue(response?.response?.data?.errors))
});

export const addExistingContactPoint = createAsyncThunk("actionContact/addExistingContactPoint", async(data: IAddExistingContactPoint, { rejectWithValue, fulfillWithValue, dispatch}) => {
  dispatch(resetStateStatus());
  const url: string = `${API.studentContact}/${data.contact_id}/add-to-existing-contact-point`;
  const paramsData = { contact_point_ids: data.contact_point_ids };
  return await axiosClient.post(url, paramsData)
    .then(response => fulfillWithValue(response?.data?.data))
    .catch(response => rejectWithValue(response?.response?.data?.errors))
})

export const removeContactPoint = createAsyncThunk(
  "actionContact/removeContactPoint",
  async(data: IAddExistingContactPoint, { rejectWithValue, fulfillWithValue, dispatch}) => {
    dispatch(resetStateStatus());
    const url: string = `${API.studentContact}/${data.contact_id}/remove-to-contact-point`;
    const paramsData = { contact_point_ids: data.contact_point_ids };
    return await axiosClient.post(url, paramsData)
      .then(response => fulfillWithValue(response?.data?.data))
      .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
)

export const removeContactToList = createAsyncThunk(
  "actionContact/removeContactToList",
  async(data: IRemoveContactToList, { rejectWithValue, fulfillWithValue, dispatch}) => {
    dispatch(resetStateStatus());
    const url: string = `${API.studentContact}/${data.id}/remove-to-list`;
    const paramsData = { contact_category_ids: data.contact_category_ids };
    return await axiosClient.post(url, paramsData)
      .then(response => fulfillWithValue(response?.data?.data))
      .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
)

export const addContactToNewList = createAsyncThunk(
  "actionContact/addContactToNewList",
  async(data: IAddContactToNewList, { rejectWithValue, fulfillWithValue, dispatch, getState}) => {
    dispatch(resetStateStatus());
    const state = getState() as RootState;
    const url: string = `${API.studentContact}/${data.id}/add-to-new-list`;
    const paramsData = {
      unit_tags: data.unit_tags,
      name: data.name,
      source: data.source,
      type: state.contactCategory.type
    };
    return await axiosClient.post(url, { ...paramsData })
      .then(response => fulfillWithValue(response?.data?.data))
      .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
);

export const addContactToExistingList = createAsyncThunk(
  "actionContact/addContactToExistingList", async(data: IAddContactToExistingList, { rejectWithValue, fulfillWithValue, dispatch, getState}) => {
    dispatch(resetStateStatus());
    const state = getState() as RootState;
    const url: string = `${API.studentContact}/${data.id}/add-to-existing-list`;
    const paramsData = {
      ...data,
      type: state.contactCategory.type
    }
    return await axiosClient.post(url, paramsData )
      .then(response => fulfillWithValue(response?.data?.data))
      .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
)

const actionContactSlice = createSlice({
  name: "actionContact",
  initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isRemoveContactToList = null;
    },
    resetStateError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(removeContactToList.pending, (state) => {
      state.isRemoveContactToList = "pending";
    })
    builder.addCase(removeContactToList.fulfilled, (state, action) => {
      state.isRemoveContactToList = "fulfilled";
    })
    builder.addCase(removeContactToList.rejected, (state) => {
      state.isRemoveContactToList = "rejected";
    })
    builder.addCase(addContactToNewList.rejected, (state, action) => {
      state.error = action.payload;
    })
    builder.addCase(addExistingContactPoint.rejected, (state, action) => {
      state.error = action.payload;
    })
    builder.addCase(addContactToExistingList.rejected, (state, action) => {
      state.error = action.payload;
    })
    builder.addCase(addNewContactPoint.rejected, (state, action) => {
      state.error = action.payload;
    })
    builder.addCase(removeContactPoint.rejected, (state, action) => {
      state.error = action.payload;
    })
  }
})

export default actionContactSlice;
export const { resetStateStatus, resetStateError } = actionContactSlice.actions;
export const isRemoveContactToListSelector = (state: any) => state?.actionContact?.isRemoveContactToList;
export const getErrorActionContactSelector = (state: any) => state?.actionContact?.error;