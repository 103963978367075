import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { LooseObject } from "models/common";
import { RootState } from "redux/store";
import { axiosClient } from "services/axiosClient";
import { API } from "utils/api";

interface PermissionsState {
  permissions: IPermission[];
  permissionsHandled: LooseObject[];
  userPermissions: IPermission[];
}

export interface IPermission {
  name?: string;
  id: number;
  description?: string;
}

export const getPermissions = createAsyncThunk(
  "permissions/getPermissions",
  async () => {
    const url: string = API.listPermissions;
    const response = await axiosClient.get(url);
    const data = response.data.data;
    return data;
  }
);

const initialState: PermissionsState = {
  permissions: [],
  permissionsHandled: [],
  userPermissions: []
};

const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    resetUserPermissions: (state) => {
      state.userPermissions = [];
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload?.data;
    });
  }
});

interface IGroupedPermissions {
  [key: string]: {
    name: string;
    data: IPermission[];
  }
}

export default permissionSlice;

export const { setPermissions } = permissionSlice.actions;
export const permissionsSelector = (state: RootState): IPermission[] => state.permissions?.permissions;
export const permisisonsGroupSelector = createSelector(permissionsSelector, (permissions) => {
  const groupedPermissions: IGroupedPermissions = {
    student_contact: {
      name: "Student contact",
      data: [],
    },
    student_list:  {
      name: "Student list",
      data: [],
    },
    master_contact:  {
      name: "Master contact",
      data: [],
    },
    master_list:  {
      name: "Master list",
      data: [],
    },
    role:  {
      name: "Role",
      data: [],
    },
    user: {
      name: "User",
      data: [],
    },
    online_admission: {
      name: "Online Admission",
      data: [],
    },
    reminder_management: {
      name: "Reminder management",
      data: [],
    },
    report: {
      name: "Reports",
      data: [],
    },
  };
  const addPermisisonToList = (permission: IPermission, list: string) => {
    if (permission?.name?.includes(list)) {
      const data = {
        id: permission?.id,
        name: permission?.description
      }
      groupedPermissions[list]["data"].push(data)
    }
  }

  permissions?.forEach((permission: IPermission) => {
    addPermisisonToList(permission, "student_contact");
    addPermisisonToList(permission, "student_list");
    addPermisisonToList(permission, "master_contact");
    addPermisisonToList(permission, "master_list");
    addPermisisonToList(permission, "role");
    addPermisisonToList(permission, "user");
    addPermisisonToList(permission, "online_admission");
    addPermisisonToList(permission, "reminder_management");
    addPermisisonToList(permission, "report");
  })  
  return groupedPermissions;
})
export const userPermissionsSelector = (state: RootState) => state.permissions?.userPermissions;
export const permissionHandledSelector = createSelector(permissionsSelector, userPermissionsSelector, (permissions, userPermissions) => {
  return null;
});