import Loading from "components/Loading/Loading";
import UserForm from "components/UserForm";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppDispatch } from "redux/store";
import { createUser } from "store/userSlice";

export default function CreateUser() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandle = async (data: any) => {
    setIsLoading(true);
    const response = await dispatch(createUser(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      toast.success<void>(t("create_user_successful"));
      navigate("/user");
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <UserForm onSubmitData={onSubmitHandle} />
    </>
  );
}
