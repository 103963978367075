import { io } from "socket.io-client";

const domainSocket = process.env.REACT_APP_URL_SOCKET;
const socket = io(domainSocket as string);
const prefixSocket = process.env.REACT_APP_PREFIX_SOCKET;

export const socketListenImport = (id: number, handleListen: Function) => {
  socket.on(`${prefixSocket}:import_contact_student_${id}`, (res: any) => {
    handleListen(res);
  });
};

export const socketListenEmailBatchJobFinished = (id: number, handleListen: Function) => {
  socket.on(`${prefixSocket}:email_batch_job_finished_${id}`, (res: any) => {
    handleListen(res);
  });
};

export const socketListenSmsBatchJobFinished = (id: number, handleListen: Function) => {
  socket.on(`${prefixSocket}:sms_batch_job_finished_${id}`, (res: any) => {
    handleListen(res);
  });
};

export const socketListenTaskSupportRequest = (id: number, handleListen: Function) => {
  socket.on(`${prefixSocket}:task_support_request${id}`, (res: any) => {
    handleListen(res);
  });
};
