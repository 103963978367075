import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: "100%",
    minHeight: "calc(100vh - 147px)",
    "& .MuiMenuItem-root": {
      fontFamily: "Roboto",
    }
  },
  contentPage: {
    backgroundColor: "#FFF",
    margin: "16px",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "16px",
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
    textAlign: "left",
    "& tr": {
      height: "30px",
    },
    "& th": {
      backgroundColor: "#F2F2F2",
      color: "#413C51",
      fontSize: "14px",
      fontWeight: 700,
      textAlign: "center",
    },
    "& td": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#413C51",
    },
  },
  tdSwitch: {
    textAlign: "center",
    width: "150px",
  },
  tdName: {
    paddingLeft: "13px",
  },
  noData: {
    textAlign: "center",
    fontSize: "14px",
  },
  evenRow: {
    backgroundColor: "#FFF",
  },
  oddRow: {
    backgroundColor: "#F2F2F2",
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center",
    marginTop: "22px"
  },
  btn: {
    backgroundColor: colors.btnPrimary,
    margin: "22px",
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
  titleText: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#413C51",
    marginBottom: "25px",
  },
  classNameSlect: {
    width: "387px !important",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  classNameInput: {
    width: "387px !important",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
}));
