import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  dialogContent: {
    [theme.breakpoints.up("xs")]: {
      padding: "8px 16px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "16px 24px",
    },
  },
  title: {
    backgroundColor: "#FFF",
    height: "50px",
    fontSize: "18px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    height: "30px",
  },
  btnYes: {
    backgroundColor: "#6A7A83",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#6A7A83",
    },
  },
  btnNo: {
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "20px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  containerContent: {
    // overflow: "hidden",
  },
  wrapColumn: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      margin: "0 16px",
    },
  },
  wrapColumnLeft: {
    flex: 1,
    [theme.breakpoints.up("md")]: {
      marginRight: "16px",
    },
  },
  wrapColumnRight: {
    flex: 1,
    [theme.breakpoints.up("xs")]: {
      marginLeft: "5px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "16px",
    },
  },
  column: {
    flex: 1,
    backgroundColor: "#F5F5F5",
    borderRadius: "5px",

    [theme.breakpoints.up("xs")]: {
      padding: "5px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "16px",
    },
  },
  headerColunm: {
    fontWeight: 700,
    fontSize: "14px",
    color: colors.defaultColorText,
  },
  itemLeft: {
    [theme.breakpoints.up("xs")]: {
      padding: "0 8px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 16px",
    },

    height: "26px",
    borderRadius: "5px",
    backgroundColor: "#CDC4E3",
    cursor: "move",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    wordBreak: "break-word",
    textOverflow: "ellipsis",
    "& p": {
      fontSize: "14px",
      fontWeight: 400,
      whiteSpace: "nowrap",
      wordBreak: "break-word",
      textOverflow: "ellipsis",
    },
  },
  itemRight: {
    [theme.breakpoints.up("xs")]: {
      padding: "0 8px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 16px",
    },

    height: "26px",
    borderRadius: "5px",
    backgroundColor: "#FFBFAB",
    cursor: "move",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    wordBreak: "break-word",
    textOverflow: "ellipsis",
    "& p": {
      fontSize: "14px",
      fontWeight: 400,
      whiteSpace: "nowrap",
      wordBreak: "break-word",
      textOverflow: "ellipsis",
    },
  },
  wrapDropable: {
    minHeight: 100,
    paddingBottom: "20px",
  },
  btnClose: {
    position: "absolute",
    right: 0,
    backgroundColor: "#FFF",
    color: "#4A4358",
  },
}));
