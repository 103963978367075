import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { saveUserInfo } from "../../redux/auth/auth.service";
import { loginSuccess } from "../../redux/auth/auth.slice";
import { API } from "../../utils/api";
import { getPathToRedirect, getViewPermission } from "utils";

export default function LoginGoogle() {
  const [dataFetched, setDataFetched] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!dataFetched) {
      fetch(`${API.callbackLoginGoogle}${location.search}`, {
        headers: new Headers({ accept: "application/json" }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong!");
        })
        .then(async (data) => {
          await dispatch(loginSuccess(data.data));
          saveUserInfo(data.data);
          const listPermission = getViewPermission(data.data?.user);
          const path = getPathToRedirect(listPermission);
          navigate(path);
          setDataFetched(true);
        })
        .catch((error) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
