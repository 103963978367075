import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { RootState } from "redux/store";

interface ReportState {
  quantityContact: any;
}

const initialState: ReportState = {
  quantityContact: {},
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuantityContact.fulfilled, (state, action) => {
      state.quantityContact = action.payload;
    });
  },
});

export const getQuantityContact = createAsyncThunk(
  "reports/getAll",
  async (
    data: { dateFilter: any; dateStart?: any; dateEnd?: any },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const url = API.quantityContactReport(
        data.dateFilter,
        data.dateStart || "",
        data.dateEnd || ""
      );
      const response = await axiosClient.get(url);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export default reportSlice;

export const quantityContactSelector = (state: RootState) =>
  state?.reports?.quantityContact;
