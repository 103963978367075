import { Box, Typography } from "@mui/material";
import ReportDateRangePicker from "components/ReportDateRangePicker";
import moment from "moment";
import React from "react";

interface IProps extends React.PropsWithChildren {
  title?: string;
  minWidthSelect?: string | number;
  leftMode?: boolean;
}

const StatisticChartBase: React.FC<IProps> = ({ title, minWidthSelect = "300px",leftMode, children }) => {
  const [startUpdate, setStartUpdate] = React.useState(moment());
  const [endUpdate, setEndUpdate] = React.useState(moment());

  const handleDateRange = (startDate: any, endDate: any) => {
    setStartUpdate((prev) => (prev = startDate));
    setEndUpdate((prev) => (prev = endDate));
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1" fontWeight="bold" sx={{ mb: "16px" }}>
            {title}
          </Typography>
          <Box>
            <ReportDateRangePicker
              leftMode={leftMode}
              noMobileMode={leftMode}
              start={startUpdate}
              end={endUpdate}
              applyCallback={handleDateRange}
            />
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </>
  );
};

export default StatisticChartBase;
