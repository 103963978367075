import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  headerModal: {
    height: "40px",
    backgroundColor: "#4A4358",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: 700,
    paddingLeft: "16px",
  },
  wrapTitle: {
    height: "40px",
    backgroundColor: "#4A4358",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    "& p": {
      marginLeft: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  containerContent: {
    display: "block",
    padding: "10px 20px 24px",
  },
  formControl: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  btnCancel: {
    height: "31px",
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "51px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  btnSearch: {
    height: "31px",
    backgroundColor: colors.btnDark,
    "&:hover": {
      backgroundColor: colors.btnDark,
    },
  },
  containerForm: {
    paddingLeft: "12.5px",
  },
  headerTextForm: {
    color: colors.defaultColorText,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    ":hover": {
      color: colors.defaultColorText,
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  headerDiviverForm: {
    width: "1px",
    height: "38px",
    backgroundColor: "#DEDEDE",
    margin: "0 20px",
  },
  containerHeaderForm: {
    display: "flex",
    justifyContent: "center",
    height: "38px",
    alignItems: "center",
    marginBottom: "10px",
  },
  activeHeaderTextForm: {
    color: `${colors.danger} !important`,
  },
  btnClose: {
    backgroundColor: "#4A4358",
    color: "#FFF",
  },
}));
