import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { IconButton } from "@mui/material";
import Chip from "components/Buttons/Chip";
import Comment from "components/Comment";
import ModalConfirm from "components/Modal/ModalConfirm";
import RoleBaseComponent from "components/RoleBaseComponent";
import HTMLReactParser from "html-react-parser";
import { ImageComment, LooseObject } from "models/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { TASK_TYPE } from "utils";

interface TaskDetailProp {
  task: LooseObject;
  childrens?: JSX.Element;
  className?: string;
  taskResult: LooseObject[];
  handleChangeTask: Function;
  handleAddComment: Function;
  handleTongleCmt: Function;
  listIdTaskShowAddCmt: number[];
  handleOpenModalEdit: Function;
  handleClickUser: Function;
  permissions: string[];
  handleChangeResult: Function;
}

export default function TaskDetail({
  task,
  childrens,
  className,
  taskResult,
  handleChangeTask,
  handleAddComment,
  listIdTaskShowAddCmt,
  handleTongleCmt,
  handleOpenModalEdit,
  handleClickUser,
  permissions,
  handleChangeResult,
}: TaskDetailProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [results, setResults] = useState<LooseObject[]>([]);
  const [isOpenModalChangeResult, setIsOpenModalChangeResult] = useState<boolean>(false);
  const [newResult, setNewResult] = useState<LooseObject | null>(null);

  useEffect(() => {
    let listResult: LooseObject[] = [...taskResult];
    listResult = listResult.filter((item: LooseObject) => item?.task_type_id === task?.type?.id);
    setResults(listResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const handleExpandCollapse = () => {
    const newData = { ...task };
    newData.show = !task?.show;
    handleChangeTask(newData);
  };

  const handleSubmitComment = async (data: any, listImage: ImageComment[]) => {
    const result = await handleAddComment(task?.id, data, listImage);
    return result;
  };

  return (
    <>
      <div className={classes.container}>
        <div className={`${classes.containerHeader} ${className}`}>
          <div className={classes.wrapTitle}>
            <div>
              <IconButton onClick={() => handleExpandCollapse()} className={classes.btnArrow}>
                {task?.show ? (
                  <KeyboardArrowDownRoundedIcon className={classes.arrow} />
                ) : (
                  <KeyboardArrowRightRoundedIcon className={classes.arrow} />
                )}
              </IconButton>
              <span className={classes.title}>
                #T{task?.id} - {task?.name}
              </span>
            </div>
            {task?.type?.id === TASK_TYPE && (
              <div className={classes.resizeIconUrgent}>
                <span className={`${classes.iconMenu} ${classes.iconUrgent}`} />
              </div>
            )}
          </div>
          <div className={classes.wrapRightHeader}>
            <div className={classes.wrapTime}>
              <div>{`${t("created_at")}: ${task?.created_at}`}</div>
              <div className={classes.redColor}>{`${t("deadline_at")}: ${task?.deadline_at}`}</div>
            </div>
            <RoleBaseComponent permissions={[...permissions]}>
              <IconButton className={classes.btnPen} onClick={() => handleOpenModalEdit(task)}>
                <CreateRoundedIcon className={classes.iconPen} />
              </IconButton>
            </RoleBaseComponent>
          </div>
        </div>
        <div>
          {task?.show ? (
            <>
              <div className={classes.containerBody}>
                <div className={classes.wrapTypeResult}>
                  <div className={classes.labelTypeResult}>{`${t("type")}: `}</div>
                  <span className={classes.title}>{task?.type?.name}</span>
                </div>
                <div className={classes.wrapTypeResult}>
                  <div className={classes.labelTypeResult}>{`${t("result")}: `}</div>
                  <span>
                    <div className={classes.wrapResult}>
                      {results.map((item: LooseObject) => {
                        return (
                          <Chip
                            label={item?.name}
                            handleClick={() => {
                              setNewResult(item);
                              setIsOpenModalChangeResult(true);
                            }}
                            key={`${item.id} - a`}
                            isActive={item.id === task?.result?.id}
                          />
                        );
                      })}
                    </div>
                  </span>
                </div>
                <div className={classes.wrapControl}>
                  <div className={classes.label}>{`${t("assigned_to")}: `}</div>
                  <span className={classes.redColor}>{task?.assigned_to?.name}</span>
                </div>
                <div className={classes.wrapControl}>
                  <div className={classes.label}>{`${t("contact")}: `}</div>
                  <span>
                    {Array.isArray(task?.tasks_in_group) &&
                      task?.tasks_in_group.map((item: LooseObject, index: number) => (
                        <span key={`${item?.contact_id} - e`}>
                          <span className={classes.spanContactLink}>{index !== 0 ? ", " : ""}</span>
                          <span
                            className={`${
                              item?.contact_id === task?.contact_id ? classes.redColor : classes.spanContactLink
                            }`}
                            onClick={() => {
                              if (item?.contact_id !== task?.contact_id) {
                                handleClickUser(item?.contact_id, item?.task_id);
                              }
                            }}
                          >
                            {item?.contact_name}
                          </span>
                        </span>
                      ))}
                  </span>
                </div>
                <hr className={classes.hr} />
                <div>
                  <div className={`${classes.title} ${classes.labelDescription}`}>{t("description")}</div>
                  <div className={classes.contentDescription} dangerouslySetInnerHTML={{ __html: task?.description }} />
                </div>
                <hr className={classes.hr} />
              </div>
              <div className={classes.wrapCmt}>
                {Array.isArray(task?.logs) &&
                  task?.logs.map((item: LooseObject) => (
                    <div key={`${item?.id} - c`}>
                      <div className={classes.wrapHeaderCmt}>
                        <div>
                          <span className={classes.redColor}>{`${item?.author?.name} `}</span>
                          {item?.type === 0 && <span>{t("created_this_task")}</span>}
                          {item?.type === 1 && (
                            <span>
                              {`${t("changed_result_from")} `}
                              <span className={classes.resultTextName}>{`${item?.changed?.result?.old} `}</span>
                              {`${t("to")} `}
                              <span className={classes.resultTextName}>{item?.changed?.result?.new}</span>
                            </span>
                          )}

                          {item?.type === 2 && <span>{t("added_a_comment")}</span>}
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: item?.created_at }} />
                      </div>
                      <div className={classes.contentCmt}>{HTMLReactParser(item?.content ?? "")}</div>
                    </div>
                  ))}
              </div>
              {listIdTaskShowAddCmt.includes(task?.id) ? (
                <Comment onClose={() => handleTongleCmt(task.id)} onsubmit={handleSubmitComment} />
              ) : (
                <RoleBaseComponent permissions={[...permissions]}>
                  <div className={classes.addComment} onClick={() => handleTongleCmt(task.id)}>
                    {t("add_comment")}
                  </div>
                </RoleBaseComponent>
              )}
            </>
          ) : (
            <div className={classes.wrapResultCollap}>
              <div className={classes.labelTypeResult}>{`${t("result")}: `}</div>
              <span>
                <div className={classes.wrapResult}>
                  {results.map((item: LooseObject) => {
                    return (
                      <Chip
                        label={item?.name}
                        handleClick={() => {
                          setNewResult(item);
                          setIsOpenModalChangeResult(true);
                        }}
                        key={`${item.id} - d`}
                        isActive={item.id === task?.result?.id}
                      />
                    );
                  })}
                </div>
              </span>
            </div>
          )}
        </div>
      </div>

      {isOpenModalChangeResult && (
        <ModalConfirm
          isOpen={isOpenModalChangeResult}
          title={t("confirmation")}
          htmlContent={
            <p>
              {t("are_you_sure_to_change_result_from")}{" "}
              <span className={classes.nameResult}>{`${task?.result?.name}`}</span> {t("to")}{" "}
              <span className={classes.nameResult}>{`${newResult?.name}?`}</span>
            </p>
          }
          onClose={() => {
            setNewResult(null);
            setIsOpenModalChangeResult(false);
          }}
          onConfirm={() => {
            setIsOpenModalChangeResult(false);
            handleChangeResult(task?.id, newResult);
          }}
        />
      )}
    </>
  );
}
