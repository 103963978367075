import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import BaseButton from "../../Buttons/BaseButton";

interface ModalConfirmProp {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  title: string;
  headerContent?: string;
  content?: string;
  htmlContent?: JSX.Element;
  contentYes?: any;
  contentNo?: any;
}

export default function ModalConfirm({
  isOpen,
  onClose,
  title,
  headerContent,
  content,
  onConfirm,
  htmlContent,
  contentYes,
  contentNo,
}: ModalConfirmProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <div className={classes.title}>
          <p>{title}</p>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers className={classes.wrapContent}>
          <p className={classes.headerContent}>{headerContent}</p>
          <p>{content}</p>
          {htmlContent}
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={contentNo ? t(contentNo) : t("no")} className={classes.btnNo} onClick={() => onClose()} />
          <BaseButton
            title={contentYes ? t(contentYes) : t("yes")}
            className={classes.btnYes}
            onClick={() => onConfirm()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
