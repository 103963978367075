import { DateTimePicker, LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useStyles } from "./styles";
import {DateOrTimeViewWithMeridiem} from "@mui/x-date-pickers/internals/models";

interface InputDateTimeProp {
  className?: string;
  data?: string;
  handleChange?: Function;
  nameRegister?: string;
  control?: any;
  isPast?: boolean;
  resetField?: Function;
  minDate?: any;
  placeholder?: any;
  _style?: any;
  openTo?: DateOrTimeViewWithMeridiem | undefined;
  isYear?: boolean
}

export default function InputDateTime({ className, data, handleChange, nameRegister, control, isPast, resetField, minDate, placeholder, _style, openTo, isYear }: InputDateTimeProp) {
  const { classes } = useStyles();
  const [openDate, setOpenDay] = useState<boolean>(false);

  return control && nameRegister && !isYear ? (
      <Controller
          control={control}
          name={nameRegister}
          defaultValue={data}
          render={({field: {value, onChange, ref}}) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                      inputRef={ref}
                      sx={_style}
                      open={openDate}
                      onOpen={() => setOpenDay(true)}
                      onClose={() => setOpenDay(false)}
                      format="YYYY-MM-DD HH:mm"
                      ampm={false}
                      className={`${classes.container} ${className}`}
                      value={value || value ? dayjs(value) : null}
                      onChange={(event: any) => {
                          onChange(event?.$d);
                      }}
                      slotProps={{
                          textField: {
                              InputProps: {placeholder: placeholder, readOnly: true},
                              onClick: () => setOpenDay(true),
                              onKeyDown: (e) => e.preventDefault()
                          },
                          field: {clearable: true, onClear: () => resetField && resetField(nameRegister)},
                      }}
                      disablePast={isPast ? false : true}
                      closeOnSelect
                      minDate={minDate ? minDate : ""}
                  />
              </LocalizationProvider>
          )}
        />
        ) :
      control && nameRegister && isYear ?  (
        <Controller
            control={control}
            name={nameRegister}
            defaultValue={data}
            render={({field: {value, onChange, ref}}) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      sx={_style}
                      open={openDate}
                      onOpen={() => setOpenDay(true)}
                      onClose={() => setOpenDay(false)}
                      className={`${classes.container} ${className}`}
                      value={value || value ? dayjs(value) : null}
                      onChange={(event: any) => {
                          onChange(event?.$d);
                      }}
                      slotProps={{
                          textField: { InputProps: { placeholder: placeholder, readOnly: true }, onClick: () => setOpenDay(true), onKeyDown: (e) => e.preventDefault() },
                          field: { clearable: true },
                      }}
                      closeOnSelect
                      openTo="year"
                      views={['year']}
                  />
              </LocalizationProvider>
            )}
        />
      ) : (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        sx={_style}
        open={openDate}
        onOpen={() => setOpenDay(true)}
        onClose={() => setOpenDay(false)}
        format="YYYY-MM-DD"
        className={`${classes.container} ${className}`}
        value={data ?? ""}
        onChange={(event) => handleChange && handleChange(event)}
        slotProps={{
          textField: { InputProps: { placeholder: placeholder, readOnly: true }, onClick: () => setOpenDay(true), onKeyDown: (e) => e.preventDefault() },
          field: { clearable: true },
        }}
        disablePast={isPast ? false : true}
        closeOnSelect
      />
    </LocalizationProvider>
  );
}
