import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  btn: {
    backgroundColor: colors.btnPrimary,
    marginRight: "51px",
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
  backIcon: {
    fontSize: "20px !important",
    marginRight: "-4px",
  },
}));
