import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  container: {
    backgroundColor: colors.backgroundLight,
    padding: "16px 30px 100px 30px",
    "& p": {
      marginBottom: "16px",
    },
    "& table": {
      width: "100%",
      tableLayout: "fixed",
      "& th": {
        textAlign: "left",
        fontSize: "14px",
        fontWeight: 700,
      },
      "& tbody td": {
        borderBottom: "1px dashed #E4E4E4",
        padding: "5px 0",
        fontSize: "14px",
        fontWeight: 400,
      },
    },
  },
  explainStep: {
    fontWeight: 500,
    fontSize: "14px",
    marginTop: 0,
  },
  labelStep: {
    fontSize: "18px",
    fontWeight: 500,
    marginTop: 0,
  },
  hr: {
    margin: "0 0 16px 0",
    borderTop: "1px solid #D3D3D3",
  },
  btnBack: {
    border: "1px solid rgba(26, 24, 79, 0.10)",
    color: colors.defaultColorText,
    backgroundColor: "#FFFFFF",
    marginRight: "50px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  btnNext: {
    backgroundColor: "#6A7A83",
    "&:hover": {
      backgroundColor: "#6A7A83",
    },
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "5px",
  },
  wrapErrorList: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "-15px",
    marginBottom: "10px",
    marginLeft: "30%",
  },
  wrapErrorForm: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "5px",
    marginBottom: "10px",
  },
  inputSelect: {
    width: "70%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#DEF4EE !important",
    },
    "& .MuiAutocomplete-root fieldset": {
      border: "1px solid #C7ECE1",
    },
    "& .MuiSvgIcon-root": {
      color: "#E2887B",
    },
    "@media(max-width: 768px)": {
      width: "100%",
    },
  },
  require: {
    color: "#F00",
  },
  rowInfor: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  containerList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "14px",
    width: "50%",
    "@media(max-width: 768px)": {
      width: "100%",
    },
  },
  wrapChooseList: {
    margin: "16px",
    display: "flex",
    justifyContent: "space-around",
    "& div": {
      width: "200px",
      display: "flex",
      alignItems: "center",
      fontWeight: 600,
      cursor: "pointer",
      height: "35px",
    },
  },
  titleAddExistList: {
    justifyContent: "end",
    marginRight: "24px",
    marginLeft: "24px"
  },
  titleCreateList: {
    justifyContent: "start",
    marginLeft: "24px",
  },
  divider: {
    borderColor: "#DEDEDE",
  },
  isActiveTab: {
    color: "#f2756f",
  },
  formControl: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  label: {
    minWidth: "30%",
  },
  input: {
    width: "100%",
    height: "30px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
  },
  inputSelectList: {
    width: "70%",
  },
}));
