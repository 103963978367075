import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    borderRadius: "4px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#7C8286",
    padding: "0 10px 0 16px",
    "& p": {
      fontSize: "18px",
      fontWeight: 400,
      color: "#FFFFFF",
    },
  },
}));
