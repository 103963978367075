import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
    minWidth: "178px",
    "& .MuiOutlinedInput-root": {
      height: "30px",
      backgroundColor: "#F5F5F5",
      border: "1px solid #DEDEDE",
      borderRadius: "4px",
      padding: "0 8px 0 0",
      boxSizing: "border-box",
      fontFamily: "Roboto",
      fontSize: "14px",
      "&:focus": {
        border: "1px solid #1070bd",
      },
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&:hover": {
        border: "1px solid #202020",
      },
    },
    "& .MuiInputBase-input": {
      paddingLeft: "8px !important",
    },
  },
}));
