import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  contentPage: {
    backgroundColor: "#FFF",
    margin: "16px",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "16px",
  },
  wrapSearchFilter: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  containerSearch: {
    display: "flex",
    flexDirection: "column",
  },
  wrapDropdown: {
    display: "flex",
    alignItems: "center",
  },
  inputSearch: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "430px",
    },
  },
  dropdown: {
    marginRight: "16px",
  },
  containerFilter: {
    display: "flex",
    flexWrap: "wrap",
  },
  clearAll: {
    borderRadius: "12px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#D63916",
    padding: "0 10px",
    width: "fit-content",
    margin: "5px",
    cursor: "pointer",
    "& p": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#FFFFFF",
    },
    "&:hover": {
      opacity: "0.8",
      transition: "ease-in 0.2s",
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
  },
  results: {
    fontSize: "14px",
    color: "#D63916",
    marginLeft: "5px",
    marginTop: "10px",
    fontWeight: 700,
  },
}));
