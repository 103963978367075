import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Loading from "components/Loading/Loading";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "redux/store";
import { addExistingContactPoint, getErrorActionContactSelector } from "store/actionContactSlice";
import contactPointSlice, {
  IContactPoint,
  fetchContactPoint,
  fetchContactPointWithoutContactId,
} from "store/contactPointSlice";
import * as Yup from "yup";
import ButtonCancelForm from "../ButtonCancelForm";
import ButtonSaveForm from "../ButtonSaveForm";
import InputTextSearch from "../InputTextSearch";
import { useStyles } from "./styles";

interface IAddContactToExistingContactPointFormProp {
  onClose: Function;
  contactId: number;
  isOpen: boolean;
}

export default function AddContactToExistingContactPointForm({
  onClose,
  contactId,
  isOpen = false,
}: IAddContactToExistingContactPointFormProp) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const errorFromApis = useSelector(getErrorActionContactSelector);
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearchContactPoint, setIsLoadingSearchContactPoint] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    contact_id: Yup.number(),
    contact_point_ids: Yup.array().min(1, t("this_field_is_required")),
  });

  useEffect(() => {
    if (isOpen) {
      dispatch<any>(contactPointSlice.actions.resetWithoutContactIdFilter());
    }
  }, [dispatch, contactId, isOpen]);

  const contactPoints = useSelector((state: RootState) => state.contactPoint.contactPointsWithoutContactIdData);

  type AddContacdtToExistingListSubmitForm = Yup.InferType<typeof validationSchema>;
  const {
    control,
    handleSubmit,
    setValue,
    resetField,
    clearErrors,
    formState: { errors },
  } = useForm<AddContacdtToExistingListSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      contact_id: contactId,
      contact_point_ids: [],
    },
  });

  const handleSubmitModal = async (data: AddContacdtToExistingListSubmitForm) => {
    setIsLoading(true);
    const dataSubmit = {
      contact_id: contactId,
      contact_point_ids: data?.contact_point_ids ?? [],
    };
    const response = await dispatch<any>(addExistingContactPoint(dataSubmit));
    if (response?.meta?.requestStatus === "fulfilled") {
      toast.success<void>(t("add_contact_point_successful"));
      dispatch<any>(contactPointSlice.actions.resetFilter());
      await dispatch<any>(fetchContactPoint());
      onClose();
    }
    setIsLoading(false);
  };

  const getErrorsValidate = (name: any) => {
    return Object.keys(errors).length !== 0 ? errors[name as keyof typeof errors]?.message : errorFromApis?.[name]?.[0];
  };

  const handleOnChangeSearch = async (value: any) => {
    setIsLoadingSearchContactPoint(true);
    clearErrors("contact_point_ids");
    dispatch<any>(contactPointSlice.actions.setWithOutContactId(contactId));
    dispatch<any>(contactPointSlice.actions.setFullNameWithoutContactId(value));
    if (value === "") {
      resetField("contact_point_ids");
      dispatch<any>(contactPointSlice.actions.resetWithoutContactIdFilter());
    } else {
      await dispatch<any>(fetchContactPointWithoutContactId());
    }
    setIsLoadingSearchContactPoint(false);
  };

  const getResultContactPoint = (contactPoint: IContactPoint) => {
    let result = contactPoint.full_name;
    if (contactPoint.email && contactPoint.phone_number) {
      result = `${result} (${contactPoint.phone_number} - ${contactPoint.email})`;
    } else if (contactPoint.email) {
      result = `${result} (${contactPoint.email})`;
    } else if (contactPoint.phone_number) {
      result = `${result} (${contactPoint.phone_number})`;
    }
    return result;
  };

  const handleResultOnChange = (e: any) => {
    setValue("contact_point_ids", [parseInt(e.target.value)]);
    clearErrors("contact_point_ids");
  };

  return (
    <>
      {isLoading && <Loading />}
      <form onSubmit={handleSubmit(handleSubmitModal)} noValidate>
        <InputTextSearch
          name={"full_name"}
          errors={getErrorsValidate("contact_point_ids")}
          title={t("full_name")}
          onChange={(value: any) => handleOnChangeSearch(value)}
        />
        {isLoadingSearchContactPoint && (
          <div className={classes.resultContainer}>
            <CircularProgress /> loading...
          </div>
        )}
        {!isLoadingSearchContactPoint && contactPoints.length > 0 && (
          <div className={classes.resultContainer}>
            <div className={classes.result}>
                {contactPoints.length} {t("results")}
              </div>
            <FormControl>
              <Controller
                rules={{ required: true }}
                control={control}
                name="contact_point_ids"
                render={({ field: { ...field } }) => (
                  <RadioGroup
                    {...field}
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={handleResultOnChange}
                  >
                    {contactPoints.map((item: IContactPoint) => (
                      <FormControlLabel
                        value={item.id}
                        control={<Radio className={classes.radio} />}
                        label={getResultContactPoint(item)}
                        key={"contact_point" + item.id}
                      />
                    ))}
                  </RadioGroup>
                )}
              ></Controller>
            </FormControl>
          </div>
        )}
        <div className={classes.containerBtn}>
          <ButtonCancelForm onClick={() => onClose()} />
          <ButtonSaveForm />
        </div>
      </form>
    </>
  );
}
