import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserInfo } from "redux/auth/auth.service";
import { RootState } from "redux/store";
import { axiosClient } from "services/axiosClient";
import { CALL_LOG_TYPE } from "utils";
import { API } from "utils/api";

interface UserProfile {
  full_name: string;
  phone_number: string;
}

interface IProps {
  isOpenModal: boolean;
  contactId: number;
  user: UserProfile;
  type: (typeof CALL_LOG_TYPE)[number]["value"];
  callInfo: any;
  acceptedLog: any;
}

const initialState: IProps = {
  isOpenModal: false,
  contactId: 0,
  user: {
    full_name: "",
    phone_number: "",
  },
  type: CALL_LOG_TYPE[2]?.value,
  callInfo: {},
  acceptedLog: {},
};

const callLogSlice = createSlice({
  name: "callLog",
  initialState,
  reducers: {
    setDataCallLog: (state, action) => {
      if (!state.isOpenModal) {
        state.isOpenModal = action.payload?.isOpenModal ?? true;
        state.contactId = action.payload?.contactId;
        state.user = action.payload?.user;
        state.type = action.payload?.type;
      }
    },
    setIsEndCallLog: (state) => {
      state.isOpenModal = initialState.isOpenModal;
      state.contactId = initialState.contactId;
      state.user = initialState.user;
      state.type = initialState.type;
    },
    setAcceptedLog: (state, action) => {
      state.acceptedLog = action.payload?.acceptedLog;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCallLogInfo.fulfilled, (state, action) => {
      state.callInfo = action.payload;
    });
  },
});

export const getCallLogInfo = createAsyncThunk(
  "callLog/getCallLogInfo",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = API.callInformation;
      const response = await axiosClient.get(url);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCallLog = (contactId: number | string, callId: string) => async () => {
  try {
    const userInfor = getUserInfo();
    const response = await axiosClient.post(API.updateCallLog, {
      "user_id": userInfor?.user?.id,
      "contact_id": contactId,
      "call_id": callId,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default callLogSlice;

export const { setDataCallLog, setIsEndCallLog } = callLogSlice.actions;
export const isOpenCallLogModalSelector = (state: RootState) => state?.callLog?.isOpenModal;
export const userCallLogSelector = (state: RootState) => state?.callLog?.user;
export const typeCallLogSelector = (state: RootState) => state?.callLog?.type;
export const contactIdCallLogSelector = (state: RootState) => state?.callLog?.contactId;
export const callInfoSelector = (state: RootState) => state?.callLog?.callInfo;
