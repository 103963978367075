import BaseButton from "components/Buttons/BaseButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { SxProps } from "@mui/material";

interface IButtonSaveFormProps {
  sx?: SxProps;
}

export default function ButtonSaveForm({ sx }: IButtonSaveFormProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <BaseButton
      sx={{ ...sx, width: "93px" }}
      loading={false}
      isSubmit={true}
      title={t("save")}
      className={classes.btn}
    />
  );
}
