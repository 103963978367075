import MyCKEditor from "components/MyCKEditor";
import React from "react";

import { Controller } from "react-hook-form";
import ErrorMessageForm from "../ErrorMessageForm";
import { useStyles } from "./styles";

interface IProps {
  title?: any;
  isRequired?: boolean;
  widthLabel?: number;
  marginRight?: number;
  errors?: any;
  uploadImageHandler: (file: File) => Promise<string>;
  changeDelay?: number;
  name: string;
  control: any;
}

const CKEditorForm: React.FC<IProps> = (props) => {
  const {
    uploadImageHandler,
    changeDelay = 200,
    title,
    isRequired,
    widthLabel = 80,
    errors,
    marginRight = 60,
    name,
    control,
  } = props;
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrap}>
          <p style={{ width: widthLabel }} className={classes.label}>
            {title} {isRequired && <span>*</span>}
          </p>
          <div className={classes.input}>
            <Controller
              name={name}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <MyCKEditor
                    value={value}
                    uploadImageHandler={uploadImageHandler}
                    onChange={onChange}
                    changeDelay={changeDelay}
                  />
                );
              }}
            />
          </div>
        </div>
        <ErrorMessageForm errors={errors} widthLabel={widthLabel} marginRightLabel={marginRight} />
      </div>
    </>
  );
};

export default CKEditorForm;
