import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFilter } from "models/common";
import { RootState } from "redux/store";
import { axiosClient } from "services/axiosClient";
import { API } from "utils/api";

export interface IContactPoint {
  id: number;
  full_name: string;
  phone_number: string;
  relationship: string;
  address: string;
  email: string;
}

interface IContactPointFilter extends IFilter {
  contact_id: number;
}

interface IContactPointWithoutContactIdFilter extends IFilter {
  without_contact_id: number;
  full_name?: string;
}

interface IContactPointInitialState {
  filterData: IContactPointFilter;
  filterWithoutContactIdData: IContactPointWithoutContactIdFilter;
  contactPoints: IContactPoint[];
  contactPointsWithoutContactIdData: IContactPoint[];
  errors: any;
  contactPoint: IContactPoint;
  contactPointsForSelected: IContactPoint[];
}

const initialState: IContactPointInitialState = {
  filterData: {
    page: 1,
    limit: 5,
    contact_id: 1,
    total: 0,
  },
  filterWithoutContactIdData: {
    page: 1,
    limit: 5,
    without_contact_id: 1,
    total: 0,
    full_name: "",
  },
  contactPoints: [],
  contactPointsForSelected: [],
  contactPointsWithoutContactIdData: [],
  contactPoint: {
    id: 0,
    full_name: "",
    phone_number: "",
    relationship: "",
    address: "",
    email: "",
  },
  errors: [],
};

export const getContactsFromQueryAndCategory = createAsyncThunk(
  "contactPoints/getContactsFromQueryAndCategory",
  async (params: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.get(API.getContactsFromQueryAndCategory(params));
      if (response.status === 200) {
        return fulfillWithValue(response.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchContactPoint = createAsyncThunk(
  "contactPoints/fetchContactPoint",
  async (_, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const payload = state.contactPoint.filterData;
      const response = await axiosClient.get(API.restfulContactPoint, {
        params: { ...payload },
      });
      return fulfillWithValue(response?.data);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getContactPointById = createAsyncThunk(
  "contactPoints/getContactPointById",
  async (id: number, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.get(`${API.restfulContactPoint}/${id}`);
      return fulfillWithValue(response?.data);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateContactPointById = createAsyncThunk(
  "contactPoints/updateContactPointById",
  async (data: IContactPoint, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.put(`${API.restfulContactPoint}/${data.id}`, data);
      return fulfillWithValue(response?.data);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);

export const fetchContactPointWithoutContactId = createAsyncThunk(
  "contactPoints/fetchContactPointWithoutContactId",
  async (_, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const payload = state.contactPoint.filterWithoutContactIdData;
      const response = await axiosClient.get(API.restfulContactPoint, {
        params: { ...payload },
      });
      return fulfillWithValue(response?.data);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const contactPointSlice = createSlice({
  name: "contactPoints",
  initialState,
  reducers: {
    resetErrorState: (state) => {
      state.errors = {};
    },
    resetFilter: (state) => {
      state.filterData.page = 1;
    },
    resetWithoutContactIdFilter: (state) => {
      state.filterWithoutContactIdData.full_name = "";
      state.contactPointsWithoutContactIdData = [];
    },
    setPage: (state, action) => {
      state.filterData.page = action.payload;
    },
    setContactId: (state, action) => {
      state.filterData.contact_id = action.payload;
    },
    setLimit: (state, action) => {
      state.filterData.limit = action.payload;
    },
    setWithOutContactId: (state, action) => {
      state.filterWithoutContactIdData.without_contact_id = action.payload;
    },
    setFullNameWithoutContactId: (state, action) => {
      state.filterWithoutContactIdData.full_name = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContactsFromQueryAndCategory.fulfilled, (state, action) => {
      state.contactPointsForSelected = action.payload;
    });
    builder.addCase(fetchContactPoint.fulfilled, (state, action) => {
      state.contactPoints = action.payload?.data;
      state.filterData.page = action.payload?.current_page;
      state.filterData.limit = action.payload?.per_page;
      state.filterData.total = action.payload?.total;
    });
    builder.addCase(fetchContactPointWithoutContactId.fulfilled, (state, action) => {
      state.contactPointsWithoutContactIdData = action.payload?.data;
      state.filterWithoutContactIdData.page = action.payload?.current_page;
      state.filterWithoutContactIdData.limit = action.payload?.per_page;
      state.filterWithoutContactIdData.total = action.payload?.total;
    });
    builder.addCase(getContactPointById.fulfilled, (state, action) => {
      state.contactPoint = action.payload?.data;
    });
    builder.addCase(updateContactPointById.rejected, (state, action) => {
      state.errors = action.payload;
    });
  },
});
export default contactPointSlice;

export const contactPointsSelector = (state: RootState) => state.contactPoint?.contactPoints;
export const contactPointsForSelectedSelector = (state: RootState) => state.contactPoint?.contactPointsForSelected;
