import { yupResolver } from "@hookform/resolvers/yup";
import { Close, ContentPaste } from "@mui/icons-material";
import { DialogActions, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BaseButton from "components/Buttons/BaseButton";
import InputTextForm from "components/Forms/InputTextForm";
import SelectForm from "components/Forms/SelectForm";
import { LooseObject } from "models/common";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux/store";
import * as Yup from "yup";
import { useStyles } from "./styles";
import { useEffect, useMemo } from "react";
import { getAllCandidates, updateCandidateApplicationStatus } from "store/registerFormSlice";
import { toast } from "react-toastify";

interface IProps {
  isOpen: boolean;
  onClose: Function;
  dataDetail: LooseObject;
}

export default function ModalChangeApplicationStatus({ isOpen, onClose, dataDetail }: IProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const validation = Yup.object().shape({
    application_status: Yup.number().required().required(t("this_field_is_required")),
    application_note: Yup.string().notRequired(),
  });

  const candidateApplicationStatus = useMemo(() => {
    return [
      { name: t("pending_inspection"), value: 0 },
      { name: t("insufficient_documents"), value: 1 },
      { name: t("sufficient_documents"), value: 2 },
    ];
  }, [t]);

  type ConfirmIsPaidForm = Yup.InferType<typeof validation>;

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<ConfirmIsPaidForm>({
    mode: "onChange",
    resolver: yupResolver(validation),
  });

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setValue("application_status", dataDetail.application_status?.id ?? "");
    setValue("application_note", dataDetail.application_note ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetail]);

  const onSubmit = async (data: ConfirmIsPaidForm) => {
    const dataUpdate = {
      id: dataDetail.id,
      data: data,
    };

    const response = await dispatch(updateCandidateApplicationStatus(dataUpdate));
    await dispatch(getAllCandidates());
    if (response.meta.requestStatus === "fulfilled") {
      toast.success<void>(t("change_result_successful"));
      handleClose();
    } else {
      console.error(response.payload.message);
      toast.error<void>(t("failed"));
    }
  };

  const getErrorsValidate = (name: any) => {
    return errors[name as keyof typeof errors]?.message;
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"sm"} onClose={() => onClose()} open={isOpen}>
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
            <ContentPaste />
            <p>{t("change_application_status")}</p>
          </div>
          <IconButton aria-label="close" onClick={handleClose} className={classes.btnClose}>
            <Close />
          </IconButton>
        </div>

        <DialogContent dividers className={classes.containerContent}>
          <SelectForm
            widthLabel={120}
            data={candidateApplicationStatus}
            isRequired
            title={t("application_status")}
            control={control}
            name="application_status"
            fullWidth
            errors={getErrorsValidate("application_status")}
          />
          <InputTextForm
            widthLabel={120}
            title={t("note")}
            name={"application_note"}
            register={register}
            errors={getErrorsValidate("application_note")}
            type={"textarea"}
            fullWidth
          />
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={t("cancel")} className={classes.btnCancel} onClick={handleClose} />
          <BaseButton
            title={t("save")}
            className={classes.btnSearch}
            isSubmit={false}
            onClick={handleSubmit(onSubmit)}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
