import { CallEndRounded, CallRounded, Close, RemoveRounded } from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Dialog, DialogActions, DialogContent, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAppDispatch } from "redux/store";
import {
  contactIdCallLogSelector,
  getCallLogInfo,
  isOpenCallLogModalSelector,
  setIsEndCallLog,
  typeCallLogSelector,
  updateCallLog,
  userCallLogSelector,
} from "store/callLogSlice";
import { CALL_LOG_TYPE } from "utils";
import ModalConfirm from "../ModalConfirm";
import Timer from "./Timer";
import { useStyles } from "./styles";
import OmiConfig from "lib/omi-config";

interface IProps {}

const ModalCallLog: React.FC<IProps> = (props) => {
  const isOpenCallLogModal = useSelector(isOpenCallLogModalSelector);
  const dataCallLog = useSelector(userCallLogSelector);
  const contactId = useSelector(contactIdCallLogSelector);
  const typeCallLog = useSelector(typeCallLogSelector);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isShowModalConfirmCall, setIsShowModalConfirmCall] = useState(false);
  const [isShowModalConfirmEndCall, setIsShowModalConfirmEndCall] = useState(false);
  const [isHiddenModal, setIsHiddenModal] = useState(false);
  const [clockCount, setCLockCount] = React.useState(0);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const omiSDK = (window as any).omiSDK;
  const [messageCallLog, setMessageCallLog] = useState<any>(t("call_setup_in_process"));
  const [isCallLogStateSuccessful, setIsCallLogStateSuccessful] = useState<boolean>(true);

  useEffect(() => {
    if (isOpenCallLogModal) {
      setIsShowModalConfirmCall(true);
    }
  }, [isOpenCallLogModal]);

  const handleClickOutsideDialog = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    if (reason && reason === "backdropClick") {
      setIsHiddenModal(true);
      return;
    }
  };

  const getProfileRoute = () => {
    switch (typeCallLog) {
      case CALL_LOG_TYPE[1].value:
        return `masterContact/${contactId}`;
      case CALL_LOG_TYPE[0].value:
        return `studentContact/${contactId}`;
      default:
        return "";
    }
  };

  const getTaskRoute = () => {
    switch (typeCallLog) {
      case CALL_LOG_TYPE[1].value:
        return `masterContact/${contactId}/tasks`;
      case CALL_LOG_TYPE[0].value:
        return `studentContact/${contactId}/tasks`;
      default:
        return "";
    }
  };

  const stopCallSDK = useCallback(async () => {
    await omiSDK?.stopCall();
    await omiSDK?.unregister();
  }, [omiSDK]);

  const handleEndCallLog = useCallback(async () => {
    setCLockCount(0);

    dispatch(setIsEndCallLog());
    setIsShowDialog(false);
    setIsCallLogStateSuccessful(true);
    await stopCallSDK();
  }, [dispatch, stopCallSDK]);

  useEffect(() => {
    let interval: any = null;
    if (messageCallLog === "isAccepted") {
      interval = setInterval(() => {
        setCLockCount((clockCount) => clockCount + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [clockCount, messageCallLog]);

  const getShortName = (name: string) => {
    if (!name) return "";
    const splitName = name.split(" ");
    const lastName = splitName[splitName.length - 1];

    return lastName[0];
  };

  const setErrorMessageCallLog = (message: string) => {
    setIsCallLogStateSuccessful(false);
    setMessageCallLog(message);
    stopCallSDK();
  };

  const handleConfirmCall = useCallback(async () => {
    setIsShowDialog(true);
    setIsShowModalConfirmCall(false);
    const callInfo = await dispatch(getCallLogInfo());
    if (callInfo?.meta?.requestStatus === "fulfilled" && dataCallLog?.phone_number !== "") {
      await omiSDK?.init(OmiConfig, async () => {});
      await omiSDK?.register({
        domain: callInfo?.payload?.domain,
        username: callInfo?.payload?.sip_number,
        password: callInfo?.payload?.password,
      });

      await omiSDK?.setBusy(true);

      await omiSDK?.on("register", function (data: any) {
        if (data?.status) {
          setMessageCallLog(t("connected_to_switchboard"));
          omiSDK?.makeCall(dataCallLog?.phone_number);
        } else {
          setErrorMessageCallLog(t("unable_to_connect_to_switchboard"));
        }
      });

      await omiSDK?.on("ringing", function (data: any) {
        if (data?.status) {
          setMessageCallLog(t("ringing"));
        } else {
          setErrorMessageCallLog(t("cannot_make_calls"));
        }
      });

      await omiSDK?.on("accepted", function (data: any) {
        if (data?.status) {
          setMessageCallLog("isAccepted");
        } else {
          setErrorMessageCallLog(t("cannot_make_calls"));
        }
      });

      await omiSDK?.on("ended", function (data: any) {
        if (data?.status) {
          if (typeCallLog !== CALL_LOG_TYPE[3].value) {
            dispatch(updateCallLog(contactId, data?.uuid));
          }
          setMessageCallLog(t("call_ended"));
          stopCallSDK();
        }
      });
    } else {
      setErrorMessageCallLog(t("cannot_make_calls"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCallLog?.phone_number, omiSDK]);

  const handleEndCallWithCondition = useCallback(() => {
    if (isCallLogStateSuccessful && messageCallLog !== t("call_ended")) {
      setIsShowModalConfirmEndCall(true);
    } else {
      handleEndCallLog();
    }
  }, [handleEndCallLog, isCallLogStateSuccessful, messageCallLog, t]);

  return (
    <>
      {isOpenCallLogModal && (
        <div>
          <ModalConfirm
            isOpen={isShowModalConfirmCall}
            onClose={() => {
              handleEndCallLog();
              setIsShowDialog(false);
            }}
            onConfirm={() => handleConfirmCall()}
            title={t("notification")}
            content={t("confirm_call_log", { phone_number: dataCallLog?.phone_number })}
          />
          <ModalConfirm
            isOpen={isShowModalConfirmEndCall}
            onClose={() => setIsShowModalConfirmEndCall(false)}
            onConfirm={() => {
              handleEndCallLog();
              setIsShowModalConfirmEndCall(false);
            }}
            title={t("notification")}
            content={t("confirm_end_call_log")}
          />
          {isShowDialog && !isHiddenModal && (
            <Dialog
              classes={{ paper: classes.paper }}
              className={classes.rootDialog}
              open={isShowDialog}
              onClose={handleClickOutsideDialog}
              PaperProps={{ sx: { overflow: "hidden", height: "auto", borderRadius: "12px" } }}
              BackdropProps={{ invisible: true }}
            >
              <div className={classes.headerModal}>
                <IconButton aria-label="close" onClick={() => setIsHiddenModal(true)} className={classes.btnClose}>
                  <RemoveRounded />
                </IconButton>
                <IconButton
                  aria-label="close"
                  onClick={() => handleEndCallWithCondition()}
                  className={classes.btnClose}
                >
                  <Close />
                </IconButton>
              </div>

              <form>
                <DialogContent className={classes.containerContent}>
                  <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Avatar sx={{ width: "100px", height: "100px", marginBottom: "18px" }}>
                      <Typography variant="h2">{getShortName(dataCallLog?.full_name)}</Typography>
                    </Avatar>
                    <Typography
                      textAlign={"center"}
                      variant="h4"
                      fontWeight={"medium"}
                      className={classes.textEllipsis}
                    >
                      {dataCallLog?.full_name}
                    </Typography>

                    <Box
                      sx={{
                        marginTop: "20px",
                        color: isCallLogStateSuccessful && messageCallLog !== t("call_ended") ? "#47D730" : "#F2756F",
                      }}
                    >
                      {messageCallLog !== "isAccepted" ? (
                        <Typography>{messageCallLog}</Typography>
                      ) : (
                        <Timer time={clockCount} />
                      )}
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions className={classes.footer}>
                  <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "5px" }}>
                    {getTaskRoute() && (
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        className={classes.btnLogInfo}
                        onClick={() => navigate(getTaskRoute())}
                        title={t("tasks")}
                      >
                        <FormatListBulletedIcon />
                      </IconButton>
                    )}
                    <IconButton
                      aria-label="delete"
                      size="large"
                      className={classes.btnEndCall}
                      onClick={() => handleEndCallWithCondition()}
                      title={t("end_call")}
                    >
                      <CallEndRounded />
                    </IconButton>
                    {getProfileRoute() && (
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        className={classes.btnLogInfo}
                        onClick={() => navigate(getProfileRoute())}
                        title={t("profile")}
                      >
                        <PersonIcon />
                      </IconButton>
                    )}
                  </Box>
                </DialogActions>
              </form>
            </Dialog>
          )}
          {isHiddenModal && (
            <Box sx={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 99999 }}>
              <IconButton
                sx={{ height: "60px", width: "60px" }}
                aria-label="close"
                onClick={() => setIsHiddenModal(false)}
                className={classes.btnHidden}
              >
                <CallRounded fontSize="large" />
              </IconButton>
            </Box>
          )}
        </div>
      )}
    </>
  );
};

export default ModalCallLog;
