import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LooseObject } from "../models/common";
import { AppDispatch } from "../redux/store";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";

export interface applicationState {
  applications: LooseObject[];
  page: number;
  total: number;
  limit: number;
  questions: LooseObject[];
}

const initialState: applicationState = {
  applications: [],
  page: 1,
  total: 0,
  limit: 15,
  questions: [],
};

const applicationSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetDataApplication: (state) => {
      state.applications = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getApplications.fulfilled, (state, action) => {
        state.applications = action.payload.data;
        state.limit = action.payload.per_page;
        state.total = action.payload.total;  
        state.page = action.payload.current_page;
      })
      .addCase(getApplicationsQuestion.fulfilled, (state, action) => {
        state.questions = action.payload.data;
      });
  },
});

export const { setLimit, setPage, resetDataApplication } = applicationSlice.actions;

export const setPageSize = (data: number) => async (dispatch: AppDispatch, getState: any) => {
  try {
    dispatch(setLimit(Number(data)));
    return true;
  } catch (error) {
    return false;
  }
};

export const getApplications = createAsyncThunk(
  "application/getData",
  async (data: { id: any; page: number }, { getState, rejectWithValue, fulfillWithValue }) => {
    const { id, page } = data;
    const { applications } = getState() as { applications: applicationState };
    const { limit } = applications;
    try {
      const params: LooseObject = {
        contact_id: id,
        page: page,
        limit: limit,
      };
      const response = await axiosClient.get(API.applications, { params: { ...params } });
      return fulfillWithValue(response.data.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getApplicationsQuestion = createAsyncThunk(
  "application/getQuestion",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.get(API.applicationQuestion);
      return fulfillWithValue(response.data.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addApplication = (data: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await axiosClient.post(API.applications, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const editApplication = (id: any, data: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = `${API.applications}/${id}`
    const response = await axiosClient.put(url, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const deleteApplication = (id: any) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = `${API.applications}/${id}`
    const response = await axiosClient.delete(url);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};


export const getCandidateApplicationPdf =
    (url: any) => async (dispatch: AppDispatch, getState: any) => {
      try {
        const urlObj = new URL(url);
        urlObj.searchParams.append('download_pdf', '1');
        urlObj.searchParams.append('form_pdf', '1');
        const response = await axiosClient.get(urlObj.toString(), {
          responseType: "blob",
        });
        if (response) {
          return response.data;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    };

export default applicationSlice;
