import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "components/Loading/Loading";
import ModalConfirmLeaveForm from "components/ModalConfirmLeaveForm";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/store";
import actionContactSlice, { addContactToNewList, getErrorActionContactSelector } from "store/actionContactSlice";
import { fetchContactCategoriesByContactId } from "store/contactCategorySlice";
import unitTagSlice, { createMultipleUnitTag, getUnitTagList, unitTagListSelector } from "store/unitTagSlice";
import * as Yup from "yup";
import AutoCompleteForm from "../AutoCompleteForm";
import ButtonCancelForm from "../ButtonCancelForm";
import ButtonSaveForm from "../ButtonSaveForm";
import InputTextForm from "../InputTextForm";
import { useStyles } from "./styles";

interface IAddContactToNewListProp {
  onClose: Function;
  contactId: number;
  isOpen: boolean;
}

export default function AddContactToNewListForm({ onClose, contactId, isOpen }: IAddContactToNewListProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingSearchUnit, setIsLoadingSearchUnit] = useState<boolean>(false);
  const [isOpenModalConfirmLeaveForm, setIsOpenModalConfirmLeaveForm] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required(t("name_is_required")).max(255, t("name_must_not_exceed_255_characters")),
    unit_tags: Yup.array().typeError(t("unit_tags_is_not_valid")),
    source: Yup.string().max(255, t("source_must_not_exceed_255_characters")),
  });

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      dispatch<any>(actionContactSlice.actions.resetStateError());
      dispatch<any>(getUnitTagList());
      setIsLoading(false);
    }
  }, [dispatch, isOpen]);

  const unitTagList = useSelector(unitTagListSelector);
  const errorFromApis = useSelector(getErrorActionContactSelector);

  type FilterStudentContactSubmitForm = Yup.InferType<typeof validationSchema>;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FilterStudentContactSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: contactId,
      name: "",
      unit_tags: [],
      source: "",
    },
  });

  const handleBackClick = () => {
    isDirty ? setIsOpenModalConfirmLeaveForm(true) : onClose();
  };

  const handleOnChangeSearch = async (value: any) => {
    setIsLoadingSearchUnit(true);
    dispatch(unitTagSlice.actions.setName(value));
    await dispatch<any>(getUnitTagList());
    setIsLoadingSearchUnit(false);
  };
  const getErrorsValidate = (name: any) => {
    return Object.keys(errors).length !== 0 ? errors[name as keyof typeof errors]?.message : errorFromApis?.[name]?.[0];
  };

  const handleSubmitModal = async (data: FilterStudentContactSubmitForm) => {
    let dataCreateUnitTag = [];
    const dataUnitTag = data?.unit_tags?.map((item: any) => item.id).filter((value: any) => value !== undefined);
    const createUnitTagsData = data?.unit_tags
      ?.map((item: any) => item.inputValue)
      .filter((value: any) => value !== undefined);

    if (createUnitTagsData?.length ?? 0) {
      const responseCreateMultipleUnitTag = await dispatch<any>(createMultipleUnitTag(createUnitTagsData ?? []));
      if (responseCreateMultipleUnitTag.meta?.requestStatus === "fulfilled") {
        dataCreateUnitTag = responseCreateMultipleUnitTag.payload;
        toast.success<void>(t("create_unit_tag_successful"));
      } else {
        toast.error<void>(t("fail_to_create_unit_tag"));
      }
    }

    const dataSubmit = {
      id: contactId,
      name: data.name,
      unit_tags: [...dataCreateUnitTag, ...(dataUnitTag as any[])],
      source: data.source,
    };

    const response = await dispatch<any>(addContactToNewList(dataSubmit));
    if (response?.meta?.requestStatus === "fulfilled") {
      toast.success<void>(t("add_contact_to_list_successful"));
      onClose();
      await dispatch<any>(fetchContactCategoriesByContactId());
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <form onSubmit={handleSubmit(handleSubmitModal)} noValidate>
        <div className={classes.containerForm}>
          <InputTextForm
            title={t("list_name")}
            isRequired={true}
            name="name"
            register={register}
            errors={getErrorsValidate("name")}
          />
          <AutoCompleteForm
            isLoading={isLoadingSearchUnit}
            title={t("unit")}
            data={unitTagList}
            isMultiple={true}
            name="unit_tags"
            register={register}
            key={"unit_tags"}
            control={control}
            errors={getErrorsValidate("unit_tags")}
            onChangeSearch={handleOnChangeSearch}
            maxHeight={120}
            isAddOption={true}
            isSearchApi={true}
          />
          <InputTextForm
            title={t("source")}
            name="source"
            isRequired={false}
            register={register}
            errors={getErrorsValidate("source")}
          />
        </div>
        <div className={classes.containerBtn}>
          <ButtonCancelForm onClick={handleBackClick} />
          <ButtonSaveForm />
        </div>
      </form>
      <ModalConfirmLeaveForm
        isOpen={isOpenModalConfirmLeaveForm}
        onClose={() => {
          setIsOpenModalConfirmLeaveForm(false);
          onClose();
        }}
        onConfirm={() => {
          setIsOpenModalConfirmLeaveForm(false);
        }}
      />
    </>
  );
}
