import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  headerModal: {
    height: "40px",
    backgroundColor: "#4A4358",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: 700,
    paddingLeft: "16px",
    "& p": {
      marginLeft: "12px",
    },
  },
  containerContent: {
    padding: "20px 40px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 20px",
    }
  },
  formControl: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  btnCancel: {
    height: "31px",
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "51px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  btnSearch: {
    height: "31px",
    backgroundColor: colors.btnDark,
    "&:hover": {
      backgroundColor: colors.btnDark,
    },
  },
  containerForm: {
    paddingLeft: "12.5px",
  },
  headerTextForm: {
    color: colors.defaultColorText,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    ":hover": {
      color: colors.defaultColorText,
      cursor: "pointer",
    },
  },
  headerDiviverForm: {
    width: "1px",
    height: "38px",
    backgroundColor: "#DEDEDE",
    margin: "0 20px",
  },
  containerHeaderForm: {
    display: "flex",
    justifyContent: "center",
    height: "38px",
    alignItems: "center",
    marginBottom: "10px",
  },
  activeHeaderTextForm: {
    color: colors.danger,
  },
  leftCard: {
    flex: 1,
    paddingRight: "60px",
    "@media (max-width: 1024px)": {
      paddingRight: "20px",
    },
    "@media (max-width: 768px)": {
      paddingRight: "unset",
    },
  },
  centerCard: {
    flex: 1,
    paddingRight: "60px",
    "@media (max-width: 1024px)": {
      paddingRight: "20px",
    },
    "@media (max-width: 768px)": {
      paddingRight: "unset",
    },
  },
  rightCard: {
    flex: 1,
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    margin: 0,
  },
  labelLeft: {
    minWidth: "200px",
    "@media (max-width: 1024px)": {
      minWidth: "100px",
    },
    "@media (max-width: 768px)": {
      minWidth: "200px",
    },
  },
  labelCenter: {
    minWidth: "200px",
  },
  labelRight: {
    minWidth: "200px",
    "@media (max-width: 1024px)": {
      minWidth: "140px",
    },
    "@media (max-width: 768px)": {
      minWidth: "200px",
    },
  },
  input: {
    width: "100%",
    height: "30px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
  },
  inputSelect: {
    width: "100%",
    minWidth: "150px",
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "left",
    marginTop: "-5px",
    paddingLeft: "5px",
    "@media (max-width: 768px)": {
      paddingLeft: "200px",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    height: "30px",
    padding: "20px 0",
  },
  btnSaveQuery: {
    height: "44px",
    backgroundColor: colors.btnPrimary,
    marginLeft: "21px !important",
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
  iconAdd: {
    fontSize: "29px !important",
    marginRight: "-4px",
  },
  hidden: {
    display: "none",
  },
  btnClose: {
    backgroundColor: "#4A4358",
    color: "#FFF",
  },
  wrapTitle: {
    height: "40px",
    backgroundColor: "#4A4358",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    "& p": {
      marginLeft: "12px",
    },
  },
}));
