import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import SwitchButton from "components/Buttons/SwitchButton";
import Select from "components/Select";
import { LooseObject } from "models/common";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/store";
import * as Yup from "yup";
import { StringSchema } from "yup";
import IconPeople from "../../../assets/images/ion_people.svg";
import BaseButton from "../../Buttons/BaseButton";
import { useStyles } from "./styles";

interface ModalMassUpdateProp {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  searchOption: LooseObject;
  contacts: LooseObject[];
  contactData: LooseObject[];
  isBlackList: boolean;
  handleOpenModalAddTask: Function;
  setListContactUpdate: Function;
  searchContact: Function;
  listId?: number[];
}

export default function ModalMassUpdate({
  isOpen,
  onClose,
  onConfirm,
  searchOption,
  contacts,
  contactData,
  isBlackList,
  handleOpenModalAddTask,
  setListContactUpdate,
  searchContact,
  listId,
}: ModalMassUpdateProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const refBtnSubmit = useRef<HTMLButtonElement>(null);
  const [isAddToBlack, setIsAddToBlack] = useState<boolean>(isBlackList);
  const [isSearchContact, setIsSearchContact] = useState<boolean>(false);
  const [contactOption, setContactOption] = useState<LooseObject[]>([]);

  const validationSchema = Yup.object().shape({
    pic_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    contact_state_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    country_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    note: Yup.string().trim().max(500, t("this_field_must_not_exceed_255_characters")),
    id: Yup.array()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .required(t("this_field_is_required"))
      .min(1, t("this_field_is_required")),
    is_blacklist: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    reject_reason_id: Yup.string()
      .when("is_blacklist", {
        // eslint-disable-next-line eqeqeq
        is: (val: any) => val == 1 && !isBlackList,
        then: (schema: StringSchema) => schema.required(t("this_field_is_required")),
        otherwise: (schema: StringSchema) => schema,
      })
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      }),
  });

  type ModalForm = Yup.InferType<typeof validationSchema>;
  type KeyError = keyof ModalForm;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ModalForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (listId && listId.length > 0) {
      setValue("id", listId);
    } else if (contactData) {
      setValue("id", contactData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactData, listId]);

  const handleSubmitModal = (form: ModalForm) => {
    const dataUpdate: LooseObject = {
      id: listId && listId.length > 0 ? listId : form?.id.map((item: LooseObject) => item?.id),
      pic_id: form?.pic_id,
      contact_state_id: form?.contact_state_id,
      country_id: form?.country_id,
      note: form?.note && String(form?.note).trim(),
      is_blacklist: form?.is_blacklist,
      reject_reason_id: form?.reject_reason_id,
    };
    Object.keys(dataUpdate).forEach((key: string) => {
      if (!dataUpdate[key] && key !== "is_blacklist") {
        delete dataUpdate[key];
      }
    });
    if (
      (isBlackList && dataUpdate["is_blacklist"] === 1) ||
      (!isBlackList && dataUpdate["is_blacklist"] === 0) ||
      dataUpdate["is_blacklist"] === undefined
    ) {
      delete dataUpdate["is_blacklist"];
    }
    if (Object.keys(dataUpdate).length <= 1) {
      toast.warning<void>(t("no_change_has_found"));
      return;
    }
    onConfirm(dataUpdate);
  };

  const handleSwitch = () => {
    setValue("is_blacklist", isAddToBlack ? 0 : 1);
    if (isAddToBlack) {
      setValue("reject_reason_id", undefined);
    }
    setIsAddToBlack(!isAddToBlack);
  };

  const handleChangeSelect = (name: string, data: any, reason: string) => {
    if (reason === "clear") {
      if (Array.isArray(data)) {
        setValue(name as KeyError, []);
      } else {
        setValue(name as KeyError, "");
      }
    }
    if (name === "id" && reason === "selectOption") {
      setListContactUpdate(data);
    } else if (name === "id" && reason === "removeOption ") {
      const list = contactData.filter((item: LooseObject) => item?.id !== data?.option?.id);
      setListContactUpdate(list);
    }
  };

  const handleSearchContact = async (text: string) => {
    setIsSearchContact(true);
    const newContacts = await dispatch(searchContact(text));
    if (newContacts) {
      let listContact: LooseObject[] = [...contactData];
      newContacts.forEach((item: LooseObject) => {
        const index = listContact.findIndex((element: LooseObject) => item?.id === element?.id);
        if (index === -1) {
          listContact.push({ id: item?.id, label: item?.full_name });
        }
      });
      setContactOption(listContact);
    }
    setIsSearchContact(false);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        scroll={"paper"}
      >
        <div className={classes.title}>
          <div className={classes.wrapIconTitle}>
            <img alt="icon" src={IconPeople} />
            <p>{t("mass_update")}</p>
          </div>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers className={classes.containerContent}>
          <form onSubmit={handleSubmit(handleSubmitModal)} noValidate>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>{`${t("pic")}: `}</p>
              <Select
                options={searchOption?.pic || []}
                className={classes.inputSelect}
                nameRegister={"pic_id"}
                control={control}
                isForm
              />
            </div>
            <div className={classes.error}>{errors["pic_id"]?.message}</div>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>
                {`${t("contact")}: `}
                <span>*</span>
              </p>
              <Select
                options={contactOption}
                className={classes.inputSelect}
                nameRegister={"id"}
                control={control}
                isForm
                isMultiple
                isObjectSubmit
                onchange={handleChangeSelect}
                isSearching={isSearchContact}
                onChangeSearch={handleSearchContact}
                isDisabled={listId && listId.length > 0}
                isHideTag={listId && listId.length > 0}
              />
            </div>
            <div className={classes.error}>{errors["id"]?.message}</div>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>{`${t("state")}: `}</p>
              <Select
                options={searchOption?.state || []}
                className={classes.inputSelect}
                nameRegister={"contact_state_id"}
                control={control}
                isForm
              />
            </div>
            <div className={classes.error}>{errors["contact_state_id"]?.message}</div>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>{`${t("country")}: `}</p>
              <Select
                options={searchOption?.country || []}
                className={classes.inputSelect}
                nameRegister={"country_id"}
                control={control}
                isForm
              />
            </div>
            <div className={classes.error}>{errors["country_id"]?.message}</div>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>{`${t("note")}: `}</p>
              <textarea rows={7} className={classes.inputArea} {...register("note")}></textarea>
            </div>
            <div className={classes.error}>{errors["note"]?.message}</div>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>{`${t("add_to_black_list")}: `}</p>
              <SwitchButton
                checked={isAddToBlack}
                onClick={() => handleSwitch()}
                className={classes.btnSwitch}
                valueItem={1}
              />
            </div>
            {!isBlackList && isAddToBlack && (
              <>
                <div className={classes.formControl}>
                  <p className={`${classes.label}`}>
                    {`${t("reject_reason")}: `}
                    <span>*</span>
                  </p>
                  <Select
                    options={searchOption?.rejectReason || []}
                    className={classes.inputSelect}
                    nameRegister={"reject_reason_id"}
                    control={control}
                    isForm
                  />
                </div>
                <div className={classes.error}>{errors["reject_reason_id"]?.message}</div>
              </>
            )}
            <button type="submit" className={classes.hidden} ref={refBtnSubmit}></button>
          </form>
          <div className={classes.wrapBtnAddTask}>
            <BaseButton
              title={t("add_task")}
              startIcon={<span className={classes.checkList}></span>}
              className={classes.btnAddTask}
              onClick={() => handleOpenModalAddTask()}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={t("cancel")} className={classes.btnNo} onClick={() => onClose()} />
          <BaseButton
            title={t("save")}
            className={classes.btnYes}
            onClick={() => refBtnSubmit.current && refBtnSubmit.current.click()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
