import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  contentPage: {
    backgroundColor: colors.backgroundLight,
    boxSizing: "border-box",
    padding: "30px 20px",
  },
  wrapSelected: {
    display: "flex",
    flexGrow: 1,
  },
  selected: {
    flexGrow: 1,
    maxWidth: "300px",
  },
}));
