import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { LooseObject } from "../models/common";
import { AppDispatch, RootState } from "../redux/store";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { getColumns, saveColumns } from "./columnSlice";
import { deleteQuery, getListQueries, getQuery } from "./querySlice";
import { getListMasterForOption } from "./listMasterContactSlice";
import {initialSearchFilterMaster, MasterContactState} from "./masterContactSlice";

export interface StudentAdmissionPeriodPageDataInterface {
    admissionPeriods: number[];
    aspirations: LooseObject[];
}

const initialState: StudentAdmissionPeriodPageDataInterface = {
    admissionPeriods: [],
    aspirations: [],
};

export const getStudentAdmissionPeriodPageData = createAsyncThunk(
    'studentAdmissionPeriods/getAdmissionPeriodPageData',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const resAspiration = await axiosClient.get(API.getAspirations, {
                params: {
                    types: [1]
                }
            });

            const data: LooseObject = {
                aspiration: Array.isArray(resAspiration.data.data) ? resAspiration.data.data : [],
            };
            return fulfillWithValue(data);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStudentAdmissionRounds = createAsyncThunk(
    'studentAdmissionPeriods/getAdmissionRounds',
    async (_, {rejectWithValue, fulfillWithValue}) => {
        try {
            const resAdmissionRounds = await axiosClient.get(API.getAdmissionRound(), {
                params: {
                    'get_for_setting': 1
                }
            });
            const data: LooseObject = {
                admissionRounds: resAdmissionRounds.data.data,
            };
            return fulfillWithValue(data);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
)

const studentAdmissionPeriodSlice = createSlice({
    name: "studentAdmissionPeriods",
    initialState: initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(getStudentAdmissionPeriodPageData.fulfilled, (state, action) => {
                state.aspirations = {...state.aspirations, ...action.payload.aspiration};
            })
            .addCase(getStudentAdmissionRounds.fulfilled, (state, action) => {
                state.admissionPeriods = {...state.admissionPeriods, ...action.payload.admissionRounds};
            });
    }
});

export const updatePeriods = createAsyncThunk(
    "studentAdmissionPeriods/updateAdmissionPeriods",
    async (form: { data: any }, { rejectWithValue, fulfillWithValue }) => {
        const { data } = form;
        try {
            const response = await axios.post(`${API.updateAdmissionPeriods}`, data, {
                headers: { "Content-Type": "application/json", "X-localization": "vi" },
            });
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const {
} = studentAdmissionPeriodSlice.actions;
export default studentAdmissionPeriodSlice;
