import { LooseObject } from "models/common";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import moment from "moment";
export interface MasterListState {
  lists: LooseObject[];
  units: LooseObject[];
  page: number;
  total: number;
  limit: number;
  searchOption: LooseObject;
  error?: LooseObject[];
}

export const initialSearchOptionListMaster = {
  unit_tags: [],
  created_date: {},
  last_updated: {},
  text: "",
};
const initialState: MasterListState = {
  lists: [],
  units: [],
  page: 1,
  total: 0,
  limit: 50,
  searchOption: initialSearchOptionListMaster,
  error: [],
};

const masterListsSlice = createSlice({
  name: "masterLists",
  initialState,
  reducers: {
    getUnitSuccess: (state, action: PayloadAction<LooseObject[]>) => {
      state.units = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    updateSearch: (state, action: PayloadAction<LooseObject>) => {
      state.searchOption = action.payload;
    },
    resetMasterListsError: (state) => {
      state.error = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchMasterLists.fulfilled, (state, action) => {
      state.lists = action.payload.data;
      state.page = action.payload.current_page;
      state.total = action.payload.total;
    });
  },
});

export const { getUnitSuccess, setLimit, updateSearch, resetMasterListsError } = masterListsSlice.actions;

export const setPageSize = (data: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLimit(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const getUnits = () => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await axiosClient.get(API.restfulUnitTag, { params: { limit: "*" } });
    if (response.status === 200) {
      dispatch(getUnitSuccess(response.data.data));
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const fetchMasterLists = createAsyncThunk(
  "masterList/fetch",
  async (page: number = 1, { getState, rejectWithValue, fulfillWithValue }) => {
    const { masterLists } = getState() as { masterLists: MasterListState };
    const { searchOption, limit } = masterLists;

    try {
      const params: LooseObject = {
        page: page,
        limit: limit,
        type: 2,
      };

      Object.entries(searchOption).map(([key, value]) => {
        if (value && key === "text") {
          params["name"] = value;
        } else if (key === "created_date") {
          params["created_date_start"] = value?.startDate && moment(value?.startDate).format("YYYY-MM-DD");
          params["created_date_end"] = value?.endDate && moment(value?.endDate).format("YYYY-MM-DD");
        } else if (key === "last_updated") {
          params["last_updated_start"] = value?.startDate && moment(value?.startDate).format("YYYY-MM-DD");
          params["last_updated_end"] = value?.endDate && moment(value?.endDate).format("YYYY-MM-DD");
        } else if (value && typeof value === "string") {
          params[key] = value;
        } else if (value && Array.isArray(value)) {
          params[key] = value.map((item: LooseObject) => item.id);
        } else if (value.name) {
          params[key] = value.id;
        }
        return null;
      });

      const response = await axiosClient.get(API.restfulContactCategory, {
        params: { ...params },
      });
      return fulfillWithValue(response.data.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateSearchOption =
  (option: LooseObject | string, field: string) => async (dispatch: AppDispatch, getState: any) => {
    try {
      let searchOption = getState().masterLists.searchOption;
      let searchData: LooseObject = JSON.parse(JSON.stringify(searchOption));

      if (field !== "text" && typeof option !== "string" && Array.isArray(searchData[field])) {
        searchData[field] = searchData[field].map((item: LooseObject) => item.id).includes(option.id)
          ? searchData[field].filter((item: LooseObject) => item.id !== option.id)
          : [...searchData[field], option];
      } else {
        searchData[field] = option;
      }

      dispatch(updateSearch(searchData));
      return true;
    } catch (error) {
      return false;
    }
  };

export const deleteFieldSearch = (field: string) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const searchOption = getState().masterLists.searchOption;
    let data: LooseObject = JSON.parse(JSON.stringify(searchOption));
    if (Array.isArray(data[field])) {
      data[field] = [];
    } else {
      data[field] = "";
    }

    dispatch(updateSearch(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const getListMasterForOption = (param: LooseObject) => async () => {
  try {
    const response = await axiosClient.get(API.restfulContactCategory, { params: {...param} });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default masterListsSlice;