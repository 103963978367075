import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  btn: {
    textTransform: "none",
    fontSize: "14px",
    borderRadius: "5px",
    height: "30px",
    boxShadow: "none",
    fontFamily: "Roboto",
    whiteSpace: "nowrap",
    color: "#fff",
    padding: "6px 16px",
    "&:hover": {
      opacity: 0.8,
      transition: "ease-in 0.2s",
    },
  },
  menuTop: {
    "& .MuiMenu-list": {
      paddingTop: 0,
    },
  },
  menuItem: {
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: "14px",
    minWidth: "100px",
    height: "25px",
    maxWidth: "250px",
    display: "flex",
    justifyContent: "space-between",
  },
  search: {
    minWidth: "200px",
    width: "-webkit-fill-available",
    "& .MuiOutlinedInput-root": {
      height: "30px",
      fontSize: "14px",
      borderRadius: "4px",
      paddingRight: "6px",
    },
  },
  menuSearch: {
    backgroundColor: "#FFF",
    position: "sticky",
    top: 0,
    zIndex: 99999,
    padding: "12px 10px",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  iconDelete: {
    color: "#D63916",
    fontSize: "20px",
  },
  nameQuery: {
    fontWeight: 700,
    overflowWrap: "break-word",
  },
  wrapItem: {
    flex: 1,
  },
}));
