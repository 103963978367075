import { makeStyles } from "tss-react/mui";
import colors from "../../utils/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "8px",
    justifyContent: "flex-end",
    "& .MuiPaginationItem-root": {
      color: colors.defaultColorText,
      fontFamily: "Roboto",
      backgroundColor: "#FFF",
      borderRadius: "6px",
      height: "26px",
      "&:hover": {
        backgroundColor: "#FFF",
        opacity: 0.8,
      },
    },
    "& li >.Mui-selected": {
      backgroundColor: "#4A4358",
      border: "1px solid #FFF",
      color: "#FFF",
      "&:hover": {
        backgroundColor: "#4A4358",
        opacity: 0.8,
      },
    },
    [theme.breakpoints.between("xs", "md")]: {
      padding: "5px 0",
      margin: "0 0 0 10px",
      justifyContent: "flex-start",
    },
  },
  pageLimit: {
    maxWidth: "10%",
    marginRight: "10px",
    height: "24px",
    padding: "0px 0px",
    minWidth: "50px",
    textAlign: "center",
    borderRadius: "4px",
    border: "1px solid #DEDEDE",
    background: "#F5F5F5",
    color: colors.defaultColorText,
    fontFamily: "Roboto",
  },
}));
