import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  containerContent: {
    margin: "16px",
    padding: "16px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
  },
  iconAdd: {
    fontSize: "29px !important",
    marginRight: "-4px",
  },
  headerCard: {
    borderRadius: "4px 4px 0 0 !important",
  },
  editBtn: {
    width: "24px",
    height: "24px",
    color: "#413C51",
    flexShrink: 0
  },
  deleteBtn: {
    width: "24px",
    height: "24px",
    color: "#FF0000",
    flexShrink: 0
  },
  addBtn: {
    borderRadius: "5px",
    background: "#413C51",
    margin: "10px 0",
    "&:hover": {
      background: "#413C51",
    }
  },
  itemCard: {
    margin: "0 0 10px 0",
    fontSize: "14px",
    fontWeight: 700,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  content: {
    backgroundColor: "#FAFAFA",
    marginBottom: "10px",
    display: "flex",
    alignItems: "flex-start",
    gap: "20px",
    alignSelf: "stretch",
    flexWrap: "wrap",
    padding: "0 16px",
  },
  status: {
    color: "#F2756F"
  },
  noData: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  nameDelete: {
    fontWeight: 700,
    overflowWrap: "break-word",
  },
}));
