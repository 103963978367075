import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  btn: {
    marginLeft: "10px",
    "@media(max-width: 1280px)": {
      marginBottom: "10px",
    },
  },
  isActiveBtn: {
    backgroundColor: "#413C51",
    "&:hover": {
      backgroundColor: "#413C51",
    },
  },
  btnLog: {
    backgroundColor: "#AA9DCD",
    "&:hover": {
      backgroundColor: "#AA9DCD",
    },
  },
  btnDelete: {
    backgroundColor: "#DA3434",
    "&:hover": {
      backgroundColor: "#DA3434",
    },
  },
  btnNormal: {
    backgroundColor: "#F08377",
    "&:hover": {
      backgroundColor: "#F08377",
    },
  },
  headerPage: {
    backgroundColor: "#FFF",
    height: "47px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media(max-width: 1280px)": {
      height: "auto !important",
      padding: "8px 16px !important",
    },
    "@media(max-width: 425px)": {
      flexDirection: "column",
    },
  },
  nameDelete: {
    fontWeight: 700,
    overflowWrap: "break-word",
  },
  logo: {
    marginLeft: "7px",
  },
  wrapTittle: {
    display: "flex",
    alignItems: "center",
    "& p": {
      whiteSpace: "nowrap",
      fontSize: "20px",
      fontWeight: 700,
      textTransform: "uppercase",
      maxWidth: "350px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "@media(max-width: 768px)": {
        textOverflow: "unset",
        whiteSpace: "pre-wrap",
        fontSize: "18px",
        margin: "10px 0",
      },
    },
  },
}));
