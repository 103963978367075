import { LooseObject } from "models/common";
import { useStyles } from "./styles";

interface TabBarProp {
  selected: number;
  data: LooseObject[];
  className?: string;
  handleClick: Function;
  isDisable?: Boolean;
}

export default function TabBar({ selected, data, className, handleClick, isDisable }: TabBarProp) {
  const { classes } = useStyles();

  const getTabClass = (index: number, length: number, selectedValue: number, itemValue: number) => {
    const isFirstTab = index === 0;
    const isLastTab = index === length - 1;

    if (isFirstTab) {
      return selectedValue === itemValue ? classes.tabStartSelected : classes.tabStart;
    } else if (isLastTab) {
      return selectedValue === itemValue ? classes.tabEndSelected : "";
    } else {
      return selectedValue === itemValue ? classes.tabCenterSelected : classes.tabCenter;
    }
  };

  return (
    <div className={classes.container}>
      {Array.isArray(data) &&
        data.map((item: LooseObject, index: number) => (
          <div
            key={item?.name}
            className={`${classes.tab} ${getTabClass(index, data.length, selected, item?.id)} ${
              isDisable ? classes.isDisable : ""
            }`}
            onClick={() => {
              if (selected === item?.id) {
                return;
              }
              handleClick(item);
            }}
          >
            {item?.name}
          </div>
        ))}
    </div>
  );
}
