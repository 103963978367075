import { IconButton } from "@mui/material";
import { useStyles } from "./styles";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import CancelIcon from "@mui/icons-material/Cancel";

interface CardInforProp {
  title: string;
  childrens?: JSX.Element;
  className?: string;
  onClickEdit: Function;
  onClickDelete: Function;
  isShowDeleteBtn?: boolean;
  isShowEditBtn?: boolean;
}

export default function CardInfor({
  title,
  childrens,
  className,
  onClickEdit,
  onClickDelete,
  isShowDeleteBtn = true,
  isShowEditBtn = true,
}: CardInforProp) {
  const { classes } = useStyles();
  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.wrapHeader}>
        <p className={classes.title}>{title}</p>
        <div className={classes.wrapBtn}>
          {isShowEditBtn && (
            <IconButton aria-label="edit" className={classes.iconEdit} onClick={() => onClickEdit()}>
              <CreateRoundedIcon />
            </IconButton>
          )}
          {isShowDeleteBtn && (
            <IconButton aria-label="delete" className={classes.iconDelete} onClick={() => onClickDelete()}>
              <CancelIcon />
            </IconButton>
          )}
        </div>
      </div>
      {childrens}
    </div>
  );
}
