import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    borderRadius: "12px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#8193a4b3",
    padding: "0 0 0 10px",
    width: "fit-content",
    maxWidth: "90%",
    margin: "5px",
    "& p": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontSize: "12px",
      fontWeight: 400,
      color: "#FFFFFF",
    },
  },
  containerbtn: {
    padding: "4px",
    marginLeft: "5px"
  },
  btnClear: {
    fontSize: "15px",
    color: "#FFF"
  },
}));
