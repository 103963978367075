import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LooseObject } from "models/common";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { City, Country, District, Ward } from "types";
import { RootState } from "redux/store";

interface SendSmsAndEmailState {
  countries: Country[];
  cities: City[];
  districts: District[];
  wards: Ward[];
}

const initialState: SendSmsAndEmailState = {
  countries: [],
  cities: [],
  districts: [],
  wards: [],
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCitesWithoutAuth.fulfilled, (state, action) => {
      state.cities = action.payload;
    });
    builder.addCase(getCountriesWithoutAuth.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
  },
});

export const getCountriesWithoutAuth = createAsyncThunk(
  "location/getCountriesWithoutAuth",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url: string = API.getCountry;
      const response = await axiosClient.get(url);

      let country = [...response.data.data];
      const index = country.findIndex((item: LooseObject) => item.name === "Vietnam");
      if (index) {
        const vietnam = country[index];
        country.splice(index, 1);
        country.unshift(vietnam);
      }
      return fulfillWithValue(response.data.data);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);

export const getCountries = () => async () => {
  try {
    const url: string = API.getCountry;
    const response = await axiosClient.get(url);
    if (response.status === 200) {
      let country = [...response.data.data];
      const index = country.findIndex((item: LooseObject) => item.name === "Vietnam");
      if (index) {
        const vietnam = country[index];
        country.splice(index, 1);
        country.unshift(vietnam);
      }
      return country;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getCitesWithoutAuth = createAsyncThunk(
  "location/getCitesWithoutAuth",
  async (params: any, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const url: string = API.getCity;
      const response = await axiosClient.get(url, { params: { countries: [...params] } });
      return fulfillWithValue(response.data.data);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);

export const getCities = (countries: number[]) => async () => {
  try {
    const url: string = API.getCity;
    const response = await axiosClient.get(url, { params: { countries: [...countries] } });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getDistrict = (cities: number[]) => async () => {
  try {
    const url: string = API.getDistricts;
    const response = await axiosClient.get(url, { params: { cities: [...cities] } });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getWard = (district_ids: number[]) => async () => {
  try {
    const url: string = API.getWard;
    const response = await axiosClient.get(url, { params: { district_ids: [...district_ids] } });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default locationSlice;

export const citiesSelector = (state: RootState) => state.location.cities;
export const countriesSelector = (state: RootState) => state.location.countries;
