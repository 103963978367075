import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
    btnAddMore: {
        height: "40px",
        backgroundColor: "#6A7A83",
        borderRadius: "10px",
        color: "#FFFFFF",
        border: "none",
        "&:hover": {
            backgroundColor: "#767a7c",
        },
        cursor: "pointer",
    },
    btnClear: {
        height: "40px",
        backgroundColor: "rgba(159,8,8,0.68)",
        borderRadius: "10px",
        color: "#FFFFFF",
        border: "none",
        "&:hover": {
            backgroundColor: "#f68a8a",
        },
        cursor: "pointer",
    },
    iconAdd: {
        fontSize: "29px !important",
        marginRight: "-4px",
    },
    iconClear: {
        fontSize: "29px !important",
        marginRight: "-4px",
    },
    containerContent: {
        display: "flex",
        flexWrap: "wrap",
        gap: '15px',
        justifyContent: "space-evenly"
    },
    formControl: {
        display: "flex",
        width: "100%",
        [theme.breakpoints.up("lg")]: {
            alignItems: "center",
        },
        marginBottom: "15px",
        [theme.breakpoints.between("xs", "lg")]: {
            flexDirection: "column",
        },
        "& p": {
            [theme.breakpoints.between("xs", "md")]: {
                marginBottom: 5,
            },
        },
    },
    error: {
        color: "#F2756F",
        fontSize: "14px",
        fontWeight: 400,
        minHeight: "19px",
        [theme.breakpoints.down("sm")]: {
            minHeight: "10px",
        },
    },
    label: {
        fontSize: "14px",
        fontWeight: 400,
        whiteSpace: "nowrap",
        margin: 0,
        minWidth: "200px",
    },
    contentPage: {
        backgroundColor: "#FFF",
        margin: "16px",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: "16px"
    },
    headerCard: {
        backgroundColor: "#AB2523cc !important",
        borderRadius: "4px 4px 0 0 !important",
    },
    subHeaderCard: {
        backgroundColor: "#000000cc !important",
        borderRadius: "4px 4px 0 0 !important",
    },
    containerCardContent: {
        backgroundColor: colors.backgroundDark,
        display: "flex",
        flexDirection: "column",
    },
    cardContent: {
        columnCount: 2,
        columnGap: "32px",
        backgroundColor: colors.backgroundDark,
        padding: "16px",
        overflowY: "auto",
        [theme.breakpoints.down("xl")]: {
            columnCount: 2,
        },
        [theme.breakpoints.down("md")]: {
            columnCount: 1,
        },
    },
    table: {
        maxWidth: "800px",
        "& td,tr": {
            lineHeight: 1,
            height: "35px",
        },
    },
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "16px",
    },
    btnSubmit: {
        height: "44px",
        backgroundColor: colors.btnPrimary,
        marginLeft: "21px !important",
        "&:hover": {
            backgroundColor: colors.btnPrimary,
        },
        cursor: "pointer",
        color: "#FFFFFF",
        borderRadius: "10px",
    },
}));
