import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  wrapHeader: {
    display: "flex",
    padding: "10px 30px",
    alignItems: "center",
    "@media(max-width: 768px)": {
      flexDirection: "column",
    },
  },
  titleHeader: {
    fontSize: "18px",
    fontWeight: 700,
    marginRight: "30px",
    whiteSpace: "nowrap",
  },
  wrapSteper: {
    width: "720px",
    "& .MuiStepLabel-label": {
      fontSize: "14px",
      fontFamily: "Roboto",
      color: colors.defaultColorText,
    },
    "& .MuiStepIcon-root": {
      color: "#BBBBBB",
      border: "1px solid #BBBBBB",
      borderRadius: "50%",
      padding: "2px",
      width: "30px",
      height: "30px",
    },
    "& .MuiStepConnector-root": {
      top: "20px",
      left: "calc(-50% + 17px)",
      right: "calc(50% + 17px)",
    },
    "& .MuiStepConnector-line": {
      borderTopWidth: "3px",
    },
    "& .MuiStepLabel-iconContainer .Mui-active": {
      color: "#F7C744",
      border: "1px solid #F7C744",
    },
    "& .Mui-active .MuiStepConnector-line": {
      height: "3px",
      border: 0,
      backgroundImage: "linear-gradient(90deg, #4CB050 0%, #aebd49 50%, #F7C744 100%)",
    },
    "& .MuiStepLabel-iconContainer .Mui-completed": {
      color: "#4CB050",
      border: "1px solid #4CB050",
    },
    "& .Mui-completed .MuiStepConnector-line": {
      height: "3px",
      border: 0,
      backgroundColor: "#4CB050",
    },
    "@media(max-width: 768px)": {
      width: "auto",
    },
  },
  containerStep: {
    backgroundColor: colors.backgroundLight,
    padding: "16px 30px 40px 30px",
  },
  btnBack: {
    border: "1px solid rgba(26, 24, 79, 0.10)",
    color: colors.defaultColorText,
    backgroundColor: "#FFFFFF",
    marginRight: "50px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  btnImport: {
    backgroundColor: "#6A7A83",
    "&:hover": {
      backgroundColor: "#6A7A83",
    },
  },
  btnUploadError: {
    backgroundColor: "#4A4358",
    marginRight: "5px",
    "&:hover": {
      backgroundColor: "#4A4358",
    },
  },
  btnDownloadError: {
    backgroundColor: "#F2756F",
    "&:hover": {
      backgroundColor: "#F2756F",
    },
  },
  inputFile: {
    display: "none",
  },
  explainStep: {
    fontWeight: 500,
    fontSize: "14px",
    marginTop: 0,
    marginBottom: "16px",
    color: "#F2756F",
  },
  explainStep4: {
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "16px",
    marginBottom: "16px",
  },
  labelStep: {
    fontSize: "18px",
    fontWeight: 500,
    marginTop: 0,
    marginBottom: "16px",
  },
  hr: {
    margin: "0 0 16px 0",
    borderTop: "1px solid #D3D3D3",
  },
  nameFile: {
    fontSize: "18px",
    fontStyle: "italic",
    color: "#F2756F",
    fontWeight: 400,
  },
  errResult: {
    fontSize: "14px",
    color: "#F2756F",
    fontWeight: 500,
  },
  resultImport: {
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "16px",
  },
  iconDelete: {
    color: "red",
  },
  wrapbtnInput: {
    display: "flex",
    alignItems: "center",
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "5px",
  },
  wrapBtnFooter: {
    marginTop: "30px",
  },
}));
