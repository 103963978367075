import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import { ImageComment, LooseObject } from "../models/common";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";

export interface taskState {
  tasks: LooseObject[];
  taskType: LooseObject[];
  taskResult: LooseObject[];
  users: LooseObject[];
  contacts: LooseObject[];
}

const initialState: taskState = {
  tasks: [],
  taskType: [],
  taskResult: [],
  users: [],
  contacts: [],
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setContactsForTask: (state, action) => {
      state.contacts = action.payload;
    },
    resetTasks: (state) => {
      state.tasks = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTaskTypes.fulfilled, (state, action) => {
        state.taskType = action.payload.data;
      })
      .addCase(getTaskResults.fulfilled, (state, action) => {
        state.taskResult = action.payload.data;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.tasks = action.payload.data;
      });
  },
});

export const { setContactsForTask, resetTasks } = taskSlice.actions;

export const fetchTasks = createAsyncThunk(
  "tasks/getTasks",
  async (id: any, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.get(API.task, { params: { contact_id: id } });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTaskTypes = createAsyncThunk("tasks/getTaskType", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await axiosClient.get(API.taskType, { params: { limit: "*" } });
    return fulfillWithValue(response.data);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getTaskResults = createAsyncThunk(
  "tasks/getTaskResults",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.get(API.taskResult, { params: { limit: "*" } });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsers = createAsyncThunk(
  "tasks/getUsers",
  async (text: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const params: LooseObject = {};
      if (text === "") {
        params["limit"] = 10;
      } else {
        params["limit"] = "*";
        params["name"] = text;
      }
      const response = await axiosClient.get(API.restfulUsers, { params: { ...params } });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createTask = (data: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await axiosClient.post(API.task, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const editTask = (id: number, data: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = `${API.task}/${id}`;
    const response = await axiosClient.put(url, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const changeResult = (id: number, data: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = `${API.task}/${id}/change-result`;
    const response = await axiosClient.post(url, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const addComment =
  (taskId: any, data: any, listImage: ImageComment[]) => async (dispatch: AppDispatch, getState: any) => {
    try {
      const url = API.comment(taskId);
      let bodyFormData = new FormData();
      bodyFormData.append("comment", data);
      listImage.forEach((item: any) => {
        bodyFormData.append(`images[${item.name}]`, item.src);
      });
      const response = await axiosClient.post(url, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export default taskSlice;
