import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import BaseButton from "../../Buttons/BaseButton";
import { useState } from "react";

interface ModalTextFormProp {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
}

export default function ModalTextForm({ isOpen, onClose, onConfirm }: ModalTextFormProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [error, setError] = useState<string>("");
  const [text, setText] = useState<string>("");
  const handleClickSubmit = () => {
    if (text.trim() === "") {
      setText("");
      setError(t("this_field_is_required"));
      return;
    }
    onConfirm(text);
  };
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <div className={classes.title}>
          <p>{t("save_query")}</p>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers className={classes.containerContent}>
          <div className={classes.formControl}>
            <p className={`${classes.label}`}>
              {`${t("query_name")}: `}
              <span>*</span>
            </p>
            <input
              className={classes.input}
              type="text"
              value={text}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setText(e.target.value);
                setError("");
              }}
            />
          </div>
          {error && <div className={classes.error}>{error}</div>}
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={t("no")} className={classes.btnNo} onClick={() => onClose()} />
          <BaseButton title={t("yes")} className={classes.btnYes} onClick={() => handleClickSubmit()} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
