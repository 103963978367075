import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    backgroundColor: "#FFF",
    height: "47px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      whiteSpace: "nowrap",
      fontSize: "18px",
      fontWeight: 700,
      textTransform: "uppercase",
      maxWidth: "350px", 
      textOverflow: "ellipsis",
    },
  },
}));
