import { Box, Button } from "@mui/material";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import colors from "utils/colors";
import { useStyles } from "./styles";

interface IProps {
  start: any;
  end: any;
  applyCallback: Function;
  leftMode?: boolean;
  noMobileMode?: boolean;
}

const ReportDateRangePicker: React.FC<IProps> = (props) => {
  const { start, end, applyCallback, leftMode, noMobileMode } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const rangesOption = {
    [t("today")]: [moment(), moment()],
    [t("yesterday")]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    [t("this_week")]: [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    [t("last_week")]: [
      moment().subtract(1, "weeks").startOf("isoWeek"),
      moment().subtract(1, "weeks").endOf("isoWeek"),
    ],
    [t("this_month")]: [moment().startOf("month"), moment().endOf("month")],
    [t("last_month")]: [moment().subtract(1, "months").startOf("month"), moment().subtract(1, "months").endOf("month")],
    [t("this_year")]: [moment().startOf("year"), moment()],
    [t("last_year")]: [moment().subtract(1, "years").startOf("year"), moment().subtract(1, "years").endOf("year")],
  };

  const local = {
    format: "YYYY/MM/DD",
    sundayFirst: false,
    months: [
      t("january"),
      t("february"),
      t("march"),
      t("april"),
      t("may"),
      t("june"),
      t("july"),
      t("august"),
      t("september"),
      t("october"),
      t("november"),
      t("december"),
    ],
    fromDate: t("from_date"),
    toDate: t("to_date"),
    apply: t("apply"),
    cancel: t("cancel"),
  };
  return (
    <Box className={classes.container}>
      <DateTimeRangeContainer
        ranges={rangesOption}
        start={start}
        end={end}
        local={local}
        applyCallback={applyCallback}
        noMobileMode={noMobileMode}
        leftMode={leftMode}
        style={{
          customRangeButtons: { color: colors.defaultColorText },
          customRangeSelected: {
            backgroundColor: colors.btnDark,
            border: `1px solid ${colors.btnDark}`,
          },
          fromDate: { backgroundColor: colors.btnDark },
          toDate: { backgroundColor: colors.btnDark },
        }}
      >
        <Button variant="contained">{t("custom")}</Button>
      </DateTimeRangeContainer>
    </Box>
  );
};

export default ReportDateRangePicker;
