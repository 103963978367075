import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  headerModal: {
    height: "40px",
    backgroundColor: "#4A4358",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: 700,
    paddingLeft: "16px",
  },
  wrapTitle: {
    height: "40px",
    backgroundColor: "#4A4358",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    "& p": {
      marginLeft: "12px",
    },
  },
  containerContent: {
    columnCount: 3,
    columnGap: "32px",
    "@media (max-width: 1280px)": {
      columnCount: 2,
    },
    "@media (max-width: 768px)": {
      columnCount: 1,
    },
  },
  formControl: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
    },
    marginBottom: "15px",
    [theme.breakpoints.between("xs", "md")]: {
      flexDirection: "column",
    },
    "& p": {
      [theme.breakpoints.between("xs", "md")]: {
        marginBottom: 5,
      },
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    margin: 0,
    minWidth: "200px",
  },
  formControlLabel: {
    fontSize: "15px",
  },
  input: {
    width: "100%",
    height: "30px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
  },
  inputSelect: {
    width: "100%",
    minWidth: "100px",
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "left",
    marginTop: "-5px",
    paddingLeft: "5px",
    "@media (max-width: 768px)": {
      paddingLeft: "200px",
    },
  },
  footer: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      height: "30px",
      justifyContent: "center",
      padding: "20px 0",
    },
  },
  btnCancel: {
    height: "44px",
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "21px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  btnSearch: {
    height: "44px",
    backgroundColor: "#6A7A83",
    marginLeft: "21px !important",
    marginRight: "21px",
    "&:hover": {
      backgroundColor: "#6A7A83",
    },
  },
  btnSaveQuery: {
    height: "44px",
    backgroundColor: colors.btnPrimary,
    marginLeft: "21px !important",
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
  iconAdd: {
    fontSize: "29px !important",
    marginRight: "-4px",
  },
  hidden: {
    display: "none",
  },
  btnClose: {
    backgroundColor: "#4A4358",
    color: "#FFF",
  },
}));
