import { useStyles } from "./styles";

interface IProp {
  errors?: string;
  widthLabel?: number;
  marginRightLabel?: number;
  name?: any;
}

export default function ErrorMessageForm({ errors, widthLabel, name, marginRightLabel }: IProp) {
  const { classes } = useStyles();
  return (
    <>
      {errors && (
        <div className={classes.wrap}>
          {widthLabel !== 0 && (
            <p style={{ width: widthLabel ?? 80, marginRight: marginRightLabel }} className={classes.label}></p>
          )}
          <div className={classes.error}>{errors}</div>
        </div>
      )}
    </>
  );
}
