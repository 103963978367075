import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFilter } from "models/common";
import { RootState } from "redux/store";
import { axiosClient } from "services/axiosClient";
import { API } from "utils/api";
import { UnitTag } from "./contactCategorySlice";
import { LooseObject } from "models/common";

interface IUnitTagInitialState {
  unitTags: UnitTag[],
  filters: IUnitTagFilter
}

interface IUnitTagFilter extends IFilter{
  name?: string|null
}

const initialState: IUnitTagInitialState = {
  unitTags: [],
  filters: {
    page: 1,
    limit: 10,
    total: 0,
  }
}

export const getUnitTagList = createAsyncThunk(
  "unitTag/getUnitTagList",
  async(_, { rejectWithValue, fulfillWithValue, getState}) => {
    const url: string = API.restfulUnitTag;
    const state = getState() as RootState;
    const paramsData = state.unitTag.filters;
    return await axiosClient.get(url,  {params: { ...paramsData }})
      .then(response => fulfillWithValue(response?.data?.data))
      .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
)

export const createMultipleUnitTag = createAsyncThunk("unitTag/createMultipleUnitTag", async(data: LooseObject[],  { rejectWithValue, fulfillWithValue}) => {
  const url: string = API.restfulUnitTag + "/create-multiple";
    return await axiosClient.post(url,  { names: data })
      .then(response => fulfillWithValue(response?.data?.data))
      .catch(response => rejectWithValue(response?.response?.data?.errors))
})

const unitTagSlice = createSlice({
  name: "unitTag",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.filters.name = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUnitTagList.fulfilled, (state, action) => {
      state.filters.limit = action.payload?.per_page;
      state.filters.page = action.payload?.current_page;
      state.filters.total = action.payload?.total;
      state.unitTags = action.payload?.data;
    })
  }
})

export const getUnitContact = (name: string) => async () => {
  try {
    const param = {
      name: name,
      limit: "*",
    }
    const response = await axiosClient.get(API.restfulUnitTag, { params: {...param} });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default unitTagSlice;
export const unitTagListSelector = (state: any) => state?.unitTag?.unitTags;