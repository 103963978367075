import { Box, Button } from "@mui/material";
import iconEnSquare from "../../assets/images/englandSquare.png";
import iconJPSquare from "../../assets/images/japaneseSquare.jpg";
import iconVnSquare from "../../assets/images/vietnamSquare.png";

import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

const ChangeLanguage = () => {
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
    localStorage.setItem("react-app-lang", language);
  };

  return (
    <Box className={classes.container} >
      <Button onClick={() => changeLanguage("vi")} sx={{ p: 0 }} disabled={currentLanguage === "vi"}>
        <Box className={classes.wrapLanguage}>
          <img
            alt="Language"
            className={`${classes.language} ${currentLanguage === "vi" && classes.activeLanguage}`}
            src={iconVnSquare}
          />
        </Box>
      </Button>
      {process.env.REACT_APP_IS_SHOW_EN_TRANSLATE_BTN === "true" && (
        <Button onClick={() => changeLanguage("en")} sx={{ p: 0 }} disabled={currentLanguage === "en"}>
          <Box className={classes.wrapLanguage}>
            <img
              alt="Language"
              className={`${classes.language} ${currentLanguage === "en" && classes.activeLanguage}`}
              src={iconEnSquare}
            />
          </Box>
        </Button>
      )}
      {process.env.REACT_APP_IS_SHOW_JP_TRANSLATE_BTN === "true" && (
        <Button onClick={() => changeLanguage("jp")} sx={{ p: 0 }} disabled={currentLanguage === "jp"}>
          <Box className={classes.wrapLanguage}>
            <img
              alt="Language"
              className={`${classes.language} ${currentLanguage === "jp" && classes.activeLanguage}`}
              src={iconJPSquare}
            />
          </Box>
        </Button>
      )}
    </Box>
  );
};

export default ChangeLanguage;
