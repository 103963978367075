import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    width: "40px",
    height: "20px",
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 1,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(20px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#4A4358",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
        "& .MuiSwitch-thumb": {
          color: "#FFF",
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#4A4358",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "gray",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: "18px",
      height: "18px",
      color: "#4A4358",
    },
    "& .MuiSwitch-track": {
      borderRadius: "15px",
      backgroundColor: "#E9E9E9",
      opacity: 1,
    },
  },
}));
