import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/store";
import Loading from "components/Loading/Loading";
import { useStyles } from "./styles";
import Card from "components/Card";
import RoleForm from "components/Forms/RoleForm";
import { useSelector } from "react-redux";
import { findRoleById, roleSelector, updateRole } from "store/roleSliceV2";

export default function EditRole() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getRoleEdit = useCallback(async () => {
    if (id) {
      setLoading(true);
      await dispatch(findRoleById(Number(id)));
      setLoading(false);
    }
  }, [dispatch, id]);

  useEffect(() => {
    getRoleEdit();
  }, [getRoleEdit]);

  const role = useSelector(roleSelector);

  const handleEditRole = async (data: any) => {
    const dataSubmit = {
      id: Number(id),
      name: data?.name ?? "",
      permissions: data?.permissions ?? [],
    };

    setLoading(true);
    const response = await dispatch(updateRole(dataSubmit));
    if (response?.meta?.requestStatus === "fulfilled") {
      toast.success<void>(t("edit_role_successful"));
      navigate("/role");
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{t("edit_role")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <div className={classes.containerContent}>
          <Card title={t("edit_role")} titleText={t("please_fill_role_name_and_permissions")}>
            <RoleForm onSubmit={handleEditRole} data={role} />
          </Card>
        </div>
      </div>
    </>
  );
}
