import {
  Box,
  Collapse,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

interface IProps extends React.PropsWithChildren {
  title?: string;
}

const ReportCardCollapse: React.FC<IProps> = ({ title, children }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [checked, setChecked] = React.useState(true);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Box className={classes.contentChart}>
      <Box className={title ? classes.wrapTitle : classes.noWrapTitle}>
        <Typography variant="h6">{title}</Typography>
        <FormControlLabel
          label={t("show")}
          control={<Switch checked={checked} onChange={handleChange} />}
        />
      </Box>

      <Box className={classes.contentPage}>
        <Collapse in={checked} sx={{ flexGrow: 1 }}>
          {children}
        </Collapse>
      </Box>
    </Box>
  );
};

export default ReportCardCollapse;
