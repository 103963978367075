import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    paddingBottom: "30px",
    "& .MuiSelect-select": {
      padding: "4.5px 2px",
    },
    "& .MuiOutlinedInput-root": {
      minHeight: "30px !important",
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingLeft: "8px !important",
      fontSize: "14px!important",
      fontWeight: "400!important",
      border: "none",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5",
    },
    "& .MuiMenuItem-root": {
      fontFamily: "unset",
    },
    "& .MuiAutocomplete-tag": {
      height: "20px",
    },
    "& .MuiChip-deleteIcon": {
      fontSize: "14px !important",
    },
    "& .MuiChip-label": {
      fontSize: "14px",
      fontWeight: 400,
    },
    "& .MuiAutocomplete-input": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingLeft: "0 !important",
    },
    "& .MuiAutocomplete-root fieldset": {
      border: "1px solid #DDD",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #DDD",
    },
    "& .Mui-focused fieldset": {
      border: "1px solid #1070bd !important",
    },
    "& .ck": {
      borderRadius: 4,
      flexGrow: 1,
      wordBreak: "break-word",
      "& .ck-toolbar__separator": {
        maxWidth: "3px",
      },
    },
    "& .ck-toolbar": {
      borderRadius: "4px 4px 0 0 !important",
      backgroundColor: "#F5F5F5",
    },
    "& .ck-content": {
      height: "250px",
      borderRadius: "0 0 4px 4px !important",
      backgroundColor: "#F5F5F5",
      border: "1px solid #ccced1",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0",
    },
  },
  title: {
    borderRadius: "4px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#7C8286",
    "& p": {
      fontSize: "18px",
      fontWeight: 400,
      color: "#FFFFFF",
      marginLeft: "15px",
    },
  },
  content: {
    borderRadius: "0 0 4px 4px",
    padding: "0 16px",
  },
  input: {
    backgroundColor: "#F5F5F5",
    width: "387px!important",
    flexGrow: 1,
    boxSizing: "border-box",
    ":focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    ":hover": {
      borderColor: "1px solid #1070bd",
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  wrap: {
    display: "flex",
    alignItems: "flex-start",
    fontSize: "14px",
    fontWeight: 400,
    "& > p": {
      marginRight: "60px",
      "& span": {
        color: "#F00",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& p": {
        marginRight: 0,
        marginBottom: "5px",
      },
    },
  },
  label: {
    margin: 0,
  },
  textField: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
  },
  noOptions: {
    fontSize: "14px",
    fontWeight: "400",
  },
  flexGrow: {
    flexGrow: 1,
  },
}));
