import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";
import bannerSchool from "../../../assets/images/banner-school.jpeg";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: "100%",
  },
  header: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#AB2523",
    zIndex: "9000",
    display: "flex",
    justifyContent: "center",
  },
  wrapHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "1200px",
    width: "100%",
    padding: "5px 15px",
    height: "60px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "80px",
      justifyContent: "center",
    },
  },
  logo: {
    height: "55px",
  },
  titleRegister: {
    textTransform: "uppercase",
    color: colors.backgroundLight,
    fontWeight: 700,
    fontSize: "20px",
  },
  content: {
    marginTop: "70px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "90px",
    },
  },
  banner: {
    display: "flex",
    justifyContent: "center",
    backgroundImage: `url(${bannerSchool})`,
    height: "550px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
  },
  textBanner: {
    backgroundColor: "#00000088",
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapBtnFooter: {
    display: "flex",
    justifyContent: "center",
    margin: "32px 0",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  btnSubmit: {
    width: "200px",
    height: "40px",
    fontSize: "18px",
    textTransform: "uppercase",
    fontWeight: 600,
    backgroundColor: "#AB2523",
    "&:hover": {
      backgroundColor: "#AB2523",
    },
  },
  headerModalRemind: {
    color: "#FFF  !important",
    backgroundColor: "#AB2523 !important",
    "& button": {
      backgroundColor: "#AB2523 !important",
      color: "#FFF !important",
    },
  },
}));
