import { ChartOptions } from "chart.js";

export const optionsPieChart: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
      labels: {
        boxWidth: 15,
      },
    },
  },
};

export const optionsBarChart: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: true,
    },
    y: {
      display: true,
    },
  },
};

export const optionsLineChart: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },

    y: {
      display: false,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};
