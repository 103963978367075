import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface IState {
  action: stateActionSupport;
}

export interface stateActionSupport {
  enableSendSms: boolean;
  enableSendEmail: boolean;
  enableCallLog: boolean;
  enableLine: boolean;
}

const initialState: IState = {
  action: {
    enableSendSms: true,
    enableSendEmail: true,
    enableCallLog: true,
    enableLine: true,
  },
};

const actionSupportSlice = createSlice({
  name: "actionSupport",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default actionSupportSlice;

export const actionButtonSelector = (state: RootState) => state.actionSupport.action;
