import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  container: {
    padding: "16px",
    "& p": {
      margin: 0,
    },
  },
  wrapBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
  },
  wrapBtnLog: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginTop: "16px",
  },
  btnYes: {
    backgroundColor: colors.btnPrimary,
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
  btnYesLog: {
    backgroundColor: "#6A7A83",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#6A7A83",
    },
  },
  btnNo: {
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "10px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  editor: {
    display: "flex",
    flexDirection: "column-reverse",
    "& img": {
      maxHeight: "200px",
      maxWidth: "max-content",
    },
    "& .ck-toolbar": {
      backgroundColor: "#eaecec",
      borderRadius: "0 0 4px 4px !important",
    },
    "& .ck-content": {
      height: "250px",
      border: "1px solid #ccced1",
      borderRadius: "4px 4px 0 0 !important",
      "&:hover": {
        border: "1px solid #1070bd",
      },
      "& img": {
        maxHeight: "200px",
        maxWidth: "max-content",
      },
    },
  },
  error: {
    marginTop: "5px",
    color: "red",
    fontSize: "14px",
    fontWeight: 400,
  },
}));
