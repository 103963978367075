import Dropdown from "components/Dropdown";
import FilterTag from "components/FilterTag";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading/Loading";
import Table from "components/Table";
import BasicDateRange from "components/dropdownDateRangePicker";
import SearchBar from "components/inputSearch";
import { LooseObject } from "models/common";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "redux/store";
import {
  deleteFieldSearch,
  fetchMasterLists,
  getUnits,
  initialSearchOptionListMaster,
  setPageSize,
  updateSearch,
  updateSearchOption,
} from "store/listMasterContactSlice";
import { ACTION_MASTER_LIST, MASTER_LIST_COLUMN } from "utils/constant";
import { formatSearchContent, sortTable } from "utils/helper";
import { useStyles } from "./style";

export default function MasterList() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const lists = useSelector((state: RootState) => state.masterLists.lists);
  const units = useSelector((state: RootState) => state.masterLists.units);
  const page = useSelector((state: RootState) => state.masterLists.page);
  const total = useSelector((state: RootState) => state.masterLists.total);
  const pageSize = useSelector((state: RootState) => state.masterLists.limit);
  const searchOption = useSelector((state: RootState) => state.masterLists.searchOption);
  const [limit, setLimit] = useState<number>(pageSize);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [checkedList, setCheckedList] = useState<number[] | []>([]);
  const [isShowAction, setIsShowAction] = useState<boolean>(false);
  const [isShowClearAll, setIsShowClearAll] = useState<boolean>(false);
  const [startUpdate, setStartUpdate] = useState(moment().startOf("isoWeek"));
  const [endUpdate, setEndUpdate] = useState(moment().endOf("isoWeek"));
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));

  const [data, setData] = useState<LooseObject[]>([]);

  const initialized = useRef(false);
  const getDataOption = async () => {
    setLoading(true);
    await dispatch(getUnits());
    setLoading(false);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDataOption();
      const firstFormGroup = document.querySelectorAll("#root")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setCurrentPage(page);
    navigate(`/masterList?page=${page}`);
    await dispatch(fetchMasterLists(page));
    setIsShowAction(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, page, pageSize, searchOption]);

  useEffect(() => {
    setData(lists);
    setCheckedList([]);
  }, [lists]);

  useEffect(() => {
    let check: boolean = false;
    if (searchOption) {
      Object.keys(searchOption).map((item) => {
        if (
          (Array.isArray(searchOption[item]) && searchOption[item].length !== 0) ||
          Object.keys(searchOption[item]).length !== 0
        ) {
          check = true;
          return null;
        }
        return null;
      });
    }
    setIsShowClearAll(check);
  }, [searchOption]);

  const handlePageClick = (page: number = 1) => {
    setCurrentPage(page);
    navigate(`/masterList?page=${page}`);
    setLoading(true);
    setCheckedList([]);
    setIsShowAction(false);
    dispatch(fetchMasterLists(page))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCheckBox = useCallback((checkbox: number[]) => {
    setCheckedList(checkbox);
    if (checkbox.length > 0) {
      setIsShowAction(true);
    } else {
      setIsShowAction(false);
    }
  }, []);

  const handleClickAction = useCallback((action: string) => {}, []);

  const handleSortTable = useCallback(
    async (field: string, type: string) => {
      const newData: LooseObject[] = sortTable(data, field, type);
      setData(newData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [data]
  );

  const handleChangeLimit = useCallback(async (limit: number) => {
    setLimit(limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(async (page: number) => {
    handlePageClick(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetPageSize = useCallback(async (data: number) => {
    dispatch(setPageSize(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyCallbackLastUpdated = (startDate: any, endDate: any) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartUpdate(startDate);
    setEndUpdate(endDate);
    setIsShowAction(false);
    dispatch(updateSearchOption(option, "last_updated"));
    fetchData();
  };

  const applyCallbackCreateDate = (startDate: any, endDate: any) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setCurrentPage(1);
    setIsShowAction(false);
    dispatch(updateSearchOption(option, "created_date"));
    fetchData();
  };

  const handleClickMenuItem = (row: LooseObject | string, field: string) => {
    dispatch(updateSearchOption(row, field));
  };

  const handleDeleteFieldSearch = (field: string) => {
    if (field === "text") {
      setSearchText("");
    }
    dispatch(deleteFieldSearch(field));
  };

  const handleClearAllTagSearch = () => {
    dispatch(updateSearch(initialSearchOptionListMaster));
    setSearchText("");
  };

  const handleSearchList = () => {
    if (searchText === searchOption?.text) {
      return;
    }
    dispatch(updateSearchOption(searchText, "text"));
  };

  return (
    <>
      <Helmet>
        <title>{t("master_lists")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <HeaderPage title={t("master_lists")} />
        <div>
          <div className={classes.contentPage}>
            <div className={classes.wrapSearchFilter}>
              <div className={classes.containerSearch}>
                <SearchBar
                  value={searchText}
                  className={classes.inputSearch}
                  handleChange={setSearchText}
                  onBlur={handleSearchList}
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === "Enter" || e.key === "Tab") {
                      handleSearchList();
                      const target = e.target as HTMLButtonElement;
                      target.blur();
                    }
                  }}
                />
                <div className={classes.wrapDropdown}>
                  <Dropdown
                    title={t("unit")}
                    className={classes.dropdown}
                    options={units ?? []}
                    withCheckbox
                    withSearch
                    onClickItem={handleClickMenuItem}
                    fieldSearch="unit_tags"
                    searchOption={searchOption}
                  />
                  <BasicDateRange
                    title={t("last_updated")}
                    start={startUpdate}
                    end={endUpdate}
                    applyCallback={applyCallbackLastUpdated}
                  />
                  <BasicDateRange
                    title={t("created_date")}
                    start={startCreate}
                    end={endCreate}
                    applyCallback={applyCallbackCreateDate}
                  />
                </div>
              </div>
            </div>
            <div className={classes.containerFilter}>
              {Object.entries(searchOption).map(([category, options]) => {
                let text: any = category as any;
                if (
                  options &&
                  typeof options === "object" &&
                  (category === "created_date" || category === "last_updated") &&
                  "startDate" in options &&
                  "endDate" in options &&
                  options?.startDate &&
                  options?.endDate
                ) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${moment(options?.startDate).format("YYYY/MM/DD")} -> ${moment(
                        options?.endDate
                      ).format("YYYY/MM/DD")}`}
                      key={category}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (Array.isArray(options) && options.length !== 0) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${formatSearchContent(options, t("or"))}`}
                      key={category}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (options && typeof options === "string") {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (options && typeof options === "object" && "name" in options && options?.name) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options?.name}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                }
                return null;
              })}

              {isShowClearAll && (
                <div className={classes.clearAll} onClick={() => handleClearAllTagSearch()}>
                  <p>{t("clear_all")}</p>
                </div>
              )}
            </div>
            <div className={classes.results}>{isShowClearAll && `${total} ${t("results")}`}</div>
          </div>
          <div className={classes.contentPage}>
            <Table
              data={data}
              columns={MASTER_LIST_COLUMN}
              total={total}
              limit={limit}
              pageSize={pageSize}
              currentPage={currentPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
              handleSortTable={handleSortTable}
              setPageSize={handleSetPageSize}
              handleCheckBox={handleCheckBox}
              isShowAction={isShowAction}
              actionMenu={ACTION_MASTER_LIST as any[]}
              handleClickAction={handleClickAction}
              checkedList={checkedList}
            />
          </div>
        </div>
      </div>
    </>
  );
}
