import { yupResolver } from "@hookform/resolvers/yup";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import BaseButton from "components/Buttons/BaseButton";
import RoleBaseComponent from "components/RoleBaseComponent";
import Select from "components/Select";
import InputDate from "components/inputDate";
import { LooseObject, optionsStudentContact } from "models/common";
import { Fragment, useEffect, useRef, useState } from "react";
import {Controller, useForm} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux/store";
import { getCities, getDistrict } from "store/locationSlice";
import * as Yup from "yup";
import ModalTextForm from "../ModalTextForm";
import { useStyles } from "./styles";
import {Checkbox, FormControlLabel, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputDateTime from "../../inputDateTime";

interface ModalFilterProp {
  isOpen: boolean;
  onClose: Function;
  onConfirmSearch: Function;
  onConfirmSaveQuery: Function;
  optionsSelect: optionsStudentContact;
  filterField: LooseObject;
  filterOptions: LooseObject;
  isSearchListContact?: boolean;
  handleSearchList?: Function;
}

export default function ModalFilter({
  isOpen,
  onClose,
  onConfirmSaveQuery,
  onConfirmSearch,
  optionsSelect,
  filterField,
  filterOptions,
  isSearchListContact,
  handleSearchList,
}: ModalFilterProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [isSearch, setIsSearch] = useState<boolean>(true);
  const [isOpenModalSaveQuery, setIsOpenModalSaveQuery] = useState<boolean>(false);
  const [options, setOptions] = useState<optionsStudentContact>(optionsSelect);
  const refBtnSubmit = useRef<HTMLButtonElement>(null);
  const [shouldFilterEmailedContact, setShouldFilterEmailedContact] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    filter_contact_list_id: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_full_name: Yup.string().trim().max(255, t("this_field_must_not_exceed_255_characters")),
    filter_email: Yup.string().trim().max(255, t("this_field_must_not_exceed_255_characters")),
    filter_phone_number: Yup.string().transform((cv, ov) => {
      return ov === "" ? undefined : cv;
    }),
    countries: Yup.array().nullable(),
    cities: Yup.array().nullable(),
    districts: Yup.array().nullable(),
    filter_citizen_id: Yup.string().transform((cv, ov) => {
      return ov === "" ? undefined : cv;
    }),
    filter_address: Yup.string().trim(),
    statuses: Yup.array().nullable(),
    states: Yup.array().nullable(),
    filter_note: Yup.string().trim(),
    filter_competency_evaluation_score: Yup.string().transform((cv, ov) => {
      return ov === "" ? undefined : cv;
    }),
    filter_total_graduation_score: Yup.string().transform((cv, ov) => {
      return ov === "" ? undefined : cv;
    }),
    filter_high_school: Yup.string().trim(),
    filter_discipline: Yup.string().trim(),
    filter_university: Yup.string().trim(),
    filter_english_language_certificate: Yup.string().trim(),
    filter_japan_language_certificate: Yup.string().trim(),
    filter_subject_combination_id: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_exam_subject_id: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_contact_source_id: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_call_status_id: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_reject_reason_id: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_pic: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_contact_has_task_with_user_id: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_date_of_acceptance: Yup.date().nullable(),
    filter_aspiration_1: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_aspiration_2: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    filter_aspiration_3: Yup.object()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    name: Yup.string(),
    contact_with_email_only: Yup.mixed()
        .nullable(),
    contact_without_email_only: Yup.mixed()
        .nullable(),
    year_of_concern: Yup.mixed()
        .nullable(),
  });
  type FilterSubmitForm = Yup.InferType<typeof validationSchema>;
  type KeyError = keyof FilterSubmitForm;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
    getValues,
  } = useForm<FilterSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const newOption: optionsStudentContact = { ...options };
    newOption.list = optionsSelect?.list;
    setOptions(newOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsSelect]);

  const handleSubmitModal = async (data: FilterSubmitForm) => {
    if (isSearch) {
      onConfirmSearch(data, options);
    } else {
      onConfirmSaveQuery(data);
    }
  };

  const getData = async () => {
    Object.entries(filterOptions).map(([key, value]) => {
      if ((value && typeof value === "string") || Array.isArray(value)) {
        setValue(key as KeyError, value);
      } else if (value.name) {
        setValue(key as KeyError, value);
      }
      return null;
    });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSelect = async (name: string, data: any, reason: string) => {
    const dataOption: optionsStudentContact = JSON.parse(JSON.stringify(options));
    if (reason === "removeOption") {
      if (name === "countries") {
        dataOption.city = dataOption.city.filter((item: LooseObject) => item.id !== data.option.id);
        dataOption.district = dataOption.district.filter((item: LooseObject) => item.country_id !== data.option.id);
        let valueCities = getValues("cities");
        if (valueCities) {
          valueCities = valueCities.filter((item: LooseObject) => item.country_id !== data.option.id);
        }
        let valueDistricts = getValues("districts");
        if (valueDistricts) {
          valueDistricts = valueDistricts.filter((item: LooseObject) => item.country_id !== data.option.id);
        }
        setValue("cities", valueCities);
        setValue("districts", valueDistricts);
      }
      if (name === "cities") {
        dataOption.district = dataOption.district.filter((item: LooseObject) => item.id !== data.option.id);
        let valueDistricts = getValues("districts");
        if (valueDistricts) {
          valueDistricts = valueDistricts.filter((item: LooseObject) => item.city_id !== data.option.id);
        }
        setValue("districts", valueDistricts);
      }
    } else if (reason === "clear") {
      if (name === "countries") {
        dataOption.city = [];
        dataOption.district = [];
        setValue("countries", []);
        setValue("cities", []);
        setValue("districts", []);
      } else if (name === "cities") {
        dataOption.district = [];
        setValue("cities", []);
        setValue("districts", []);
      } else if (Array.isArray(data)) {
        setValue(name as KeyError, []);
      } else {
        setValue(name as KeyError, "");
      }
    } else {
      if (name === "countries") {
        const cities = await dispatch(getCities([data[data.length - 1].id]));
        if (cities && Array.isArray(cities)) {
          let cityData: LooseObject[] = [...cities];
          cityData.forEach((item: LooseObject) => (item.nameGroup = data[data.length - 1].name));
          dataOption.city = [...dataOption.city, { ...data[data.length - 1], options: cities }];
        }
      }
      if (name === "cities") {
        const district = await dispatch(getDistrict([data[data.length - 1].id]));
        if (district && Array.isArray(district)) {
          let districts: LooseObject[] = [...district];
          districts.forEach((item: LooseObject) => {
            item.country_id = data[data.length - 1].country_id;
            item.nameGroup = data[data.length - 1].name;
          });
          dataOption.district = [...dataOption.district, { ...data[data.length - 1], options: districts }];
        }
      }
    }
    setOptions(dataOption);
  };
  const handleClickSaveQuery = () => {
    setIsOpenModalSaveQuery(true);
    setIsSearch(false);
  };

  const handleSaveQuery = (text: string) => {
    setIsOpenModalSaveQuery(false);
    setValue("name", text);
    refBtnSubmit.current && refBtnSubmit.current.click();
  };

  const handleSearchQuery = () => {
    setIsSearch(true);
    refBtnSubmit.current && refBtnSubmit.current.click();
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"xl"} open={isOpen} onClose={() => onClose()} scroll={"paper"}>
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
            <FilterAltIcon />
            <p>{t("filter")}</p>
          </div>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <form onSubmit={handleSubmit(handleSubmitModal)} noValidate className={classes.containerContent}>
            {filterField.option.map((field: LooseObject, index: number) => (
              <Fragment key={`${index} - b`}>
                <div className={classes.formControl}>
                  <p className={`${classes.label}`}>{t(field.title)}</p>
                  {field.title === "filter_contact_list_id" ? (
                    <Select
                      options={options[field.option as keyof optionsStudentContact]}
                      className={classes.inputSelect}
                      nameRegister={field.name}
                      control={control}
                      isForm
                      onchange={handleChangeSelect}
                      isObjectSubmit
                      isSearching={isSearchListContact}
                      onChangeSearch={handleSearchList}
                    />
                  ) : field.type === "select" ? (
                    <Select
                      options={options[field.option as keyof optionsStudentContact]}
                      className={classes.inputSelect}
                      nameRegister={field.name}
                      control={control}
                      isForm
                      onchange={handleChangeSelect}
                      isObjectSubmit
                      isMultiple={field?.isMultiple && true}
                      isSubOption={field?.isSubOption && true}
                    />
                  ) : field.type === "date" ? (
                    <InputDate control={control} nameRegister={field.name} isPast resetField={resetField} />
                  ) : field.type === "exclude_emailed_contact" ? (
                      <div>
                        <InputDateTime placeholder={t('from_date')} control={control} nameRegister={field.start_time.name} isPast={true} resetField={resetField} />
                        <InputDateTime _style={{"marginTop": "0.5rem"}} placeholder={t('to_date')} control={control} nameRegister={field.end_time.name} isPast={true} resetField={resetField} />
                      </div>
                  ) : field.title === "filter_email" ? (
                      <div>
                        <input placeholder={t('input_email')} className={classes.input} {...register(field.name)} type={field.type} />
                        <FormControlLabel sx={{marginTop: "0.5rem", height: "1rem"}} control={
                          <Controller
                              control={control}
                              render={
                                ({ field: { value, onChange, } }) =>
                                    (<Checkbox
                                        checked={value ? true : false}
                                        value={value}
                                        onChange={(event) => {onChange(event)}}
                                    />)
                              }
                              name={'contact_without_email_only'}
                          />
                        } label={<Typography className={classes.formControlLabel}>{t('contact_without_email_only')}</Typography>} />
                        <FormControlLabel sx={{marginTop: "0.5rem", height: "1rem"}} control={
                          <Controller
                              control={control}
                              render={
                                ({ field: { value, onChange, } }) =>
                                    (<Checkbox
                                        checked={value ? true : false}
                                        value={value}
                                        onChange={(event) => {onChange(event)}}
                                    />)
                              }
                              name={'contact_with_email_only'}
                          />
                        } label={<Typography className={classes.formControlLabel}>{t('contact_with_email_only')}</Typography>} />
                      </div>
                  ) : field.title === "year_of_concern" ?
                      <InputDateTime placeholder={t('year_of_concern')} control={control}
                                     nameRegister={'year_of_concern'} isPast={true} resetField={resetField} openTo={'year'} isYear={true}/>
                       : (
                    <input className={classes.input} {...register(field.name)} type={field.type} />
                  )}
                </div>
                <div className={classes.error}>{errors[field.name as KeyError]?.message}</div>
              </Fragment>
            ))}
            <button type="submit" className={classes.hidden} ref={refBtnSubmit}></button>
          </form>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={t("cancel")} className={classes.btnCancel} onClick={() => onClose()} />
          <BaseButton title={t("search")} className={classes.btnSearch} onClick={() => handleSearchQuery()} />
          <RoleBaseComponent permissions={["student_contact_save_query"]}>
            <BaseButton
              title={t("save_query")}
              className={classes.btnSaveQuery}
              onClick={() => handleClickSaveQuery()}
              startIcon={<AddRoundedIcon className={classes.iconAdd} />}
            />
          </RoleBaseComponent>
        </DialogActions>
      </Dialog>
      {isOpenModalSaveQuery && (
        <ModalTextForm
          isOpen={isOpenModalSaveQuery}
          onClose={() => {
            setIsOpenModalSaveQuery(false);
            setIsSearch(true);
          }}
          onConfirm={handleSaveQuery}
        />
      )}
    </div>
  );
}
