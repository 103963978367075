import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import moment from "moment";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { useTranslation } from "react-i18next";
import colors from "utils/colors";
import { useStyles } from "./style";

interface BasicDateRangeProps {
  start: any;
  end: any;
  applyCallback: Function;
  title: string;
}
export default function BasicDateRange({
  start,
  end,
  applyCallback,
  title,
}: BasicDateRangeProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const rangesOption = {
    [t("last_week_only")]: [
      moment().subtract(1, "weeks").startOf("isoWeek"),
      moment().subtract(1, "weeks").endOf("isoWeek"),
    ],
    [t("this_week_only")]: [
      moment().startOf("isoWeek"),
      moment().endOf("isoWeek"),
    ],
    [t("last_month_only")]: [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    [t("this_month_only")]: [
      moment().startOf("month"),
      moment().endOf("month"),
    ],
  };

  const local = {
    format: "YYYY/MM/DD",
    sundayFirst: false,
    months: [
      t("january"),
      t("february"),
      t("march"),
      t("april"),
      t("may"),
      t("june"),
      t("july"),
      t("august"),
      t("september"),
      t("october"),
      t("november"),
      t("december"),
    ],
    fromDate: t("from_date"),
    toDate: t("to_date"),
    apply: t("apply"),
    cancel: t("cancel"),
  };

  return (
    <div className={classes.container}>
      <DateTimeRangeContainer
        ranges={rangesOption}
        start={start}
        end={end}
        local={local}
        applyCallback={applyCallback}
        rightMode
        style={{
          customRangeButtons: { color: colors.defaultColorText },
          customRangeSelected: {
            backgroundColor: colors.btnDark,
            border: `1px solid ${colors.btnDark}`,
          },
          fromDate: { backgroundColor: colors.btnDark },
          toDate: { backgroundColor: colors.btnDark },
        }}
      >
        <div className={classes.wrapTitle}>
          <div className={classes.title}>{title}</div>
          <ArrowDropDownRoundedIcon className={classes.iconArrow} />
        </div>
      </DateTimeRangeContainer>
    </div>
  );
}
