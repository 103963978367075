import { makeStyles } from "tss-react/mui";
import iconSms from "../../assets/images/sms.svg";
import iconCall from "../../assets/images/call.svg";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
    padding: 0,
    "& .MuiInputBase-input": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
      height: "30px",
      backgroundColor: "#F5F5F5",
      borderRadius: "4px",
      boxSizing: "border-box",
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F5F5F5",
      padding: 0,
      "& fieldset": {
        border: "1px solid #DEDEDE",
      },
      "&:hover fieldset": {
        border: "1px solid #202020",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #1070bd",
        outline: "none",
      },
    },
    "& .MuiIconButton-root": {
      marginRight: "-2px",
      marginTop: "2px",
    },
  },
  inputSelect: {
    "& .MuiOutlinedInput-root": {
      width: "100px",
      minHeight: "30px !important",
      padding: "0 20px 0 8px !important",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
      border: "none",
      borderRadius: "4px 0 0 4px !important",
      backgroundColor: "#F5F5F5",
    },
    "& .MuiAutocomplete-tag": {
      height: "20px",
    },
    "& .MuiChip-deleteIcon": {
      fontSize: "14px !important",
    },
    "& .MuiChip-label": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
    },
    "& .MuiAutocomplete-input": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingLeft: "0 !important",
      cursor: "pointer",
      minWidth: "unset !important",
    },
    "& .MuiAutocomplete-root fieldset": {
      border: "1px solid #DDD",
    },
    "& .Mui-focused fieldset": {
      border: "1px solid #1070bd !important",
    },
  },
  poper: {
    zIndex: 1000,
    width: "fit-content !important",
  },
  textField: {
    fontFamily: "Roboto !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    zIndex: 99,
  },
  noOptions: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
  },
  iconMenu: {
    display: "block",
    width: "24px",
    height: "24px",
    backgroundColor: "#413C51",
  },
  iconMenuCall: {
    display: "block",
    width: "20px",
    height: "20px",
    backgroundColor: "#413C51",
  },
  iconSms: {
    maskImage: `url(${iconSms})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
  },
  iconCall: {
    maskImage: `url(${iconCall})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
    isplay: "block",
    width: "20px",
    height: "20px",
  },
  noStatusCall: {
    backgroundColor: "#413C51",
  },
  haveNotCall: {
    backgroundColor: "#cccccc !important",
  },
  successfulCall: {
    backgroundColor: colors.btnPrimary + "!important",
  },
  failedCall: {
    backgroundColor: "#F2756F !important",
  },
}));
