import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import ChangeLanguage from "components/ChangeLanguage";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { COPYRIGHT, getPathToRedirect, getViewPermission } from "utils";
import * as Yup from "yup";
import logoVJU from "../../assets/images/VNU-VJU.jpeg";
import googleIcon from "../../assets/images/google-icon.png";
import logoDEHA from "../../assets/images/icon-login.png";
import imgLogin from "../../assets/images/img-login.png";
import microsoftIcon from "../../assets/images/logos_microsoft-icon.png";
import { getUserInfo } from "../../redux/auth/auth.service";
import { loginStandard } from "../../redux/auth/auth.slice";
import { AppDispatch } from "../../redux/store";
import { API } from "../../utils/api";
import { useStyles } from "./styles";

interface IPropsBody {
  classes: any;
}
interface IProps {}

const LogoRender: React.FC<IPropsBody> = (props) => {
  const { classes } = props;
  let logo = logoDEHA;

  if (process.env.REACT_APP_LOGO === "vju") {
    logo = logoVJU;
    return (
      <>
        <img className={classes.dehaAMS} alt="icon-login" src={logo} />
        <Typography textAlign={"center"} variant="h5" className={classes.title}>
          Admission management system
        </Typography>
      </>
    );
  } else return <img className={classes.dehaAMS} alt="icon-login" src={logo} />;
};

const Login: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const userInfo = getUserInfo();
  const [googleLoginUrl, setGoogleLoginUrl] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loadingGoogle, setLoadingGoogle] = useState<boolean>(false);
  const [loadingLogin, setLoadingLogin] = useState<boolean>(false);
  const loginGoogleRef = useRef<HTMLAnchorElement>(null);
  const { classes } = useStyles();
  type UserSubmitForm = {
    email: string;
    password: string;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("email_is_required"))
      .email(t("email_is_invalid"))
      .max(255, t("email_must_not_exceed_255_characters")),
    password: Yup.string().required(t("password_is_required")).max(255, t("password_must_not_exceed_255_characters")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  });

  const handleLoginStandard = async (data: UserSubmitForm) => {
    setLoadingLogin(true);
    const result = await dispatch(loginStandard(data));
    if (result) {
      const listPermission = getViewPermission(result?.user);
      const path = getPathToRedirect(listPermission);
      navigate(path);
    } else {
      toast.error<void>(t("login_failed."));
    }
    setLoadingLogin(false);
  };

  useEffect(() => {
    if (userInfo) {
      navigate(location.state?.from || "/");
    }
    fetch(`${API.urlLoginGoogle}`, {
      headers: new Headers({ accept: "application/json" }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setGoogleLoginUrl(data.data))
      .catch((error) => console.error(error));
  }, [userInfo, location.state?.from, navigate]);

  const handleLoginGoogle = () => {
    setLoadingGoogle(true);
    loginGoogleRef.current?.click();
    setLoadingGoogle(false);
  };

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <>
      <Helmet>
        <title>{t("login")}</title>
      </Helmet>
      {!userInfo && (
        <div className={classes.container}>
          <div className={classes.contentLeft}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Box className={classes.wrapTitleAndLogo}>
                <LogoRender classes={classes} />
              </Box>
              <div className={classes.wrapLoginSocial}>
                <LoadingButton
                  className={classes.btnGoogle}
                  variant="contained"
                  loading={loadingGoogle}
                  loadingPosition="start"
                  startIcon={<img src={googleIcon} alt="icon-google" className={classes.iconGoogle} />}
                  onClick={() => handleLoginGoogle()}
                >
                  <a ref={loginGoogleRef} href={googleLoginUrl ?? "/"}>
                    Google
                  </a>
                </LoadingButton>
                <LoadingButton
                  className={classes.btnMicrosoft}
                  variant="contained"
                  loading={loadingGoogle}
                  loadingPosition="start"
                  startIcon={<img src={microsoftIcon} alt="icon-google" className={classes.iconGoogle} />}
                  onClick={() => toast.warning<void>(t("not_available_at_this_time"))}
                >
                  <p>Microsoft</p>
                </LoadingButton>
              </div>
              <div className={classes.containerLine}>
                <div>
                  <hr />
                </div>
                <p>{t("or")}</p>
                <div>
                  <hr />
                </div>
              </div>
              <form onSubmit={handleSubmit(handleLoginStandard)}>
                <div className={classes.formControl}>
                  <p className={classes.label}>{t("email_address")}</p>
                  <input
                    className={classes.input}
                    {...register("email")}
                    type="text"
                    placeholder={t("example@gmail.com")}
                  />
                  <div className={classes.error}>{errors.email?.message}</div>
                </div>
                <div className={classes.formControl}>
                  <p className={classes.label}>{t("password")}</p>
                  <div className={classes.containerPassword}>
                    <input
                      className={classes.input}
                      {...register("password")}
                      type={showPassword ? "text" : "password"}
                      placeholder={t("password")}
                    />
                    <IconButton onClick={() => setShowPassword(!showPassword)} className={classes.btnHide}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>

                  <div className={classes.error}>{errors.password?.message}</div>
                </div>
                <div className={classes.caption}>
                  <div>
                    <input type="checkbox" name="" id="" />
                    <label>{t("remember_me")}</label>
                  </div>
                  <p>{t("reset_password?")}</p>
                </div>
                <LoadingButton className={classes.btnLogin} variant="contained" loading={loadingLogin} type="submit">
                  <span>{t("login")}</span>
                </LoadingButton>
              </form>
              <ChangeLanguage />
            </div>
            <p className={classes.footer}>{COPYRIGHT}</p>
          </div>
          {!isMobileScreen && (
            <div className={classes.contentRight}>
              <img className={classes.imgLogin} alt="login" src={imgLogin} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Login;
