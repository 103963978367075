import { yupResolver } from "@hookform/resolvers/yup";
import ButtonBackForm from "components/Forms/ButtonBackForm";
import ButtonSaveForm from "components/Forms/ButtonSaveForm";
import InputTextForm from "components/Forms/InputTextForm";
import PermissionForm from "components/Forms/PermissionForm";
import ModalConfirmLeaveForm from "components/ModalConfirmLeaveForm";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/store";
import { errorRoleSelector, resetRoleStateError } from "store/roleSliceV2";
import * as Yup from "yup";
import { useStyles } from "./styles";
import { LooseObject } from "models/common";

interface RoleFormProps {
  onSubmit: (data: any) => void;
  data?: LooseObject;
}

export default function RoleForm({ onSubmit, data }: RoleFormProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isOpenModalConfirmLeaveForm, setIsOpenModalConfirmLeaveForm] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetRoleStateError());
  }, [dispatch]);

  const handleCreateRole = useCallback(
    (data: FormData) => {
      onSubmit(data);
    },
    [onSubmit]
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("this_field_is_required")),
    permissions: Yup.array().notRequired(),
  });

  type FormData = Yup.InferType<typeof validationSchema>;

  const {
    reset,
    control,
    handleSubmit,
    register,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      permissions: [],
    },
  });

  useEffect(() => {
    reset(undefined, { keepValues: false, keepDirty: false, keepDefaultValues: false });
    clearErrors();
  }, [reset, clearErrors]);

  useEffect(() => {
    if (data) {
      setValue("name", data.name ?? "");
      const permissionIds = data?.permissions?.map((item: LooseObject) => item.id);
      setValue("permissions", permissionIds ?? []);
    }
  }, [data, setValue]);

  const handleBackClick = useCallback(() => {
    isDirty ? setIsOpenModalConfirmLeaveForm(true) : navigate("/role");
  }, [isDirty, navigate]);

  const errorFromApis = useSelector(errorRoleSelector);

  const getErrorsValidate = useCallback(
    (name: any) => {
      return Object.keys(errors).length !== 0
        ? errors[name as keyof typeof errors]?.message
        : errorFromApis?.[name]?.[0];
    },
    [errors, errorFromApis]
  );

  return (
    <>
      <form onSubmit={handleSubmit(handleCreateRole)}>
        <InputTextForm
          title={t("role")}
          name={"name"}
          register={register}
          isRequired={true}
          errors={getErrorsValidate("name")}
        />

        <div className={classes.titleText}>{t("permissions")}</div>
        <div style={{ margin: "20px" }}>
          <PermissionForm control={control} />
        </div>
        <div className={classes.containerBtn}>
          <ButtonBackForm onClick={handleBackClick} />
          <ButtonSaveForm />
        </div>
      </form>
      <ModalConfirmLeaveForm
        isOpen={isOpenModalConfirmLeaveForm}
        onClose={() => {
          setIsOpenModalConfirmLeaveForm(false);
          navigate("/role");
        }}
        onConfirm={() => {
          setIsOpenModalConfirmLeaveForm(false);
        }}
      />
    </>
  );
}
