import { Box, Button, Typography } from "@mui/material";
import CopyToClipboard from "components/Buttons/CopyToClipboard";
import React from "react";
import { toast } from "react-toastify";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

interface IProps {
  title: string;
  link: string;
  contactMonthly: number;
  totalContact: number;
}

const ReferralLinkForm: React.FC<IProps> = (props) => {
  const { title, link, contactMonthly, totalContact } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.contentPage}>
      <Box className={classes.wrapTitle}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box className={classes.wrapLinkAndButton}>
        <Box className={classes.wrapLink}>
          <Typography variant="body1" className={classes.textEllipsis}>
            {link}
          </Typography>
        </Box>
        <Button variant="contained" className={classes.copyButton}>
          <CopyToClipboard
            onCopy={() => {
              toast.success<void>(t("copied"));
            }}
            value={link}
          >
            {t("copy_link")}
          </CopyToClipboard>
        </Button>
      </Box>
      <Box className={classes.wrapNotification}>
        <Typography variant="body1">
          <span style={{ color: "#F2756F" }}>{contactMonthly}</span>&nbsp;{t("description_referral_link")}&nbsp;
          <span style={{ fontWeight: "bold" }}>{totalContact}</span>
          {")"}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReferralLinkForm;
