import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading() {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 999999 }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
