import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  btn: {
    backgroundColor: colors.btnPrimary,
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
}));
