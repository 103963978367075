import { makeStyles } from "tss-react/mui";
import iconUrgent from "../../assets/images/urgent.svg";

export const useStyles = makeStyles()(() => ({
  container: {
    borderRadius: "5px",
    border: "1px solid #4A4358",
    marginBottom: "10px",
    fontSize: "14px",
    color: "#4A4358",
  },
  containerHeader: {
    padding: "12px 5px 0 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 425px)": {
      flexDirection: "column",
      marginBottom: "10px",
    },
  },
  containerBody: {
    padding: "0 33px 0px 20px",
    "@media (max-width: 425px)": {
      padding: "0 5px 0px 10px",
    },
  },
  btnArrow: {
    width: "24px",
    height: "24px",
    marginTop: "-4px",
  },
  arrow: {
    color: "#4A4358",
    fontSize: "30px",
  },
  wrapRightHeader: {
    display: "flex",
    alignItems: "start",
    "@media (max-width: 425px)": {
      alignSelf: "flex-end",
    },
  },
  wrapTime: {
    textAlign: "right",
  },
  redColor: {
    color: "#F08377",
    fontWeight: 700,
  },
  btnPen: {
    width: "24px",
    height: "24px",
    marginLeft: "4px",
    marginTop: "-5px",
  },
  iconPen: {
    width: "18px",
    height: "18px",
    color: "#F08377",
  },
  wrapTitle: {
    display: "flex",
    alignItems: "center",
    "@media (max-width: 425px)": {
      alignSelf: "flex-start",
    },
  },
  resizeIconUrgent: {
    marginLeft: "10px",
    marginBottom: "15px",
  },
  title: {
    color: "#4A4358",
    fontWeight: 700,
    fontSize: "14px",
  },
  wrapHeaderCmt: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 425px)": {
      flexDirection: "column",
    },
  },
  hr: {
    border: "0.5px dashed #4A4358",
    marginBottom: "18px",
  },
  label: {
    minWidth: "85px",
  },
  labelTypeResult: {
    minWidth: "60px",
  },
  wrapTypeResult: {
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
  },
  wrapResult: {
    display: "flex",
    overflow: "auto",
    paddingBottom: "5px",
    maxWidth: "calc(100vw - 180px)",
    "@media (max-width: 425px)": {
      maxWidth: "calc(100vw - 115px)",
    },
  },
  wrapControl: {
    marginBottom: "18px",
    display: "flex",
    alignItems: "center",
  },
  labelDescription: {
    marginBottom: "18px",
  },
  contentDescription: {
    marginBottom: "18px",
    paddingLeft: "30px",
    whiteSpace: "pre-wrap",
  },
  addComment: {
    color: "#0973BA",
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "italic",
    cursor: "pointer",
    margin: "8px 0 18px 20px",
    "&:hover": {
      opacity: 0.8,
      transition: "ease-in 0.2s",
    },
  },
  spanContactLink: {
    color: "#456589",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "14px",
    "&: hover": {
      color: "#F08377",
      transition: "ease-in 0.2s",
    },
  },
  resultTextName: {
    fontSize: "14px",
    fontWeight: 700,
  },
  contentCmt: {
    marginLeft: "30px",
    marginBottom: "10px",
    pointerEvents: "none",
    "& img": {
      maxHeight: "200px",
      maxWidth: "fit-content",
    },
    "& ul, ol": {
      margin: 0,
      padding: 0,
    },
    "@media (max-width: 425px)": {
      "& img": {
        maxHeight: "100px",
      },
    },
  },
  wrapCmt: {
    maxHeight: "300px",
    overflow: "auto",
    padding: "0 33px 0px 20px",
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      height: "100%",
      border: "1px double hsl(0, 0%, 70%)",
    },
    "& th, td": {
      border: "1px double hsl(0, 0%, 70%)",
    },
    "@media (max-width: 425px)": {
      padding: "0 5px 0px 10px",
    },
  },
  wrapResultCollap: {
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    padding: "0 33px 0px 20px",
    "@media (max-width: 425px)": {
      maxWidth: "calc(100vw - 115px)",
      padding: "0 5px 0px 10px",
    },
  },
  nameResult: {
    fontWeight: 600,
    color: "#F08377",
  }, 
  iconMenu: {
    display: "block",
    width: "32px",
    height: "32px",
  },
  iconUrgent: {
    backgroundColor: "#e74c3c",
    maskImage: `url(${iconUrgent})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
  },
}));
