// CustomFormControl.tsx
import React from "react";
import { FormControl, FormControlLabel, FormGroup, FormLabel, Checkbox, SxProps, Theme } from "@mui/material";
import { Controller } from "react-hook-form";
import { LooseObject } from "models/common";
import colors from "utils/colors";

type CheckBoxFormControlProps = {
  name: string;
  label: string;
  data: LooseObject[];
  control: any;
  sx?: SxProps<Theme>;
};

const labelStyle = {
  fontSize: "14px",
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
  },
  fontFamily: "Roboto",
  fontWeight: 600,
  color: colors.defaultColorText + "!important",
};

const checkboxStyle = {
  "& .MuiSvgIcon-root": { fontSize: 24 },
  "& .MuiTypography-root": { fontSize: 14 },
  fontFamily: "Roboto",
  fontWeight: 600,
  color: colors.defaultColorText,
  "&.Mui-checked": {
    color: colors.defaultColorText,
  },
};

const CheckBoxFormControl: React.FC<CheckBoxFormControlProps> = ({ name, label, data, control, sx }) => {
  return (
    <FormControl component="fieldset" sx={sx}>
      <FormLabel component="legend" sx={labelStyle}>
        {label}
      </FormLabel>
      <FormGroup>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              {data.map((item: any) => (
                <FormControlLabel
                  sx={labelStyle}
                  key={item?.id}
                  label={item?.name}
                  control={
                    <Checkbox
                      sx={checkboxStyle}
                      value={parseInt(item?.id)}
                      onChange={(event, checked) => {
                        if (checked) {
                          field.onChange([...(field.value || []), parseInt(event.target.value)]);
                        } else {
                          field.onChange(
                            (field.value || []).filter((value: number) => value !== parseInt(event.target.value))
                          );
                        }
                      }}
                      checked={field?.value?.some((existingValue: number) => {
                        return existingValue === item.id;
                      })}
                    />
                  }
                />
              ))}
            </>
          )}
        />
      </FormGroup>
    </FormControl>
  );
};

export default CheckBoxFormControl;
