import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import SwitchButton from "components/Buttons/SwitchButton";
import Select from "components/Select";
import InputDate from "components/inputDate";
import dayjs from "dayjs";
import { LooseObject } from "models/common";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { STATUS_APPLICATION_STUDENT } from "utils/constant";
import * as Yup from "yup";
import BaseButton from "../../Buttons/BaseButton";
import { useStyles } from "./styles";

interface ModalApplicationProp {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  data: LooseObject;
  formValue?: LooseObject;
}

export default function ModalApplication({ isOpen, onClose, onConfirm, data, formValue }: ModalApplicationProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [listSwitch, setListSwitch] = useState<number[]>([]);
  const refBtnSubmit = useRef<HTMLButtonElement>(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("this_field_is_required"))
      .trim()
      .max(255, t("this_field_must_not_exceed_255_characters")),
    stage: Yup.object().required(t("this_field_is_required")),
    pic_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      }),
    note: Yup.string().trim().max(500, t("this_field_must_not_exceed_255_characters")),
    end_date: Yup.date()
      .required(t("this_field_is_required"))
      .typeError(t("date_is_invalid"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      }),
    application_policies: Yup.array().transform((cv, ov) => {
      return ov === "" ? undefined : cv;
    }),
  });

  type ModalForm = Yup.InferType<typeof validationSchema>;
  type KeyError = keyof ModalForm;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
    getValues,
  } = useForm<ModalForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (formValue) {
      let listPolicies: number[] = [];
      if (formValue.application_policies.length !== 0) {
        formValue.application_policies.forEach((item: LooseObject) => listPolicies.push(item.id));
      }
      Object.entries(formValue).map(([key, value]) => {
        if (Object.keys(validationSchema.fields).includes(key)) {
          if (key === "application_policies") {
            setValue("application_policies", listPolicies);
            return null;
          }
          setValue(String(key) as KeyError, value ?? "");
        }
        return null;
      });
      setListSwitch(listPolicies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  const handleSubmitModal = (form: ModalForm) => {
    const application = {
      name: form.name,
      contact_id: data?.contact?.id,
      stage: "id" in form.stage && form.stage.id,
      pic_id: form?.pic_id ?? "",
      note: form.note ?? "",
      end_date: moment(form.end_date).format("YYYY-MM-DD"),
      application_policies: form.application_policies ?? [],
    };
    if (formValue) {
      onConfirm(formValue.id, application);
    } else {
      onConfirm(application);
    }
  };

  const handleSwitch = (value: any) => {
    let list = getValues("application_policies") ?? [];
    if (list && list.includes(value)) {
      list = list.filter((item: any) => Number(item) !== Number(value));
    } else {
      list?.push(value);
    }
    setListSwitch(list);
    setValue("application_policies", list);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        scroll={"paper"}
      >
        <div className={classes.title}>
          <div className={classes.wrapIconTitle}>
            <DescriptionOutlinedIcon />
            <p>{formValue ? t("edit_application") : t("add_application")}</p>
          </div>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers className={classes.containerContent}>
          <div className={classes.formControl}>
            <p className={`${classes.label}`}>
              {`${t("contact")}: `}
              <span>*</span>
            </p>
            <input className={classes.input} type="text" value={data?.contact?.full_name} disabled />
          </div>
          <form onSubmit={handleSubmit(handleSubmitModal)} noValidate>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>
                {`${t("application_name")}: `}
                <span>*</span>
              </p>
              <input className={classes.input} type="text" {...register("name")} />
            </div>
            <div className={classes.error}>{errors["name"]?.message}</div>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>
                {`${t("status")}: `}
                <span>*</span>
              </p>
              <Select
                options={STATUS_APPLICATION_STUDENT}
                className={classes.inputSelect}
                nameRegister={"stage"}
                control={control}
                isForm
                isObjectSubmit
              />
            </div>
            <div className={classes.error}>{errors["stage"]?.message}</div>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>
                {`${t("ended_date")}: `}
                <span>*</span>
              </p>
              <InputDate
                control={control}
                nameRegister={"end_date"}
                resetField={resetField}
                minDate={dayjs(new Date()).add(1, "day")}
              />
            </div>
            <div className={classes.error}>{errors["end_date"]?.message}</div>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>{`${t("pic")}: `}</p>
              <Select
                options={data?.users}
                className={classes.inputSelect}
                nameRegister={"pic_id"}
                control={control}
                isForm
              />
            </div>
            <div className={classes.error}>{errors["pic_id"]?.message}</div>
            <div className={classes.formControl}>
              <p className={`${classes.label}`}>{`${t("note")}: `}</p>
              <textarea rows={7} className={classes.inputArea} {...register("note")}></textarea>
            </div>
            <div className={classes.error}>{errors["note"]?.message}</div>
            <div className={classes.error}>{errors["application_policies"]?.message}</div>
            <div className={classes.titleQuestion}>{t("application_list")}</div>
            {data.questions.map(
              (item: LooseObject, index: number) =>
                item.type === 0 && (
                  <div className={classes.wrapItemQuestion} key={`${index} - b`}>
                    <div dangerouslySetInnerHTML={{ __html: item.name }} />
                    <SwitchButton
                      checked={listSwitch.includes(item.id)}
                      onClick={handleSwitch}
                      className={classes.btnSwitch}
                      valueItem={item.id}
                    />
                  </div>
                )
            )}
            <div className={classes.titleQuestion}>{t("fees")}</div>
            {data.questions.map(
              (item: LooseObject, index: number) =>
                item.type === 1 && (
                  <div className={classes.wrapItemQuestion} key={`${index} - c`}>
                    <div dangerouslySetInnerHTML={{ __html: item.name }} />
                    <SwitchButton
                      checked={listSwitch.includes(item.id)}
                      onClick={handleSwitch}
                      className={classes.btnSwitch}
                      valueItem={item.id}
                    />
                  </div>
                )
            )}
            <button type="submit" className={classes.hidden} ref={refBtnSubmit}></button>
          </form>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={t("cancel")} className={classes.btnNo} onClick={() => onClose()} />
          <BaseButton
            title={t("save")}
            className={classes.btnYes}
            onClick={() => refBtnSubmit.current && refBtnSubmit.current.click()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
