import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { LooseObject } from "models/common";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "../../Buttons/BaseButton";
import { useStyles } from "./styles";
interface ModalChangeColumnsProp {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  column: LooseObject;
  isMaster?: boolean;
}

export default function ModalChangeColumns({ isOpen, onClose, onConfirm, column, isMaster }: ModalChangeColumnsProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [items, setItems] = useState(column);

  const removeFromList = (list: any, index: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };

  const addToList = (list: any, index: any, element: any) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const listCopy: any = { ...items };
    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(sourceList, result.source.index);
    listCopy[result.source.droppableId] = newSourceList;

    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(destinationList, result.destination.index, removedElement);
    setItems(listCopy);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <div className={classes.title}>
          <p>{t("choose_columns")}</p>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers className={classes.dialogContent}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={classes.wrapColumn}>
              <div className={classes.wrapColumnLeft}>
                <p className={classes.headerColunm}>{t("displayed")}</p>
                <div className={classes.column}>
                  <Droppable droppableId={"displayed"}>
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                      <div ref={provided.innerRef} className={classes.wrapDropable}>
                        {items.displayed &&
                          items.displayed.map((item: any, index: number) => (
                            <Draggable key={item} draggableId={item + ""} index={index}>
                              {(provided: DraggableProvided | any, snapshot: DraggableStateSnapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <div className={classes.itemLeft}>
                                    <p>{isMaster && item === "aspiration_1" ? t("aspiration") : t(item)}</p>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
              <div className={classes.wrapColumnRight}>
                <p className={classes.headerColunm}>{t("hidden")}</p>
                <div className={classes.column}>
                  <Droppable droppableId={"hidden"}>
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                      <div ref={provided.innerRef} className={classes.wrapDropable}>
                        {items.hidden &&
                          items.hidden.map((item: any, index: number) => (
                            <Draggable draggableId={String(item)} index={index} key={item}>
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <div className={classes.itemRight}>
                                    <p>{isMaster && item === "aspiration_1" ? t("aspiration") : t(item)}</p>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </div>
          </DragDropContext>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={t("close")} className={classes.btnNo} onClick={() => onClose()} />
          <BaseButton title={t("save_changes")} className={classes.btnYes} onClick={() => onConfirm(items)} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
