import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LooseObject } from "models/common";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { getUserInfo } from "redux/auth/auth.service";

export interface notificationState {
  list: LooseObject[];
  newNotify: number;
}

const initialState: notificationState = {
  list: [],
  newNotify: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllNotification.fulfilled, (state, action) => {
      const data: LooseObject[] = action.payload.data;
      state.list = data
      if (Array.isArray(data) && data.length > 0) {
        let notify: number = 0;
        data.forEach((item: LooseObject) => {
          if (!item?.read_at) {
            notify += 1;
          }
        });
        state.newNotify = notify;
      }
    });
  },
});

export const getAllNotification = createAsyncThunk(
  "notification/getAllNotification",
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    try {   
      const userInfor = getUserInfo();
      const response = await axiosClient.get(API.notifications, { params: { author_id: userInfor?.user?.id, limit: '*' } });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNotifyById = (id: any) => async () => {
  try {
    const url: string = `${API.notifications}/${id}`;
    const response = await axiosClient.get(url);

    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default notificationSlice;
