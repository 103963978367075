import { Typography } from "@mui/material";
import React from "react";

type IProps = {
  time: number;
};

const Timer: React.FC<IProps> = (props) => {
  const { time } = props;
  const [sec, setSec] = React.useState(0);
  const [min, setMin] = React.useState(0);

  const makeTimeForm = (time: number): void => {
    if (time < 60) {
      setMin(0);
      setSec(time);
    } else {
      let min = Math.floor(time / 60);
      let sec = time - min * 60;
      setSec(sec);
      setMin(min);
    }
  };

  React.useEffect((): void => {
    makeTimeForm(time);
  }, [time]);

  return (
    <>
      <Typography variant="h5">
        {min > 9 ? min : "0" + min}:{sec > 9 ? sec : "0" + sec}
      </Typography>
    </>
  );
};

export default Timer;
