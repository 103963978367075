import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    textTransform: "none",
    fontSize: "14px",
    borderRadius: "5px",
    height: "30px",
    lineHeight: 1,
    boxShadow: "none",
    fontFamily: "Roboto",
    whiteSpace: "nowrap",
    "&:hover": {
      opacity: 0.8,
      transition: "ease-in 0.2s",
    },
    "&:disabled": {
      backgroundColor: "#CCC",
      color: "#FFFFFF",
    }
  },
}));
