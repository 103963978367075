import { useStyles } from "./styles";

export default function Card(props: any) {
  const { classes } = useStyles();
  const { title, children, titleText } = props;
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <p>{title}</p>
      </div>
      <div className={classes.content}>
        {titleText && <div className={classes.titleText}>{titleText}</div>}
        {children}
      </div>
    </div>
  );
}
