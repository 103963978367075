import { yupResolver } from "@hookform/resolvers/yup";
import { Close, WhatsApp } from "@mui/icons-material";
import { DialogActions, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BaseButton from "components/Buttons/BaseButton";
import AutoCompleteForm from "components/Forms/AutoCompleteForm";
import InputTextForm from "components/Forms/InputTextForm";
import SelectForm from "components/Forms/SelectForm";
import ModalConfirmLeaveForm from "components/ModalConfirmLeaveForm";
import { isEmpty } from "lodash";
import { SelectDataProp } from "models/common";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/store";
import contactCategorySlice, {
  contactCategoryListWithoutCurrentContactIdSelector,
  fetchContactCategoriesWithoutCurrentContactId,
} from "store/contactCategorySlice";
import { contactPointsForSelectedSelector, getContactsFromQueryAndCategory } from "store/contactPointSlice";
import querySlice, { fetchQuery, querySelector } from "store/querySlice";
import {
  contentSmsSelector,
  detailSmsTemplate,
  getListSmsTemplate,
  listSmsTemplateSelector,
  sendSmsToContactUser,
} from "store/sendSmsAndEmailSlice";
import {
  MASTER_CONTACT_TYPE,
  STUDENT_CONTACT_TYPE,
  convertToArrayId,
  renderListQueriesAndCategoriesParams,
  uniqueTwoArray,
} from "utils";
import * as Yup from "yup";
import { useStyles } from "./styles";

interface IProps {
  isOpen: boolean;
  onClose: Function;
  dataSendSms: SelectDataProp[];
  isMasterModal?: boolean;
  listId?: number[];
}

export default function ModalSendSms({ isOpen, onClose, isMasterModal, dataSendSms, listId }: IProps) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch<any>(
      contactCategorySlice.actions.setTypeContactCategory(isMasterModal ? MASTER_CONTACT_TYPE : STUDENT_CONTACT_TYPE)
    );
    dispatch<any>(fetchContactCategoriesWithoutCurrentContactId());
    dispatch<any>(querySlice.actions.setTypeContact(isMasterModal ? MASTER_CONTACT_TYPE : STUDENT_CONTACT_TYPE));
    dispatch<any>(fetchQuery());
  }, [dispatch, isMasterModal]);
  const listCategories = useSelector(contactCategoryListWithoutCurrentContactIdSelector);
  const listQueries = useSelector(querySelector);

  const smsContent = useSelector(contentSmsSelector);
  const listTemplates = useSelector(listSmsTemplateSelector);
  const contacts = useSelector(contactPointsForSelectedSelector);

  const [isOpenModalConfirmLeaveForm, setIsOpenModalConfirmLeaveForm] = useState<boolean>(false);

  const { t } = useTranslation();
  const { classes } = useStyles();

  const validationSchema = Yup.object().shape({
    contact_ids: Yup.array().min(1, t("this_field_is_required")),
    content: Yup.string().required(t("this_field_is_required")),
    current_query: Yup.object().notRequired(),
    sms_template_id: Yup.string().notRequired(),
    contact_category_ids: Yup.array().notRequired(),
    contact_query_ids: Yup.array().notRequired(),
  });

  type FilterSubmitForm = Yup.InferType<typeof validationSchema>;
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<FilterSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      contact_ids: [],
      content: "",
      current_query: {},
      sms_template_id: "",
      contact_category_ids: [],
      contact_query_ids: [],
    },
  });

  const content = watch("content");
  const smsTemplateId = watch("sms_template_id");
  const selectedQuery = watch("contact_query_ids");
  const selectedListContact = watch("contact_category_ids");

  const convertToListParams = renderListQueriesAndCategoriesParams(
    convertToArrayId(selectedQuery ?? []),
    convertToArrayId(selectedListContact ?? [])
  );

  const getContacts = useMemo(() => {
    return async (listParams: any) => {
      await dispatch(getContactsFromQueryAndCategory(listParams));
    };
  }, [dispatch]);

  useEffect(() => {
    getContacts(convertToListParams);
  }, [convertToListParams, getContacts]);

  const contactsShorten = useMemo(() => {
    return contacts.map((item: any) => ({ id: item.id, name: item.phone_number }));
  }, [contacts]);

  const mergeContacts = useMemo(() => {
    return uniqueTwoArray(contactsShorten, dataSendSms);
  }, [contactsShorten, dataSendSms]);

  const filterEmptyPhoneNumber = useMemo(() => {
    return mergeContacts?.filter((item: any) => !isEmpty(item.name));
  }, [mergeContacts]);

  useEffect(() => {
    if (!isEmpty(dataSendSms)) {
      reset();
    }
  }, [dataSendSms, reset]);

  useEffect(() => {
    setValue("contact_ids", listId && listId.length > 0 ? listId : filterEmptyPhoneNumber);
    setValue("content", smsContent ?? "");
  }, [dataSendSms, filterEmptyPhoneNumber, setValue, smsContent, listId]);

  const getContentSmsTemplate = useMemo(() => {
    return async (templateId: any) => {
      await dispatch(detailSmsTemplate(templateId));
      await dispatch(getListSmsTemplate());
    };
  }, [dispatch]);

  useEffect(() => {
    getContentSmsTemplate(smsTemplateId);
  }, [getContentSmsTemplate, smsTemplateId]);

  const handleClose = () => {
    if (isDirty) {
      return setIsOpenModalConfirmLeaveForm(true);
    }
    onClose();
  };

  const onSubmitSms = async (data: FilterSubmitForm) => {
    if (data) {
      const dataSubmit = {
        ...data,
        contact_ids: listId && listId.length > 0 ? listId : convertToArrayId(data.contact_ids ?? []),
        contact_category_ids: convertToArrayId(data.contact_category_ids ?? []),
        contact_query_ids: convertToArrayId(data.contact_query_ids ?? []),
      };

      await dispatch(sendSmsToContactUser(dataSubmit));
    }

    toast.success<void>(t("send_sms_success"));
    reset();
    onClose();
  };

  const getErrorsValidate = (name: any) => {
    return errors[name as keyof typeof errors]?.message;
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ sx: { overflow: "hidden", height: "auto" } }}
      >
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
            <WhatsApp />
            <p>{t("send_sms")}</p>
          </div>
          <IconButton aria-label="close" onClick={handleClose} className={classes.btnClose}>
            <Close />
          </IconButton>
        </div>

        <form>
          <DialogContent className={classes.containerContent}>
            <AutoCompleteForm
              limitTags={5}
              widthLabel={120}
              key={"contact_ids"}
              data={listId && listId.length > 0 ? listId : filterEmptyPhoneNumber}
              title={t("phone_number")}
              control={control}
              name="contact_ids"
              errors={getErrorsValidate("contact_ids")}
              isMultiple
              isRequired
              fullWidth
              showAmountContact
              isDisabled={listId && listId.length > 0}
              isHideTag={listId && listId.length > 0}
            />
            <AutoCompleteForm
              widthLabel={120}
              key={"contact_category_ids"}
              data={listCategories}
              title={t("select_list")}
              name="contact_category_ids"
              control={control}
              isMultiple
              fullWidth
            />
            <AutoCompleteForm
              widthLabel={120}
              key={"contact_query_ids"}
              data={listQueries}
              title={t("select_query")}
              control={control}
              name="contact_query_ids"
              isMultiple
              fullWidth
            />
            <SelectForm
              widthLabel={120}
              data={listTemplates}
              isRequired={false}
              title={t("select_sms_template")}
              control={control}
              name="sms_template_id"
              fullWidth
            />
            <InputTextForm
              isRequired
              widthLabel={120}
              title={t("content")}
              name={"content"}
              register={register}
              errors={getErrorsValidate("content")}
              type={"textarea"}
              showCountCharacters
              currentContent={content}
              fullWidth
              amountContact={filterEmptyPhoneNumber.length}
            />
          </DialogContent>
          <DialogActions className={classes.footer}>
            <BaseButton title={t("cancel")} className={classes.btnCancel} onClick={handleClose} />
            <BaseButton
              title={t("send_sms")}
              className={classes.btnSearch}
              isSubmit={false}
              onClick={handleSubmit(onSubmitSms)}
            />
          </DialogActions>
        </form>
      </Dialog>
      <ModalConfirmLeaveForm
        isOpen={isOpenModalConfirmLeaveForm}
        onClose={() => {
          setIsOpenModalConfirmLeaveForm(false);
          reset();
          onClose();
        }}
        onConfirm={() => {
          setIsOpenModalConfirmLeaveForm(false);
        }}
      />
    </div>
  );
}
