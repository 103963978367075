import { Switch } from "@mui/material";
import { useStyles } from "./styles";

interface SwitchButtonProp {
  checked: boolean;
  onClick: Function;
  className?: string;
  valueItem?: any;
}

export default function SwitchButton({ checked, onClick, className, valueItem }: SwitchButtonProp) {
  const { classes } = useStyles();
  return (
    <Switch
      checked={checked}
      className={`${className} ${classes.container}`}
      onClick={() => onClick(valueItem && valueItem)}
      value={valueItem}
    />
  );
}
