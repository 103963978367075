import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LooseObject, IFilter } from "models/common";
import { RootState } from "redux/store";
import { axiosClient } from "services/axiosClient";
import { API } from "utils/api";

interface RolesState {
  filters: IFilter,
  roles: LooseObject[];
  loading: boolean;
  role: {};
  error: any
}

interface IRoleUpdateOrCreate {
  id?: number;
  name: string;
  permissions: number[];
}

export interface IGetRoleData {
  name: string;
  permissions: LooseObject[];
}

export const getRoles = createAsyncThunk(
  "roles/getRoles",
  async (filters: IFilter) => {
    const paramsData = { page: filters.page ?? 1, limit: filters.limit ?? 10 };
    const url: string = API.restfulRoles;

    const response = await axiosClient.get(url, {
      params: { ...paramsData },
    });
    const data = response.data.data;
    return data;
  }
);

export const createRole = createAsyncThunk(
  "roles/createRole", async(data: IRoleUpdateOrCreate, { fulfillWithValue, rejectWithValue, dispatch}) => {
    dispatch(resetRoleStateError());
    const url: string = API.restfulRoles;
    return await axiosClient.post(url, { ...data }).then(response => fulfillWithValue(response?.data?.data))
    .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
);

export const updateRole = createAsyncThunk(
  "roles/updateRole", async(data: IRoleUpdateOrCreate, { fulfillWithValue, rejectWithValue, dispatch}) => {
    dispatch(resetRoleStateError());
    const url: string = `${API.restfulRoles}/${data.id}`;
    const dataParams = {
      name: data.name,
      permissions: data.permissions
    }
    return await axiosClient.put(url, { ...dataParams }).then(response => fulfillWithValue(response?.data?.data))
    .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
);

export const findRoleById = createAsyncThunk("roles/findRoleById", async (id: number, { fulfillWithValue, rejectWithValue}) => {
  const url: string = `${API.restfulRoles}/${id}`;
  return await axiosClient.get(url).then(response => fulfillWithValue(response?.data?.data))
    .catch(response => rejectWithValue(response?.response?.data?.errors))
});

const initialState: RolesState = {
  filters:{
    page: 1,
    limit: 10
  },
  roles: [],
  loading: false,
  role: {},
  error: null
};

const roleSliceV2 = createSlice({
  name: "roles",
  initialState,
  reducers: {
    resetRoleStateError: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state) => {      
      state.loading = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
      state.loading = false;
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createRole.rejected, (state, action) => {
      state.error = action.payload;
    })
    builder.addCase(updateRole.rejected, (state, action) => {
      state.error = action.payload;
    })
    builder.addCase(findRoleById.fulfilled, (state, action) => {
      state.role = action.payload;
    })
  }
});

export default roleSliceV2;
export const {resetRoleStateError} = roleSliceV2.actions;
export const roleListSelector = (state: any) => state.rolesV2?.roles;
export const roleSelector = (state: RootState) => state.rolesV2?.role;
export const loadingRoleSelector = (state: any) => state.rolesV2?.loading;
export const errorRoleSelector = (state: any) => state.rolesV2?.error;