import { Box, CircularProgress, Grid } from "@mui/material";
import CheckBoxFormControl from "components/Forms/CheckBoxFormControl";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "redux/store";
import { getPermissions, permisisonsGroupSelector } from "store/permissionSlice";
import { useStyles } from "./styles";

interface PermissionFormProps {
  control: any;
  name?: string;
  isDisabled?: boolean;
}

export default function PermissionForm({ control, name = "permissions", isDisabled = false }: PermissionFormProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const permissions = useSelector(permisisonsGroupSelector);
  const { classes } = useStyles();

  const getDataListPermission = useCallback(async () => {
    setLoading(true);
    await dispatch(getPermissions());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    getDataListPermission();
  }, [getDataListPermission]);

  return (
    <>
      {loading && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
      <Grid aria-disabled={isDisabled} className={isDisabled ? classes.isDisabled : ""} container spacing={2}>
        <Grid item xs={4}>
          <CheckBoxFormControl
            name={name}
            label={permissions?.student_contact?.name}
            data={permissions?.student_contact?.data || []}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <CheckBoxFormControl
            name={name}
            label={permissions?.master_contact?.name}
            data={permissions?.master_contact?.data || []}
            control={control}
          />
        </Grid>
        <Grid item xs={4} sx={{ display: "flow" }}>
          <CheckBoxFormControl
            name={name}
            label={permissions?.student_list?.name}
            data={permissions?.student_list?.data || []}
            control={control}
            sx={{ display: "flex", mb: "10px" }}
          />
          <CheckBoxFormControl
            name={name}
            label={permissions?.master_list?.name}
            data={permissions?.master_list?.data || []}
            control={control}
            sx={{ display: "flex", mb: "10px" }}
          />
          <CheckBoxFormControl
            name={name}
            label={permissions?.user?.name}
            data={permissions?.user?.data || []}
            control={control}
            sx={{ display: "flex", mb: "10px" }}
          />
          <CheckBoxFormControl
            name={name}
            label={permissions?.role?.name}
            data={permissions?.role?.data || []}
            control={control}
            sx={{ display: "flex" }}
          />
          <CheckBoxFormControl
            name={name}
            label={permissions?.online_admission?.name}
            data={permissions?.online_admission?.data || []}
            control={control}
            sx={{ display: "flex" }}
          />
          <CheckBoxFormControl
            name={name}
            label={permissions?.reminder_management?.name}
            data={permissions?.reminder_management?.data || []}
            control={control}
            sx={{ display: "flex" }}
          />
          <CheckBoxFormControl
            name={name}
            label={permissions?.report?.name}
            data={permissions?.report?.data || []}
            control={control}
            sx={{ display: "flex" }}
          />
        </Grid>
      </Grid>
    </>
  );
}
