import AddIcon from "@mui/icons-material/Add";
import BaseButton from "components/Buttons/BaseButton";
import CardCollapse from "components/CardCollapse";
import CardInfor from "components/CardInfor";
import Loading from "components/Loading/Loading";
import ModalApplication from "components/Modal/ModalApplication";
import ModalConfirm from "components/Modal/ModalConfirm";
import Paginate from "components/Pagination";
import PagingDetail from "components/PagingDetail";
import RoleBaseComponent from "components/RoleBaseComponent";
import { LooseObject } from "models/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { hasPermissions } from "redux/auth/auth.service";
import { RootState, useAppDispatch } from "redux/store";
import {
  addApplication,
  deleteApplication,
  editApplication,
  getApplications,
  getApplicationsQuestion,
  resetDataApplication,
  setPage,
  setPageSize,
} from "store/applicationSlice";
import { setSelectedTab } from "store/detailStudentContactSlice";
import { getAllUser } from "store/userSlice";
import { useContextIsFilterStudent } from "..";
import { useStyles } from "./styles";

export default function Application() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const currentContact = useSelector((state: RootState) => state.detailStudentContact.contact);
  const [contact, setContact] = useState<LooseObject>({});
  const applications = useSelector((state: RootState) => state.applications.applications);
  const total = useSelector((state: RootState) => state.applications.total);
  const pageSize = useSelector((state: RootState) => state.applications.limit);
  const page = useSelector((state: RootState) => state.applications.page);
  const [limit, setLimit] = useState<number>(pageSize);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [data, setData] = useState<LooseObject[]>([]);
  const [isShowModalAddApplication, setIsShowModalAddApplication] = useState<boolean>(false);
  const [isShowModalEditApplication, setIsShowModalEditApplication] = useState<boolean>(false);
  const [isOpenModalConfirmDelete, setIsOpenModalConfirmDelete] = useState<boolean>(false);
  const [currentApplication, setCurrentApplication] = useState<LooseObject>({});
  const [users, setUser] = useState<LooseObject[]>([]);
  const questions = useSelector((state: RootState) => state.applications.questions);
  const [isFilter, setIsFilter] = useContextIsFilterStudent();

  useEffect(() => {
    if (state?.isFilter) {
      setIsFilter(state?.isFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getData = async () => {
    setLoading(true);
    let response = await dispatch(getApplications({ id: id, page: page }));
    if (response.meta.requestStatus === "rejected") {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    dispatch(setSelectedTab("application"));
    return () => {
      dispatch(resetDataApplication());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(applications);
  }, [applications]);

  useEffect(() => {
    if (currentContact) {
      setContact(currentContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContact]);

  const fetchData = async () => {
    setLoading(true);
    setCurrentPage(page);
    navigate(`?page=${page}`, { state: { isFilter: isFilter } });
    await dispatch(getApplications({ id: id, page: page }));
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, page, pageSize]);

  const handlePageClick = (page: number = 1) => {
    setCurrentPage(page);
    navigate(`?page=${page}`, { state: { isFilter: isFilter } });
    setLoading(true);
    dispatch(getApplications({ id: id, page: page }));
    setLoading(false);
  };

  const handleOpenModalAdd = async () => {
    if (!contact?.is_applied) {
      toast.warning<void>(t("only_contacts_with_an_applied_status_can_perform_this_action"));
      return;
    }
    setLoading(true);
    const response = await dispatch(getApplicationsQuestion());
    if (response.meta.requestStatus === "rejected") {
      toast.error<void>(t("system_error,_please_try_again_later"));
      return;
    }
    const user = await dispatch(getAllUser());
    if (!user) {
      toast.error<void>(t("system_error,_please_try_again_later"));
      return;
    }
    if (user) {
      setUser(user);
    }
    setIsShowModalAddApplication(true);
    setLoading(false);
  };

  const handleOpenModalEdit = async (item: LooseObject) => {
    setLoading(true);
    const response = await dispatch(getApplicationsQuestion());
    if (response.meta.requestStatus === "rejected") {
      toast.error<void>(t("system_error,_please_try_again_later"));
      return;
    }
    const user = await dispatch(getAllUser());
    if (!user) {
      toast.error<void>(t("system_error,_please_try_again_later"));
      return;
    }
    if (user) {
      setUser(user);
    }
    setCurrentApplication(item);
    setIsShowModalEditApplication(true);
    setLoading(false);
  };

  const handleOpenModalDelete = async (item: LooseObject) => {
    setLoading(true);
    setCurrentApplication(item);
    setIsOpenModalConfirmDelete(true);
    setLoading(false);
  };

  const handleAddApplication = async (form: LooseObject) => {
    setLoading(true);
    const result = await dispatch(addApplication(form));
    if (result) {
      toast.success<void>(t("add_application_successful"));
      setIsShowModalAddApplication(false);
      fetchData();
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  const handleEditApplication = async (id: any, form: LooseObject) => {
    setLoading(true);
    const result = await dispatch(editApplication(id, form));
    if (result) {
      toast.success<void>(t("edit_application_successful"));
      setIsShowModalEditApplication(false);
      setCurrentApplication({});
      fetchData();
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  const handleDeleteApplication = async () => {
    setLoading(true);
    const result = await dispatch(deleteApplication(currentApplication.id));
    if (result) {
      toast.success<void>(t("delete_application_successful"));
      setIsOpenModalConfirmDelete(false);
      setCurrentApplication({});
      fetchData();
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  return (
    <>
      <div className={classes.containerContent}>
        {loading && <Loading />}
        <CardCollapse
          title={t("deals")}
          className={classes.headerCard}
          childrens={
            <div>
              <RoleBaseComponent permissions={["student_contact_edit"]}>
                <BaseButton
                  className={classes.addBtn}
                  title={t("add_application")}
                  startIcon={<AddIcon className={classes.iconAdd} />}
                  onClick={() => handleOpenModalAdd()}
                />
              </RoleBaseComponent>
              {data.length !== 0 ? (
                <>
                  <div className={classes.content}>
                    {data.map((item) => (
                      <CardInfor
                        isShowDeleteBtn={hasPermissions(["student_contact_edit"])}
                        isShowEditBtn={hasPermissions(["student_contact_edit"])}
                        key={`${item.id} - a`}
                        title={item.name}
                        childrens={
                          <div>
                            <p className={classes.itemCard}>{`${t("created_date")}: ${item?.created_at}`}</p>
                            <p className={classes.itemCard}>{`${t("ended_date")}: ${item?.end_date}`}</p>
                            <p className={classes.itemCard}>
                              {"Status: "}
                              <span className={classes.status}>
                                {String(item?.stage?.name).charAt(0).toUpperCase() +
                                  String(item?.stage?.name).slice(1).toLowerCase()}
                              </span>
                            </p>
                          </div>
                        }
                        onClickEdit={() => handleOpenModalEdit(item)}
                        onClickDelete={() => handleOpenModalDelete(item)}
                      />
                    ))}
                  </div>
                  <Paginate
                    totalRecords={total}
                    limit={limit}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onChangeLimit={(limit: number) => {
                      setLimit(limit);
                    }}
                    onChangePage={(page: number) => {
                      handlePageClick(page);
                    }}
                    onSetPageSize={(data: number) => dispatch(setPageSize(data))}
                  />
                </>
              ) : (
                <div className={classes.content}>
                  <div className={classes.noData}>{t("no_data_found")}</div>
                </div>
              )}
            </div>
          }
        />
      </div>
      <PagingDetail
        current={contact?.index + 1}
        total={contact?.total}
        onClickBack={() => navigate("/studentContact")}
        onClickPrevious={() => {
          dispatch(setPage(1));
          navigate(`/studentContact/${contact?.prev_id}/application`, { state: { isFilter: isFilter } });
        }}
        onClickNext={() => {
          dispatch(setPage(1));
          navigate(`/studentContact/${contact?.next_id}/application`, { state: { isFilter: isFilter } });
        }}
      />

      {isShowModalAddApplication && (
        <ModalApplication
          isOpen={isShowModalAddApplication}
          onClose={() => setIsShowModalAddApplication(false)}
          onConfirm={handleAddApplication}
          data={{ users: users, questions: questions, contact: contact }}
        />
      )}
      {isShowModalEditApplication && (
        <ModalApplication
          isOpen={isShowModalEditApplication}
          onClose={() => setIsShowModalEditApplication(false)}
          onConfirm={handleEditApplication}
          data={{ users: users, questions: questions, contact: contact }}
          formValue={currentApplication}
        />
      )}
      {isOpenModalConfirmDelete && (
        <ModalConfirm
          isOpen={isOpenModalConfirmDelete}
          title={t("confirmation")}
          htmlContent={
            <p>
              {t("are_you_sure_to_remove")} <span className={classes.nameDelete}>{`${currentApplication.name}?`}</span>
            </p>
          }
          onClose={() => {
            setIsOpenModalConfirmDelete(false);
          }}
          onConfirm={() => handleDeleteApplication()}
        />
      )}
    </>
  );
}
