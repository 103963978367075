import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useStyles } from "./styles";

interface CardCollapseProp {
  title: string;
  startIconTitle?: any;
  childrens?: JSX.Element;
  className?: string;
  iconTittle?: JSX.Element;
  initShow?: boolean;
}

export default function CardCollapse({ title, childrens, className, iconTittle, initShow }: CardCollapseProp) {
  const { classes } = useStyles();
  initShow = initShow === null || initShow === undefined ? true : initShow;
  const [isShowChildren, setIsShowChildren] = useState<boolean>(initShow);
  return (
    <div className={classes.container}>
      <div className={`${classes.containerHeader} ${className}`}>
        <div className={classes.title}>
          {iconTittle}
          {title}
        </div>
        <IconButton onClick={() => setIsShowChildren(!isShowChildren)}>
          {isShowChildren ? (
            <ArrowDropDownIcon className={classes.arrow} />
          ) : (
            <ArrowDropUpIcon className={classes.arrow} />
          )}
        </IconButton>
      </div>
      {isShowChildren && childrens}
    </div>
  );
}
