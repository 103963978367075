import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  container: {
    backgroundColor: colors.backgroundLight,
    padding: "16px 30px",
    "& p": {
      marginBottom: "16px",
    }
  },
  explainStep: {
    fontWeight: 500,
    fontSize: "14px",
    marginTop: 0,
  },
  labelStep: {
    fontSize: "18px",
    fontWeight: 500,
  },
  downloadTemplate: {
    color: "#F08377",
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginTop: "16px",
  },
  fileUpload: {
    fontWeight: 400,
    fontSize: "14px",
  },
  warningFile: {
    fontWeight: 400,
    fontSize: "10px",
    marginTop: "16px",
  },
  question: {
    fontWeight: 400,
    fontSize: "14px",
  },
  note: {
    color: "#F2756F",
    fontWeight: 500,
    fontSize: "14px",
    marginTop: 0,
  },
  infor: {
    width: "15px",
    height: "15px",
    color: "#1C4CA1",
    marginLeft: "6px",
  },
  btnInputFile: {
    width: "190px",
    height: "32px",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4A4358",
    fontSize: "14px",
    fontWeight: 400,
    color: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.25)",
    marginRight: "10px",
    "& input[type='file']": {
      display: "none",
    },
    "&:hover": {
      opacity: 0.8,
      transition: "ease-in 0.2s",
    },
  },
  hr: {
    margin: 0,
    borderTop: "1px solid #D3D3D3"
  },
  btnNext: {
    backgroundColor: "#6A7A83",
    "&:hover": {
      backgroundColor: "#6A7A83"
    }
  },
  wrapbtnInput: {
    height: "32px",
    display: "flex",
    alignItems: "center",
  },
  iconDelete: {
    color: "red",
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "5px",
  },
  linkDownload: {
    textDecoration: "none",
  }
}));
