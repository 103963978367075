import { LoadingButton } from "@mui/lab";
import { useStyles } from "./styles";
import { Tooltip } from "@mui/material";

interface ButtonProp {
  title: string;
  startIcon?: JSX.Element | null;
  endIcon?: JSX.Element | null;
  icon?: JSX.Element | null;
  onClick?: Function;
  className?: string;
  isSubmit?: boolean;
  loading?: boolean;
  isDisabled?: boolean;
  sx?: any;
  showToolTip?: boolean;
  textToolTip?: string;
}

export default function BaseButton({
  title,
  startIcon,
  endIcon,
  onClick,
  className,
  isSubmit,
  loading,
  isDisabled,
  sx,
  icon,
  showToolTip,
  textToolTip,
}: ButtonProp) {
  const { classes } = useStyles();
  return (
    <Tooltip enterTouchDelay={0} title={showToolTip ? textToolTip : ""}>
      <LoadingButton
        variant="contained"
        className={`${classes.container} ${className}`}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={() => onClick && onClick()}
        type={isSubmit ? "submit" : "button"}
        loading={loading}
        disabled={isDisabled ? isDisabled : false}
        sx={sx}
      >
        {title}
        {icon}
      </LoadingButton>
    </Tooltip>
  );
}
