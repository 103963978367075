import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  title: {
    textTransform: "none",
    color: "#63727C",
    fontWeight: 700,
    fontFamily: "Roboto",
    paddingLeft: 0,
    "& .MuiMenuItem-root": {
      fontWeight: 400,
      fontFamily: "Roboto",
      fontSize: "14px",
    },
  },
  menuTop: {
    "& .MuiMenu-list": {
      paddingTop: 0,
    },
  },
  menuItem: {
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: "14px",
    minWidth: "100px",
    height: "25px",
    maxWidth: "250px",
  },
  checkbox: {
    marginRight: "5px",
  },
  search: {
    minWidth: "200px",
    width: "-webkit-fill-available",
    "& .MuiOutlinedInput-root": {
      height: "30px",
      fontSize: "14px",
      borderRadius: "4px",
      paddingRight: "6px",
    },
  },
  menuSearch: {
    backgroundColor: "#FFF",
    position: "sticky",
    top: 0,
    zIndex: 99999,
    padding: "12px 10px",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  subMenu: {
    margin: "5px 10px",
    color: "#1070bd",
  },
}));
