import { Box } from "@mui/material";
import BaseChart from "components/Charts";
import { colorsPaletteChart } from "utils/colors";

const labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
const option = {
  responsive: true,
  aspectRatio: 6,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: true,
    },
    y: {
      display: true,
      ticks: {
        beginAtZero: true,
      },
    },
  },
};

const StatisticChartByDistrict = () => {
  const data = [
    {
      data: labels.map(() => Math.ceil(Math.random() * 10) * 2000),
      borderColor: colorsPaletteChart.default,
      backgroundColor: colorsPaletteChart.default_opacity,
      borderWidth: 2,
      borderSkipped: false,
    },
  ];

  return (
    <Box>
      <Box>
        <Box sx={{ margin: "16px 0 30px 0" }}>
          <Box sx={{ my: "16px" }}>
            <BaseChart
              type="bar"
              datasets={data}
              labels={labels}
              options={option}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StatisticChartByDistrict;
