import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LooseObject } from "models/common";
import { RootState } from "redux/store";
import { axiosClient } from "services/axiosClient";
import { API } from "utils/api";

interface SendSmsAndEmailState {
  smsContent: any;
  contentEmail: any;
  subjectEmail: any;
  listSmsTemplate: LooseObject[];
  listEmailTemplate: LooseObject[];
}

const initialState: SendSmsAndEmailState = {
  smsContent: "",
  contentEmail: "",
  subjectEmail: "",
  listSmsTemplate: [],
  listEmailTemplate: [],
};

const sendSmsAndEmailSlice = createSlice({
  name: "sendSmsAndEmail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(detailSmsTemplate.fulfilled, (state, action) => {
      state.smsContent = action.payload;
    });
    builder.addCase(getListSmsTemplate.fulfilled, (state, action) => {
      state.listSmsTemplate = action.payload;
    });
    builder.addCase(detailEmailTemplate.fulfilled, (state, action) => {
      state.contentEmail = action.payload.content;
      state.subjectEmail = action.payload.subject;
    });
    builder.addCase(getListEmailTemplate.fulfilled, (state, action) => {
      state.listEmailTemplate = action.payload;
    });
  },
});

export const detailSmsTemplate = createAsyncThunk(
  "sendSmsAndEmail/detailSmsTemplate",
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = API.detailSmsTemplate(id);
      const response = await axiosClient.get(url);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data.content);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const detailEmailTemplate = createAsyncThunk(
  "sendSmsAndEmail/detailEmailTemplate",
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = API.detailEmailTemplate(id);
      const response = await axiosClient.get(url);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getListSmsTemplate = createAsyncThunk(
  "sendSmsAndEmail/getListSmsTemplate",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = API.getListSmsTemplate;
      const response = await axiosClient.get(url);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getListEmailTemplate = createAsyncThunk(
  "sendSmsAndEmail/getListEmailTemplate",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = API.getListEmailTemplate;
      const response = await axiosClient.get(url);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendSmsToContactUser = createAsyncThunk(
  "sendSmsAndEmail/sendSmsToContactUser",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = API.sendSms;
      const response = await axiosClient.post(url, data);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendEmailToContactUser = createAsyncThunk(
  "sendSmsAndEmail/sendEmailToContactUser",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = API.sendEmail;
      const response = await axiosClient.post(url, data);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export default sendSmsAndEmailSlice;

export const contentSmsSelector = (state: RootState) => state?.sendSmsAndEmail?.smsContent;
export const listSmsTemplateSelector = (state: RootState) => state?.sendSmsAndEmail?.listSmsTemplate;
export const listEmailTemplateSelector = (state: RootState) => state?.sendSmsAndEmail?.listEmailTemplate;
export const contentEmailSelector = (state: RootState) => state?.sendSmsAndEmail?.contentEmail;
export const subjectEmailSelector = (state: RootState) => state?.sendSmsAndEmail?.subjectEmail;
