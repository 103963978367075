import Loading from "components/Loading/Loading";
import UserForm from "components/UserForm";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AppDispatch } from "redux/store";
import { getUserById, updateUserById, userSelector } from "store/userSlice";

export default function UpdateUser() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    id ? dispatch(getUserById(id)) : navigate("/user");
    setIsLoading(false);
  }, [id, dispatch, navigate]);
  const user = useSelector(userSelector);

  const onSubmitHandle = async (data: any) => {
    setIsLoading(true);
    if (id) {
      const userId = parseInt(id);
      const response = await dispatch(updateUserById({ user: data, id: userId }));
      if (response?.meta?.requestStatus === "fulfilled") {
        toast.success<void>(t("update_user_successful"));
        navigate("/user");
      } else {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    }
    setIsLoading(false);
  };
  const { t } = useTranslation();

  return (
    <>
      {isLoading && <Loading />}
      <UserForm onSubmitData={onSubmitHandle} userData={user} isUpdateMode={true} />
    </>
  );
}
