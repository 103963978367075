import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    paddingBottom: "10px",
  },
  input: {
    height: "30px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 10px",
    width: "387px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  wrap: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 400,
    "& p": {
      marginRight: "60px",
      "& span": {
        color: "#F00",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start !important",
    },
  },
  label: {
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  containerPassword: {
    position: "relative",
    "& input": {
      paddingRight: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  btnHide: {
    position: "absolute",
    right: "6px",
    bottom: "-2px",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
}));
