import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  containerContent: {
    margin: "0 auto 16px",
    padding: "16px",
    maxWidth: "1200px",
    backgroundColor: colors.backgroundLight,
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      margin: "8px",
      padding: "8px",
    },
  },
  iconAdd: {
    fontSize: "29px !important",
    marginRight: "-4px",
  },
  headerCard: {
    backgroundColor: "#AB2523cc !important",
    borderRadius: "4px 4px 0 0 !important",
  },
  arrowDown: {
    color: colors.backgroundLight,
    fontSize: "30px",
  },
  formControl: {
    display: "flex",
    width: "100%",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    minWidth: "250px",
    paddingTop: "6px",
  },
  input: {
    width: "100%",
    height: "30px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
  },
  inputTable: {
    width: "50%",
    height: "30px",
    backgroundColor: colors.whiteF5,
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
  },
  inputArea: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.whiteF5,
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "5px 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
    resize: "none",
  },
  inputSelect: {
    width: "100%",
  },
  inputSelectPoper: {
    zIndex: 1000,
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    minHeight: "19px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "10px",
    },
  },
  containerCard: {
    marginBottom: "16px",
  },
  containerCardContent: {
    backgroundColor: colors.backgroundDark,
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    columnCount: 2,
    columnGap: "32px",
    backgroundColor: colors.backgroundDark,
    padding: "16px",
    overflowY: "auto",
    [theme.breakpoints.down("xl")]: {
      columnCount: 2,
    },
    [theme.breakpoints.down("md")]: {
      columnCount: 1,
    },
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  btnCancel: {
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "16px",
    height: "42px",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  btnCreate: {
    backgroundColor: "#AB2523",
    color: colors.backgroundLight,
    marginLeft: "16px",
    "&:hover": {
      backgroundColor: "#AB2523aa",
    },
  },
  labelRequired: {
    color: "#FF0000",
  },
  wrapValueItem: {
    width: "100%",
    breakInside: "avoid",
  },
  inputSelectStatus: {
    width: "70%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "5px 0 0 5px !important",
      },
    },
  },
  labelNote: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      minWidth: "120px",
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      marginRight: "10px",
    },
  },
  formControlNote: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    height: "100%",
    paddingLeft: "16px",
    boxSizing: "border-box",
    marginBottom: "19px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      paddingLeft: "0",
      marginTop: "6px",
    },
  },
  wrapValueItemNote: {
    width: "100%",
    height: "100%",
  },
  inputLogoPhoneNumber: {
    "& .logo-phonenumber .MuiOutlinedInput-root": {
      width: "70px !important",
    },
  },
  hidden: {
    display: "none",
  },
  btnsave: {
    alignSelf: "end",
    margin: "16px",
    width: "fit-content",
    height: "35px",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: 500,
    backgroundColor: "#AB2523",
    "&:hover": {
      backgroundColor: "#AB2523",
    },
  },
  tableContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
  },
  table: {
    maxWidth: "800px",
    "& td,tr": {
      lineHeight: 1,
      height: "35px",
    },
  },
  performance: {
    fontWeight: 600,
    padding: "5px",
  },
  conduct: {
    fontWeight: 600,
    padding: "5px",
  },
  grade: {
    fontWeight: 600,
  },
  semester: {
    fontWeight: 600,
    width: "160px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      width: "auto",
    },
  },
  grade10: {
    width: "60px",
    fontWeight: 600,
    backgroundColor: "#e88ca033",
    clipPath: "polygon(0% 0%, 70% 0, 100% 50%, 70% 100%, 0 100%)",
  },
  grade11: {
    width: "60px",
    fontWeight: 600,
    backgroundColor: "#dd577533",
    clipPath: "polygon(0% 0%, 70% 0, 100% 50%, 70% 100%, 0 100%)",
  },
  grade12: {
    width: "60px",
    fontWeight: 600,
    backgroundColor: "#f1cfd2",
    clipPath: "polygon(0% 0%, 70% 0, 100% 50%, 70% 100%, 0 100%)",
  },
  wrapSelectTable: {
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      padding: "25px 5px",
      width: "120px",
      "& .MuiOutlinedInput-root": {
        paddingRight: "12px !important",
      },
      "& .MuiAutocomplete-endAdornment": {
        right: "0 !important",
      },
    },
  },
  errorScore: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    height: "0px",
  },
}));
