import { useStyles } from "./styles";

interface HeaderCardProp {
  title: string;
  childrens?: JSX.Element;
  className?: string;
}

export default function HeaderCard({title, childrens, className}: HeaderCardProp) {
  const { classes } = useStyles();
  return (
    <div className={`${classes.container} ${className}`}>
      <p>{title}</p>
      {childrens}
    </div>
  );
}
