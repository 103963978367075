import { useStyles } from "./styles";
import { Autocomplete, Box, TextField, Typography, createFilterOptions, debounce } from "@mui/material";
import { SelectDataProp } from "models/common";
import ErrorMessageForm from "components/Forms/ErrorMessageForm";
import { Controller } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SelectFormProp {
  title?: any;
  data: SelectDataProp[];
  isRequired?: boolean;
  widthLabel?: number;
  register?: any;
  errors?: any;
  isMultiple?: boolean;
  name?: any;
  defaultValue?: any;
  control?: any;
  isGetDataByLabel?: boolean;
  maxHeight?: number;
  onChangeSearch?: ((value: any) => void) | undefined;
  onSearchApi?: ((value: any) => void) | undefined;
  isAddOption?: boolean;
  isLoading?: boolean;
  isSearchApi?: boolean;
  onChangeValue?: (value: any) => void;
  fullWidth?: boolean;
  limitTags?: number;
  showAmountContact?: boolean;
  label?: string;
  isDisabled?: boolean;
  isHideTag?: boolean;
}

export default function AutoCompleteForm({
  title,
  data,
  isRequired = false,
  widthLabel,
  errors,
  isMultiple = false,
  isAddOption = false,
  isSearchApi = false,
  onSearchApi = () => {},
  name,
  control,
  maxHeight,
  onChangeSearch,
  isLoading = false,
  onChangeValue,
  fullWidth,
  limitTags,
  showAmountContact,
  label,
  isDisabled,
  isHideTag,
}: SelectFormProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [options, setOptions] = useState(data ?? []);
  const filter = createFilterOptions();
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((nextValue: any) => onChangeSearch?.(nextValue), 1000),
    []
  );

  useEffect(() => {
    setIsLoadingSearch(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      setOptions(data);
    }
  }, [data]);

  return (
    <div className={classes.container}>
      <div className={classes.wrap}>
        <Box sx={{ width: widthLabel ?? 80, marginRight: "60px" }}>
          <p style={{ width: widthLabel ?? 80 }} className={classes.label}>
            {title} {isRequired && <span>*</span>}
          </p>
        </Box>
        <Box className={[fullWidth ? classes.flexGrow : "", classes.wrapInput].join(" ")}>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Autocomplete
                ListboxProps={{ style: { maxHeight: maxHeight ?? "auto" } }}
                loading={isLoadingSearch}
                limitTags={isHideTag ? 0 : limitTags}
                disableClearable
                filterSelectedOptions
                noOptionsText={t("no_options")}
                onChange={(e, newValue) => {
                  if (isAddOption) {
                    if (newValue?.inputValue) {
                      onChange({
                        name: newValue.inputValue,
                      });
                    } else if (Array.isArray(newValue)) {
                      let updatedData = newValue?.map((obj) => {
                        if (obj.hasOwnProperty("inputValue")) {
                          return {
                            ...obj,
                            name: obj.inputValue,
                          };
                        }
                        return obj;
                      });
                      const uniqueInputValues = new Set();
                      updatedData = updatedData.filter((obj) => {
                        if (obj.hasOwnProperty("inputValue")) {
                          if (!uniqueInputValues.has(obj.inputValue)) {
                            uniqueInputValues.add(obj.inputValue);
                            return true;
                          }
                        } else {
                          return true;
                        }
                        return false;
                      });
                      onChange(updatedData);
                      onChangeSearch && debounceSearch("");
                    }
                  } else {
                    onChange(newValue);
                    onChangeValue?.(newValue);
                  }
                }}
                options={[...options, ...value]}
                classes={{
                  option: classes.textField,
                  noOptions: classes.noOptions,
                }}
                className={classes.input}
                multiple={isMultiple}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    onChange={(e) => {
                      isSearchApi && setIsLoadingSearch(true);
                      debounceSearch(e.target.value);
                    }}
                    label={label}
                  />
                )}
                getOptionLabel={(option: any) => option.name}
                value={value}
                {...field}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                filterOptions={(options: any, params: any) => {
                  const filtered = filter(options, params);
                  if (isAddOption && !isLoadingSearch) {
                    const { inputValue } = params;
                    const isExisting = options.some((option: any) => inputValue === option.name);
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                      });
                    }
                  }

                  return filtered;
                }}
                disabled={isDisabled}
              />
            )}
          />
          {showAmountContact && (
            <Typography variant="caption" mt={1}>
              {t("all_contacts")}: {data.length}
            </Typography>
          )}
        </Box>
      </div>
      <ErrorMessageForm errors={errors} name={name} widthLabel={widthLabel} />
    </div>
  );
}
