import { Autocomplete, Popper, TextField, createFilterOptions, debounce } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ReactCountriesFlags from "react-countries-flags";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LooseObject } from "../../models/common";
import { useStyles } from "./styles";

interface SelectProp {
  isMultiple?: boolean;
  style?: LooseObject;
  className?: string;
  classNamePoper?: string;
  placeholder?: string;
  options: LooseObject[];
  isDisableSearch?: boolean;
  isForm?: boolean;
  nameRegister?: string;
  control?: any;
  data?: any;
  onchange?: Function;
  isObjectSubmit?: boolean;
  isSubOption?: boolean;
  onChangeSearch?: Function;
  isSearching?: boolean;
  disableClearable?: boolean;
  isDisabled?: boolean;
  isAddOption?: boolean;
  isFilterSelected?: boolean;
  disablePortal?: boolean;
  hiddenOptions?: any[];
  isHideTag?: boolean;
}

export default function Select({
  isMultiple,
  style,
  placeholder,
  options,
  className,
  classNamePoper,
  isDisableSearch,
  isForm,
  nameRegister,
  control,
  data,
  onchange,
  isObjectSubmit,
  isSubOption,
  onChangeSearch,
  isSearching,
  disableClearable,
  isDisabled,
  isAddOption,
  isFilterSelected,
  disablePortal,
  hiddenOptions,
  isHideTag
}: SelectProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const filter = createFilterOptions();
  const subOption: LooseObject[] = isSubOption ? options.flatMap((item) => item.options) : [];

  useEffect(() => {
    isSearching !== undefined && setIsLoading(isSearching);
  }, [isSearching]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((nextValue: any) => onChangeSearch?.(nextValue), 1000),
    []
  );

  const handleGetValue = (value: any) => {
    if (isMultiple) {
      return isObjectSubmit
        ? value
        : value.map((item: any) => options.find((element) => (element?.id ?? element?.value) === item));
    } else {
      return (
        options.find((option) =>
          isObjectSubmit
            ? (value?.id ?? value?.value) === (option?.id ?? option?.value)
            : value === (option?.id ?? option?.value)
        ) || null
      );
    }
  };

  const MyPopper = function (props: any) {
    return <Popper {...props} className={`${classes.poper} ${classNamePoper}`} placement="bottom-start" />;
  };

  const filterOptions = (options: any, params: any) => {
    if (!hiddenOptions) {
      const filtered = filter(options, params);
      return filtered;
    }
    const fieldOption = options.filter((option: LooseObject) => {
      return !hiddenOptions.includes(option?.id ?? option?.value);
    });
    return fieldOption;
  };

  return (
    <div className={`${classes.container} ${className}`}>
      {isForm && nameRegister ? (
        <Controller
          control={control}
          name={nameRegister}
          render={({ field: { ref, value, ...field }, fieldState: { error } }) => (
            <Autocomplete
              PopperComponent={MyPopper}
              value={handleGetValue(value)}
              {...field}
              loading={isLoading}
              loadingText={`${t("loading")}...`}
              onChange={(event, newValue, reason, details) => {
                if (String(reason) === "clear") {
                  field.onChange(newValue);
                } else if (Array.isArray(newValue)) {
                  field.onChange(
                    isObjectSubmit ? newValue : newValue.map((item: LooseObject) => item?.id ?? item?.value)
                  );
                } else {
                  field.onChange(
                    isObjectSubmit
                      ? newValue
                      : ("id" in newValue && newValue?.id) || ("value" in newValue && newValue?.value)
                  );
                }
                if (reason === "removeOption") {
                  onchange && onchange(nameRegister, details, String(reason));
                } else {
                  onchange && onchange(nameRegister, newValue, String(reason));
                }
              }}
              groupBy={(option) => option.nameGroup}
              fullWidth={true}
              blurOnSelect={isMultiple ? false : true}
              disableCloseOnSelect={isMultiple}
              sx={{ ...style }}
              noOptionsText={t("no_options")}
              classes={{
                option: classes.textField,
                noOptions: classes.noOptions,
              }}
              limitTags={isHideTag? 0 :20}
              multiple={isMultiple}
              disabled={isDisabled}
              options={isSubOption ? subOption || [] : options || []}
              getOptionLabel={(option) => option?.label ?? option?.name ?? option?.full_name ?? ""}
              filterSelectedOptions={isFilterSelected === false ? isFilterSelected : true}
              renderInput={(params: any) => {
                const { InputProps } = params;
                const { startAdornment, ...restInputProps } = InputProps;
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={placeholder}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: Boolean(isDisableSearch),
                    }}
                    inputRef={ref}
                    onChange={(event) => {
                      if (onChangeSearch) {
                        setIsLoading(true);
                        debounceSearch(event.target.value);
                      }
                    }}
                    InputProps={{
                      ...restInputProps,
                      startAdornment: (
                        <div
                          style={{
                            maxHeight: "350px",
                            overflowY: "auto",
                          }}
                        >
                          {startAdornment}
                        </div>
                      ),
                    }}
                  />
                );
              }}
              renderGroup={(params) => (
                <li key={params.key}>
                  <div className={classes.groupName}>{params.group}</div>
                  <ul className={classes.groupItem}>{params.children}</ul>
                </li>
              )}
              isOptionEqualToValue={(option, value) =>
                (option?.id ?? option?.value) === (value?.id ?? value?.value) ||
                (Number(option?.id) ?? Number(option?.value)) === (Number(value?.id) ?? Number(value?.value))
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={`${option.id} ${option.label}`}>
                    {option?.iso && (
                      <>
                        <ReactCountriesFlags isoCode={option?.iso} />
                        <span> &nbsp;</span>
                      </>
                    )}
                    {option.label ?? option.name ?? option.full_name ?? ""}
                  </li>
                );
              }}
              disableClearable={disableClearable}
              filterOptions={filterOptions}
            />
          )}
        />
      ) : (
        <Autocomplete
          PopperComponent={MyPopper}
          fullWidth={true}
          value={handleGetValue(data)}
          loading={isLoading}
          blurOnSelect={isMultiple ? false : true}
          disableClearable={disableClearable}
          disableCloseOnSelect={isMultiple}
          disablePortal={disablePortal === false ? false : true}
          sx={{ ...style }}
          noOptionsText={t("no_options")}
          classes={{ option: classes.textField, noOptions: classes.noOptions }}
          multiple={isMultiple}
          options={options}
          getOptionLabel={(option: any) => option?.label ?? option?.name ?? ""}
          filterSelectedOptions={isFilterSelected === false ? isFilterSelected : true}
          renderInput={(params: any) => {
            const { InputProps } = params;
            const { startAdornment, ...restInputProps } = InputProps;
            return (
              <TextField
                {...params}
                variant="outlined"
                placeholder={placeholder}
                inputProps={{
                  ...params.inputProps,
                  readOnly: Boolean(isDisableSearch),
                }}
                onChange={(event) => {
                  if (onChangeSearch) {
                    setIsLoading(true);
                    debounceSearch(event.target.value);
                  }
                }}
                InputProps={{
                  ...restInputProps,
                  startAdornment: (
                    <div
                      style={{
                        maxHeight: "350px",
                        overflowY: "auto",
                      }}
                    >
                      {startAdornment}
                    </div>
                  ),
                }}
              />
            );
          }}
          onChange={(event, value: LooseObject) => {
            if (isAddOption) {
              if (value?.inputValue) {
                onchange &&
                  onchange({
                    id: value.inputValue,
                    name: value.inputValue,
                  });
              } else if (Array.isArray(value)) {
                let updatedData = value?.map((obj: LooseObject) => {
                  if (obj.hasOwnProperty("inputValue")) {
                    return {
                      id: obj.inputValue,
                      name: obj.inputValue,
                    };
                  }
                  return obj;
                });
                onchange && onchange(updatedData);
              } else {
                onchange && onchange(value);
              }
            } else {
              if (isLoading) {
                return;
              }
              onchange && onchange(value);
            }
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          filterOptions={(options: any, params: any) => {
            const filtered = filter(options, params);
            if (isAddOption && !isLoading) {
              const { inputValue } = params;
              const isExisting = options.some((option: any) => inputValue === option.name);
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `Add "${inputValue}"`,
                });
              }
            }
            return filtered;
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option?.iso && (
                  <>
                    <ReactCountriesFlags isoCode={option?.iso} />
                    <span> &nbsp;</span>
                  </>
                )}
                {option.label ?? option.name ?? option.full_name ?? ""}
              </li>
            );
          }}
        />
      )}
    </div>
  );
}
