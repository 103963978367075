import { IconButton } from "@mui/material";
import { useStyles } from "./styles";
import ClearIcon from "@mui/icons-material/Clear";

interface FilterTagProp {
  title: string;
  field: string;
  className?: string;
  onDelete?: Function;
}

export default function FilterTag({title, field, className, onDelete }: FilterTagProp) {
  const { classes } = useStyles();
  return (
    <div className={`${classes.container} ${className}`}>
      <p>{field}</p>
      <IconButton className={classes.containerbtn} onClick={() => onDelete && onDelete(title)}>
        <ClearIcon className={classes.btnClear} />
      </IconButton>
    </div>
  );
}
