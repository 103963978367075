import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  noWrapTitle: {
    display: "block",
  },
  contentChart: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  contentPage: {
    borderRadius: theme.card.borderRadius,
    border: theme.card.border,
    height: "100%",
    padding: 16,
  },
}));
