import ModalConfirm from "components/Modal/ModalConfirm";
import { useTranslation } from "react-i18next";

interface ModalConfirmLeaveFormProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function ModalConfirmLeaveForm({ isOpen = false, onClose, onConfirm }: ModalConfirmLeaveFormProps) {
  const { t } = useTranslation();
  return (
    <ModalConfirm
      isOpen={isOpen}
      onClose={onConfirm}
      title={t("confirmation")}
      headerContent={t("leave_this_form?")}
      content={t("changes_you_made_may_not_be_saved.")}
      onConfirm={onClose}
    />
  );
}
