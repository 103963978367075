import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Controller } from "react-hook-form";
import { useStyles } from "./styles";
import { LooseObject, SelectDataProp } from "models/common";
import ModalSendEmail from "components/Modal/ModalSendEmail";

interface InputEmailProps {
  className?: string;
  nameRegisterEmail?: string;
  control?: any;
  isVisibleIcon?: Boolean;
  currentContact?: LooseObject[];
}

export default function InputEmail({
  className,
  nameRegisterEmail,
  control,
  isVisibleIcon,
  currentContact,
}: InputEmailProps) {
  const { classes } = useStyles();

  const [openDialogSendEmail, setOpenDialogSendEmail] = useState(false);
  const [dataSendEmail, setDataSendEmail] = useState<SelectDataProp[]>([]);

  const handleOpenDialogSendEmail = (row: LooseObject) => {
    setOpenDialogSendEmail(true);
    setDataSendEmail([{ id: row?.id, name: row?.email }]);
  };

  const handleCloseDialogSendEmail = () => {
    setOpenDialogSendEmail(false);
  };

  return (
    <>
      {control && nameRegisterEmail ? (
        <Controller
          control={control}
          name={nameRegisterEmail}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="email"
              className={`${classes.container} ${className}`}
              InputProps={{
                endAdornment: isVisibleIcon ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleOpenDialogSendEmail(currentContact ?? {})}>
                      <span className={`${classes.iconMenu} ${classes.iconEmail}`} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          )}
        />
      ) : (
        <TextField
          className={`${classes.container} ${className}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleOpenDialogSendEmail(currentContact ?? {})}>
                  <EmailOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}

      {openDialogSendEmail && (
        <ModalSendEmail
          isOpen={openDialogSendEmail}
          onClose={handleCloseDialogSendEmail}
          dataSendEmail={dataSendEmail}
        />
      )}
    </>
  );
}
