import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en/en.json";
import vi from "../locales/vi/vi.json";
import jp from "../locales/jp/jp.json";
import registerVi from "../locales/vi/registerVi.json";
import registerEn from "../locales/en/registerEn.json";
import registerJp from "../locales/jp/registerJp.json";

export const resources = {
  en: {
    home: en,
    register: registerEn,
  },
  vi: {
    home: vi,
    register: registerVi,
  },
  jp: {
    home: jp,
    register: registerJp,
  },
};

const defaultNS = "home";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("react-app-lang") || "vi",
    ns: ["home", "register"],
    fallbackLng: "vi",
    defaultNS,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .catch(console.error);

export default i18n;
