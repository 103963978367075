import { ThemeProvider } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import "./i18n/i18n";
import ErrorBoundary from "./providers/ErrorBoundary";
import { store } from "./redux/store";
import AppRoutes from "./routes/index";
import defaultTheme from "./themes/default";
function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={defaultTheme}>
          <BrowserRouter>
            <ErrorBoundary>
              <ToastContainer position="top-right" autoClose={2000} />
              <NavBar />
              <AppRoutes />
              <Footer />
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
