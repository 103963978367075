import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import logoFlower from "../../../../assets/images/footer_emblem_1.png";

export const useStyles = makeStyles()((theme: Theme) => ({
  footer: {
    position: "relative",
    bottom: 0,
    width: "100%",
    marginTop: "50px",
  },
  copyright: {
    width: "100%",
    backgroundColor: "black",
    color: "#AFAFAF",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "27px",
  },
  information: {
    width: "100%",
    backgroundColor: "#790000F2",
    color: "#FFF",
    display: "flex",
    boxSizing: "border-box",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  logoVju: {
    width: "186px",
    height: "71px",
    marginBottom: "32px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "16px",
    },
  },
  logoSocial: {
    width: "32px",
    height: "32px",
    marginRight: "7px",
  },
  wrapLogoVju: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "32px 0 32px 64px",
    backgroundImage: `url(${logoFlower})`,
    backgroundSize: "421px auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "-141px -91px",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      backgroundImage: `unset`,
      padding: "32px 0 16px 16px",
    },
  },
  wrapContact: {
    padding: "32px 0",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      padding: "0",
      paddingLeft: "16px",
    },
  },
  wrapAddress: {
    padding: "32px 0",
    flex: 2,
    [theme.breakpoints.down("md")]: {
      padding: "0",
      paddingLeft: "16px",
      flex: 1,
    },
  },
  wrapMap: {
    padding: "32px 32px 32px 0",
    backgroundImage: `url(${logoFlower})`,
    backgroundSize: "498px auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      backgroundImage: `unset`,
      padding: "16px",
    },
  },
  titleFooter: {
    fontWeight: 700,
  },
  followUs: {
    marginBottom: "14px",
  },
  wrapBlock: {
    padding: "10px",
  },
  map: {
    width: "100%",
    height: "100px",
    border: 0,
    borderRadius: "8px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "16px",
    },
  },
}));
