import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";
import { keyframes } from "tss-react";

const pulse = keyframes`
    0% {
      transform: scale(0.5);
      opacity: 0;
    },
    50% {
      transform: scale(1);
      opacity: 1;
    },
    100% {
      transform: scale(1.3);
      opacity: 0;
    },`;

export const useStyles = makeStyles()(() => ({
  rootDialog: {
    zIndex: "unset",
    left: "unset",
    top: "unset",
    margin: "10px",
    minWidth: "300px",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "300px",
      },
    },
  },
  textEllipsis: {
    display: "-webkit-box",
    WebkitLineClamp: 2 /* Số dòng tối đa */,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  paper: {
    margin: 0,
  },
  headerModal: {
    height: "40px",
    backgroundColor: colors.btnDark,
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "18px",
    fontWeight: 700,
    paddingLeft: "16px",
    "& p": {
      marginLeft: "12px",
    },
  },
  containerContent: {
    padding: "20px 0px 0px 0px",
  },
  formControl: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  btnCancel: {
    height: "31px",
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "51px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  btnSearch: {
    height: "31px",
    backgroundColor: colors.btnDark,
    "&:hover": {
      backgroundColor: colors.btnDark,
    },
  },
  containerForm: {
    paddingLeft: "12.5px",
  },
  headerTextForm: {
    color: colors.defaultColorText,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    ":hover": {
      color: colors.defaultColorText,
      cursor: "pointer",
    },
  },
  headerDiviverForm: {
    width: "1px",
    height: "38px",
    backgroundColor: "#DEDEDE",
    margin: "0 20px",
  },
  containerHeaderForm: {
    display: "flex",
    justifyContent: "center",
    height: "38px",
    alignItems: "center",
    marginBottom: "10px",
  },
  activeHeaderTextForm: {
    color: colors.danger,
  },
  leftCard: {
    flex: 1,
    paddingRight: "60px",
    "@media (max-width: 1024px)": {
      paddingRight: "20px",
    },
    "@media (max-width: 768px)": {
      paddingRight: "unset",
    },
  },
  centerCard: {
    flex: 1,
    paddingRight: "60px",
    "@media (max-width: 1024px)": {
      paddingRight: "20px",
    },
    "@media (max-width: 768px)": {
      paddingRight: "unset",
    },
  },
  rightCard: {
    flex: 1,
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    margin: 0,
  },
  labelLeft: {
    minWidth: "200px",
    "@media (max-width: 1024px)": {
      minWidth: "100px",
    },
    "@media (max-width: 768px)": {
      minWidth: "200px",
    },
  },
  labelCenter: {
    minWidth: "200px",
  },
  labelRight: {
    minWidth: "200px",
    "@media (max-width: 1024px)": {
      minWidth: "140px",
    },
    "@media (max-width: 768px)": {
      minWidth: "200px",
    },
  },
  input: {
    width: "100%",
    height: "30px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
  },
  inputSelect: {
    width: "100%",
    minWidth: "150px",
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "left",
    marginTop: "-5px",
    paddingLeft: "5px",
    "@media (max-width: 768px)": {
      paddingLeft: "200px",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 0",
  },
  btnSaveQuery: {
    height: "44px",
    backgroundColor: colors.btnPrimary,
    marginLeft: "21px !important",
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
  iconAdd: {
    fontSize: "29px !important",
    marginRight: "-4px",
  },
  hidden: {
    display: "none",
  },
  btnClose: {
    backgroundColor: colors.btnDark,
    color: "#FFF",
  },
  btnHidden: {
    backgroundColor: colors.btnDark,
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#4A4358aa",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      width: `calc(100% + 30px)`,
      height: `calc(100% + 30px)`,
      border: `1px solid ${colors.btnDark}`,
      borderRadius: "50%",
      animation: `${pulse} 1s linear infinite`,
    },
    "&:after": {
      content: '""',
      position: "absolute",
      animation: `${pulse} 1s linear infinite`,
      border: `1px solid ${colors.btnDark}`,
      animationDelay: "0.3s",
      width: `calc(100% + 30px)`,
      height: `calc(100% + 30px)`,
      borderRadius: "50%",
    },
  },
  btnEndCall: {
    backgroundColor: "#FD6161",
    color: "white",
    height: "44px",
    width: "44px",
    "&:hover": {
      backgroundColor: "#FD6161aa",
    },
  },
  btnLogInfo: {
    backgroundColor: "#bdbdbd",
    color: "white",
    height: "38px",
    width: "38px",
    "&:hover": {
      backgroundColor: "#bdbdbdaa",
    },
  },
}));
