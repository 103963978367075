import { yupResolver } from "@hookform/resolvers/yup";
import BaseButton from "components/Buttons/BaseButton";
import Loading from "components/Loading/Loading";
import ModalConfirmLeaveForm from "components/ModalConfirmLeaveForm";
import { optionsStudentContact } from "models/common";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/store";
import actionContactSlice, { addNewContactPoint, getErrorActionContactSelector } from "store/actionContactSlice";
import { fetchContactPoint } from "store/contactPointSlice";
import * as Yup from "yup";
import InputTextForm from "../InputTextForm";
import { useStyles } from "./styles";
import { INIT_COUNTRY_ISO_VN_DEFAULT } from "utils";

interface ICreateNewContactPointFormProp {
  onClose: Function;
  contactId: number;
  isOpen: boolean;
  options?: optionsStudentContact;
}

export default function CreateNewContactPointForm({
  onClose,
  contactId,
  isOpen,
  options,
}: ICreateNewContactPointFormProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModalConfirmLeaveForm, setIsOpenModalConfirmLeaveForm] = useState<boolean>(false);
  const [isOpenForm] = useState(isOpen);

  useEffect(() => {
    if (isOpenForm) {
      dispatch(actionContactSlice.actions.resetStateError());
    }
  }, [dispatch, isOpenForm]);

  const validationSchema = Yup.object().shape(
    {
      contact_id: Yup.number().required(),
      full_name: Yup.string().required(t("name_is_required")).max(255, t("name_must_not_exceed_255_characters")),
      email: Yup.string()
        .ensure()
        .when("phone_number", {
          is: "",
          then: (schema: Yup.StringSchema) =>
            schema
              .required(t("please_enter_either_an_email_or_a_phone_number"))
              .email(t("email_is_invalid"))
              .max(255, t("email_must_not_exceed_255_characters")),
          otherwise: (schema: Yup.StringSchema) => schema,
        }),
      phone_number: Yup.string()
        .ensure()
        .when("email", {
          is: "",
          then: (schema: Yup.StringSchema) =>
            schema
              .test("is-Phone", t("this_field_is_required"), function (value) {
                const { phone_number, country_iso } = this.parent;
                return !!phone_number && !!country_iso;
              })
              .required(t("please_enter_either_an_email_or_a_phone_number"))
              .max(15, t("phone_is_invalid")),
          otherwise: (schema: Yup.StringSchema) => schema,
        }),
      country_iso: Yup.string()
        .ensure()
        .when("phone_number", {
          is: "",
          then: (schema: Yup.StringSchema) => schema,
          otherwise: (schema: Yup.StringSchema) => schema.required(t("phone_is_invalid")),
        }),
      relationship: Yup.string().max(255, t("this_field_must_not_exceed_255_characters")),
      address: Yup.string().max(255, t("this_field_must_not_exceed_255_characters")),
    },
    [
      ["email", "phone_number"],
      ["country_iso", "phone_number"],
    ]
  );

  const errorFromApis = useSelector(getErrorActionContactSelector);

  type FilterCreateNewContactPointSubmitForm = Yup.InferType<typeof validationSchema>;
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
  } = useForm<FilterCreateNewContactPointSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      contact_id: contactId,
      full_name: "",
      phone_number: "",
      email: "",
      relationship: "",
      address: "",
    },
  });

  useEffect(() => {
    setValue("country_iso", INIT_COUNTRY_ISO_VN_DEFAULT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getErrorsValidate = (name: any) => {
    return Object.keys(errors).length !== 0 ? errors[name as keyof typeof errors]?.message : errorFromApis?.[name]?.[0];
  };

  const handleSubmitModal = async (data: FilterCreateNewContactPointSubmitForm) => {
    setIsLoading(true);

    const response = await dispatch<any>(addNewContactPoint(data));
    if (addNewContactPoint.fulfilled.match(response)) {
      toast.success<void>(t("add_contact_point_successful"));
      onClose();
      await dispatch(fetchContactPoint());
    }
    setIsLoading(false);
  };
  const widthLabelForm = 105;
  const marginRightLabelForm = 44;

  const handleBackClick = () => {
    isDirty ? setIsOpenModalConfirmLeaveForm(true) : onClose();
  };

  return (
    <>
      {isLoading && <Loading />}
      <form onSubmit={handleSubmit(handleSubmitModal)} noValidate>
        <div className={classes.containerForm}>
          <InputTextForm
            title={t("full_name")}
            widthLabel={widthLabelForm}
            marginRightLabel={marginRightLabelForm}
            isRequired={true}
            name="full_name"
            register={register}
            errors={getErrorsValidate("full_name")}
          />
          <InputTextForm
            title={t("phone_number")}
            widthLabel={widthLabelForm}
            marginRightLabel={marginRightLabelForm}
            name="phone_number"
            type="number"
            register={register}
            errors={getErrorsValidate("phone_number") ?? getErrorsValidate("country_iso")}
            control={control}
            options={options}
            initIsoVnDefault={INIT_COUNTRY_ISO_VN_DEFAULT}
          />
          <InputTextForm
            title={t("email")}
            widthLabel={widthLabelForm}
            marginRightLabel={marginRightLabelForm}
            name="email"
            register={register}
            errors={getErrorsValidate("email")}
          />
          <InputTextForm
            title={t("relationship")}
            widthLabel={widthLabelForm}
            marginRightLabel={marginRightLabelForm}
            name="relationship"
            register={register}
            errors={getErrorsValidate("relationship")}
          />

          <InputTextForm
            type="textarea"
            title={t("address")}
            widthLabel={widthLabelForm}
            marginRightLabel={marginRightLabelForm}
            name="address"
            isRequired={false}
            register={register}
            errors={getErrorsValidate("address")}
          />
        </div>
        <div className={classes.containerBtn}>
          <BaseButton title={t("cancel")} className={classes.btnCancel} onClick={handleBackClick} />
          <BaseButton title={t("save")} className={classes.btnSearch} isSubmit />
        </div>
      </form>
      <ModalConfirmLeaveForm
        isOpen={isOpenModalConfirmLeaveForm}
        onClose={() => {
          setIsOpenModalConfirmLeaveForm(false);
          onClose();
        }}
        onConfirm={() => {
          setIsOpenModalConfirmLeaveForm(false);
        }}
      />
    </>
  );
}
