import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar, Line, Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface IProps {
  datasets: any;
  labels: any;
  type: any;
  options?: any;
}

const BaseChart: React.FC<IProps> = (props) => {
  const { datasets, labels, options, type } = props;

  switch (type) {
    case "pie":
      return (
        <Pie
          data={{
            labels,
            datasets: datasets,
          }}
          options={options}
        />
      );

    case "bar":
      return (
        <Bar
          data={{
            labels,
            datasets: datasets,
          }}
          options={options}
        />
      );
    default:
      return (
        <Line
          data={{
            labels,
            datasets: datasets,
          }}
          options={options}
        />
      );
  }
};

export default BaseChart;
