import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    border: "1px solid #456589",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 500,
    borderRadius: "20px",
    backgroundColor: "#FFF",
    color: "#456589",
    marginRight: "10px",
    height: "24px",
    "&:hover": {
      backgroundColor: "#FFF",
      opacity: 0.8,
      transition: "ease-in 0.2s",
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    }
  },
  active: {
    backgroundColor: "#413C51",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#413C51",
    }
  }
}));
