import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  contentPage: {
    border: `1px solid ${colors.borderBlack}`,
    borderRadius: 12,
    padding: 16,
    minHeight: 80,
    display: "flex",
    justifyContent: "space-between",
  },
  titleText: {
    color: "#0054A6",
    marginBottom: 5,
  },
}));
