import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import facebook from "../../../../assets/images/facebook.svg";
import instagram from "../../../../assets/images/instagram.svg";
import logoVJU from "../../../../assets/images/logo_Vju.svg";
import youtube from "../../../../assets/images/youtube.svg";
import { useStyles } from "./styles";

const FooterRegister = () => {
  const { classes } = useStyles();
  const { t } = useTranslation(["register", "home"]);
  return (
    <footer className={classes.footer}>
      <div className={classes.information}>
        <div className={classes.wrapLogoVju}>
          <img src={logoVJU} alt="logoVJU" className={classes.logoVju} />
          <div className={`${classes.titleFooter} ${classes.followUs}`}>{`${t("follow_us")}:`}</div>
          <div>
            <Link to="https://www.facebook.com/vnu.vju" target="blank">
              <img src={facebook} alt="facebook" className={classes.logoSocial} />
            </Link>
            <Link to="https://www.instagram.com/vietnamjapanuniversity/?hl=en" target="blank">
              <img src={instagram} alt="instagram" className={classes.logoSocial} />
            </Link>
            <Link to="https://www.youtube.com/channel/UCZ0TB6t-yruBuD3bg07kr4Q?view_as=subscriber" target="blank">
              <img src={youtube} alt="youtube" className={classes.logoSocial} />
            </Link>
          </div>
        </div>
        <div className={classes.wrapContact}>
          <div className={classes.wrapBlock}>
            <div className={`${classes.titleFooter} `}>{`${t("admission")}:`}</div>
            <div>{`(+84)- 247 3066 001 - ${t("ext")} 5093`}</div>
          </div>
          <div className={classes.wrapBlock}>
            <div className={`${classes.titleFooter} `}>{`${t("hotline")}:`}</div>
            <div>(+84) 966 954 736</div>
            <div>(+84) 969 638 426</div>
          </div>
          <div className={classes.wrapBlock}>
            <div className={`${classes.titleFooter} `}>{`${t("email")}:`}</div>
            <div>admission@vju.ac.vn</div>
            <div>info@vju.ac.vn</div>
          </div>
        </div>
        <div className={classes.wrapAddress}>
          <div className={classes.wrapBlock}>
            <div className={`${classes.titleFooter} `}>{`${t("my_dinh_facility")}:`}</div>
            <div>{t("my_dinh_address")}</div>
          </div>
          <div className={classes.wrapBlock}>
            <div className={`${classes.titleFooter} `}>{`${t("hoa_lac_campus")}:`}</div>
            <div>{t("hoa_lac_address")}</div>
          </div>
        </div>
        <div className={classes.wrapMap}>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14895.970909109537!2d105.762243!3d21.032977!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab8cbffec76b%3A0x971b711a01a86fd3!2sVietnam%20Japan%20University%20(VJU)%20M%E1%BB%B9%20%C4%90%C3%ACnh!5e0!3m2!1sen!2sus!4v1709517170272!5m2!1sen!2sus"
            className={classes.map}
            loading="lazy"
          ></iframe>
          <iframe
            title="map_Hoa_Lac"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5348.733804897423!2d105.49005554251772!3d21.003152466705256!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31345d6a56ac811d%3A0x4ef9eb424108e8ec!2sVNU%20Vietnam%20Japan%20University!5e0!3m2!1sen!2s!4v1709698874636!5m2!1sen!2s"
            className={classes.map}
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div className={classes.copyright}>© 2023 Vietnam Japan University - VNU | All rights reserved</div>
    </footer>
  );
};

export default FooterRegister;
