import { useCallback, useState } from "react";
import { useStyles } from "./styles";
import { IconButton, debounce } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorMessageForm from "../ErrorMessageForm";

interface IProp {
  title: string;
  isRequired?: boolean;
  widthLabel?: number;
  marginRightLabel?: number;
  type?: string;
  onChange: Function;
  errors?: string;
  name: string;
}

export default function InputTextSearch({
  title,
  isRequired = false,
  widthLabel,
  marginRightLabel,
  type,
  onChange,
  errors,
  name,
}: IProp) {
  const { classes } = useStyles();
  const width = widthLabel ?? 80;
  const marginRight = marginRightLabel ?? 60;
  const [value, setValue] = useState("");
  const onClickHandle = (e: any) => {
    setValue("");
    debouncedOnChange("");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((nextValue: string) => {
      onChange(nextValue);
    }, 500),
    []
  );

  const onChangeHandle = (e: any) => {
    setValue(e.target.value);
    debouncedOnChange(e.target.value);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrap} style={{ alignItems: "center" }}>
          <p style={{ width: width, marginRight: marginRight }} className={classes.label}>
            {title} {isRequired && <span>*</span>}
          </p>
          <div className={classes.containerPassword}>
            <input className={classes.input} onChange={onChangeHandle} value={value} />
            <IconButton onClick={onClickHandle} className={classes.btnHide}>
              {value && <ClearIcon />}
            </IconButton>
          </div>
        </div>
        <ErrorMessageForm errors={errors} name={name} widthLabel={width} marginRightLabel={marginRight} />
      </div>
    </>
  );
}
